import { call, put, all, takeLatest } from "redux-saga/effects";

import {
  getSpillsSuccess,
  getSpillsFail,
  createNewSpillSuccess,
  createNewSpillFail,
  editSpillSuccess,
  editSpillFail,
  getCompleteSpillSuccess,
  getCompleteSpillFail,
  searchSpillsSuccess,
  searchSpillsFail,
  editNotesSuccess,
  editNotesFail,
  getNoteHistorySuccess,
  getNoteHistoryFail,
  getContractorHistorySuccess,
  getContractorHistoryFail,
  getMySpillsSuccess,
  getMySpillsFail,
  createReservesSuccess,
  createReservesFail,
  editReservesSuccess,
  editReservesFail,
  closeSpillSuccess,
  closeSpillFail,
  addAttachmentsSuccess,
  addAttachmentsFail,
  getClientAssociatedNotesServicesSuccess,
  getClientAssociatedNotesServicesFail,
  getMaterialSuccess,
  getMaterialFail,
  getStatusesSuccess,
  getStatusesFail,
  getSpillSummaryFail,
  getSpillSummarySuccess,
  getClosureSpillsSuccess,
  getClosureSpillsFail,
  createSingleNoteSuccess,
  createSingleNoteFail,
  updateStatusSuccess,
  updateStatusFail,
  getSpillAdminAttachmentHistorySuccess,
  getSpillAdminAttachmentHistoryFail,
  getPaginatedNotesSuccess,
  getPaginatedNotesFail,
  getTotalNotesForPrintSpillFail,
  getTotalNotesForPrintSpillSuccess,
  clearSpillDataFail,
  clearSpillDataSuccess,
  uploadNoteVideosFail,
  uploadNoteVideosSuccess,
  getSpillStatusHistorySuccess,
  getSpillStatusHistoryFail,
  updateSpillForAddingArrivedOnServiceFail,
  updateSpillForAddingArrivedOnServiceSuccess,
  getSpillAdminAttachmentTypesFail,
  getSpillAdminAttachmentTypesSuccess,
  getScriptSuccess,
  getScriptFail,
  getDocumentationInReviewSpillsSuccess,
  getDocumentationInReviewSpillsFail,
  assignPacketReviewerSuccess,
  assignPacketReviewerFail,
  getSpillPacketAssignmentHistorySuccess,
  getSpillPacketAssignmentHistoryFail,
  requestDocumentationSuccess,
  requestDocumentationFail,
  getSpillAdminRequestedDocumentationSuccess,
  getSpillAdminRequestedDocumentationFail,  
  noteWorkTimeValidationSuccess,
  noteWorkTimeValidationFail,
  getReminderSuccess,
  getReminderFail,
  partialBillingDownloadSuccess,
  partialBillingDownloadFail,
  cancelRequestedDocumentationSuccess,
  cancelRequestedDocumentationFail,
  getMaterialClassSuccess,
  getMaterialClassFail,
  deleteMaterialClassFail,
  deleteMaterialClassSuccess,
  revenueHistoryReportSuccess,
  revenueHistoryReportFail,
} from "../actionCreators/Spill";
import { forceToCST, getCurrentTime } from "../utils";

import { doGet, doPost, doPatch, doDelete } from "../utils/request";
import { REQUEST_TYPE } from "../utils/index";

function* getSpills({ obj }) {
  try {
    const response = yield doGet(
      `/spill/spills?page=${obj.page}&limit=${obj.limit || 10}&id=${
        obj.id
      }&permission=${obj.permission}&role=${obj.role}&userType=${obj.userType}`
    );
    yield put(getSpillsSuccess(response.data));
  } catch (err) {
    yield put(getSpillsFail(err.response.data.message || err.message));
  }
}

function* createNewSpill({ obj }) {
  try {
    const response = yield doPost("/spill/createNew", {
      ...obj,
      current_time: getCurrentTime(),
    });

    yield put(createNewSpillSuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(createNewSpillFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(createNewSpillFail());
  }
}

function* editSpill({ obj }) {
  try {
    obj.append("currentTime", JSON.stringify(forceToCST(new Date())));
    const response = yield doPost("/spill/editSpill", obj);

    yield put(editSpillSuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(editSpillFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(editSpillFail());
  }
}

function* createSingleNote({ obj }) {
  try {
    const response = yield doPost("/spill/createSingleNote", obj);
    yield put(createSingleNoteSuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(createSingleNoteFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(createSingleNoteFail());
  }
}

function* uploadNoteVideos({ obj }) {
  try {
    const response = yield doPost(
      "/upload/uploadNoteVideos",
      obj,
      null,
      REQUEST_TYPE.UPLOAD
    );
    yield put(uploadNoteVideosSuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(uploadNoteVideosFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(uploadNoteVideosFail());
  }
}

function* updateStatus({ obj }) {
  try {
    const response = yield doPost("/spill/updateStatus", {
      ...obj,
      currentTime: forceToCST(new Date()),
    });
    yield put(updateStatusSuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(updateStatusFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(updateStatusFail());
  }
}

function* getCompleteSpill({ obj }) {
  try {
    let job_no;
    if (obj?.id) {
      job_no = obj.id;
    }
    if (obj?.jobNo) {
      job_no = obj.jobNo;
    }
    const response = yield doGet(
      `/spill/completeSpill?job_no=${job_no}&mode=${obj.mode}`
    );
    yield put(getCompleteSpillSuccess(response.data.data));
  } catch (err) {
    yield put(getCompleteSpillFail(err.response?.data.message || err.message));
    yield put(getCompleteSpillFail());
  }
}

function* searchSpills({ obj }) {
  try {
    const response = yield doPost(`/spill/search`, obj);

    yield put(searchSpillsSuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(getSpillsFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(getSpillsFail());
  }
}

function* editNotes({ obj }) {
  try {
    const response = yield doPost("/spill/editSpillNotes", obj);
    yield put(editNotesSuccess(response.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(editNotesFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(editNotesFail());
  }
}

function* getNoteHistory({ obj }) {
  try {
    const response = yield doGet(`/spill/noteHistory?spillId=${obj.spillId}`);
    yield put(getNoteHistorySuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(getNoteHistoryFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(getNoteHistoryFail());
  }
}

function* getTotalNotesForPrintSpill({ obj }) {
  try {
    const response = yield doGet(
      `/spill/totalPrintableNotes?spillId=${obj.spillId}`
    );
    yield put(getTotalNotesForPrintSpillSuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(
      getTotalNotesForPrintSpillFail(err.response.data.message || err.message)
    );
    yield call(delay, 2000);
  }
}

function* getPaginatedNotes({ obj }) {
  try {
    const response = yield doGet(
      `/spill/paginatedNotes?spillId=${obj.spillId}`
    );
    yield put(getPaginatedNotesSuccess(response?.data?.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(
      getPaginatedNotesFail(err?.response?.data?.message || err?.message)
    );
    yield call(delay, 2000);
    yield put(getNoteHistoryFail());
  }
}

function* getSpillAdminAttachmentHistory({ obj }) {
  try {
    const response = yield doGet(
      `/spill/getAdminAttachmentHistory?attachmentId=${obj}`
    );
    yield put(getSpillAdminAttachmentHistorySuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(
      getSpillAdminAttachmentHistoryFail(
        err.response.data.message || err.message
      )
    );
    yield call(delay, 2000);
    yield put(getSpillAdminAttachmentHistoryFail());
  }
}

function* getContractorHistory({ obj }) {
  try {
    const response = yield doGet(
      `/spill/contractorHistory?spillId=${obj.spillId}`
    );
    yield put(getContractorHistorySuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(
      getContractorHistoryFail(err.response.data.message || err.message)
    );
    yield call(delay, 2000);
    yield put(getContractorHistoryFail());
  }
}

function* getMySpills({ obj }) {
  try {
    const response = yield doGet(
      `/spill/mySpills?page=${obj.page}&id=${obj.id}&permission=${obj.permission}&role=${obj.role}&userType=${obj.userType}`
    );

    yield put(getMySpillsSuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(getMySpillsFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(getMySpillsFail());
  }
}

function* createReserves({ obj }) {
  try {
    const response = yield doPost("/spill/createSpillReserves", obj);
    yield put(createReservesSuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(createReservesFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(createReservesFail());
  }
}

function* editReserves({ obj }) {
  try {
    const response = yield doPost("/spill/editSpillReserves", obj);
    yield put(editReservesSuccess(response.data.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(editReservesFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(editReservesFail());
  }
}

function* closeSpill({ obj }) {
  try {
    const response = yield doPatch(`/spill/closeSpill/${obj.id}`, {});
    yield put(closeSpillSuccess(response.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(closeSpillFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(closeSpillFail());
  }
}

function* addAttachments({ obj }) {
  try {
    const response = yield doPatch(
      `/spill/spillAttachments/${obj.id}`,
      obj.formData
    );
    yield put(addAttachmentsSuccess(response));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(addAttachmentsFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(addAttachmentsFail());
  }
}

function* getClientAssociatedNotesServices({ obj }) {
  try {
    const response = yield doGet(
      `/spill/associatedServices?orgId=${obj.org_id}&spillId=${obj.spill_id}`
    );

    yield put(getClientAssociatedNotesServicesSuccess(response.data.services));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(
      getClientAssociatedNotesServicesFail(
        err.response.data.message || err.message
      )
    );
    yield call(delay, 2000);
    yield put(getClientAssociatedNotesServicesFail());
  }
}

function* getMaterial({ obj }) {
  try {
    const response = yield doPost("/spill/materials", obj);

    yield put(getMaterialSuccess(response.data.data));
  } catch (err) {
    yield put(getMaterialFail(err.response.data.message || err.message));
  }
}

function* getMaterialClass({ obj }) {
  try {
    const response = yield doPost("/spill/material_class", obj);

    yield put(getMaterialClassSuccess(response?.data?.data));
  } catch (err) {
    yield put(getMaterialClassFail(err?.response?.data?.message || err?.message));
  }
}

function* deleteMaterialClass({ obj }) {
  try {
    const response = yield doDelete("/spill/material_class", obj)

    yield put(deleteMaterialClassSuccess());
  } catch (error) {
    yield put(deleteMaterialClassFail(error?.response?.data?.message || error?.message))
  }
}

function* getStatuses() {
  try {
    const response = yield doGet("/spill/statuses");

    yield put(getStatusesSuccess(response.data.data));
  } catch (err) {
    yield put(getStatusesFail(err.response.data.message || err.message));
  }
}

function* getSpillSummary({ obj }) {
  try {
    const response = yield doGet(`/spill/summary?userType=${obj.userType}`);
    yield put(getSpillSummarySuccess(response.data.data));
  } catch (err) {
    yield put(getSpillSummaryFail());
  }
}

function* getClosureSpills({ obj }) {
  try {
    const response = yield doGet(
      `/spill/submitted?page=${obj.page}&limit=${
        obj.paginationProps.limit || 10
      }&id=${obj.id}&permission=${obj.permission}&role=${obj.role}&userType=${
        obj.userType
      }`
    );
    yield put(getClosureSpillsSuccess(response.data));
  } catch (err) {
    yield put(getClosureSpillsFail());
  }
}

function* clearSpillData({ obj }) {
  try {
    const response = yield doPost(
      `/spill/clearSpillData?spillId=${obj.spillId}&userId=${obj.userId}&connectionId=${obj.connectionId}&legacyId=${obj.legacyId}&jobNo=${obj.jobNo}`
    );
    yield put(clearSpillDataSuccess(response.data));
  } catch (err) {
    yield put(clearSpillDataFail(err.response.data.message || err.message));
  }
}

function* getSpillStatusHistory({ obj }) {
  try {
    const response = yield doPost(
      `/spill/statusHistory?spillId=${obj.spillId}`
    );
    yield put(getSpillStatusHistorySuccess(response.data));
  } catch (err) {
    yield put(
      getSpillStatusHistoryFail(err.response.data.message || err.message)
    );
  }
}

function* updateSpillForAddingArrivedOnService({ obj }) {
  try {
    const response = yield doPost(
      `/spill/updateSpillForAddingArrivedOnService`,
      obj
    );
    yield put(updateSpillForAddingArrivedOnServiceSuccess(response.data));
  } catch (err) {
    yield put(updateSpillForAddingArrivedOnServiceFail());
  }
}

// (update-attachment-expiry)
// function* updateContractorAttachmentTypes() {
//   try {
//     const response = yield doPost(`/spill/updateContractorAttachmentTypes`);

//     console.log('response', response);
//   } catch (err) {
//     console.log('err', err);
//   }
// }

function* getSpillAdminAttachmentTypes() {
  try {
    const response = yield doGet(`/spill/getSpillAdminAttachmentTypes`);

    yield put(getSpillAdminAttachmentTypesSuccess(response?.data));
  } catch (err) {
    yield put(
      getSpillAdminAttachmentTypesFail(err.response.data.message || err.message)
    );
  }
}

function* getScript() {
  try {
    const response = yield doGet(`/spill/getScript`);

    yield put(getScriptSuccess(response?.data?.data));
  } catch (err) {
    yield put(getScriptFail(err.response?.data.message || err.message));
    yield put(getScriptFail());
  }
}

function* getDocumentationInReviewSpills({ obj }) {
  try {
    const response = yield doPost(`/spill/getDocumentationInReviewSpills`, obj);

    yield put(getDocumentationInReviewSpillsSuccess(response?.data));
  } catch (err) {
    yield put(
      getDocumentationInReviewSpillsFail(
        err?.response?.data?.message || err?.message
      )
    );
    yield put(getDocumentationInReviewSpillsFail());
  }
}

function* assignPacketReviewer({ obj }) {
  try {
    const response = yield doPost("/spill/assign-packet-reviewer", obj);
    yield put(assignPacketReviewerSuccess(response.data));
  } catch (err) {
    yield put(
      assignPacketReviewerFail(err?.response?.data?.message || err?.message)
    );
    yield put(assignPacketReviewerFail());
  }
}

function* getSpillPacketAssignmentHistory({ obj }) {
  try {
    const response = yield doGet(
      `/spill/packetAssignmentHistory?spillId=${obj.spillId}`
    );
    yield put(getSpillPacketAssignmentHistorySuccess(response.data));
  } catch (err) {
    yield put(
      getSpillPacketAssignmentHistoryFail(
        err.response.data.message || err.message
      )
    );
  }
}

function* requestDocumentation({ obj }) {
  try {
    const response = yield doPost("/spill/request-documentation", obj);
    yield put(requestDocumentationSuccess(response?.data));
  } catch (err) {
    yield put(
      requestDocumentationFail(err?.response?.data?.message || err?.message)
    );
    yield put(requestDocumentationFail());
  }
}

function* cancelRequestedDocumentation({obj}) {
  try {
    const response = yield doPost("/spill/cancel-request-documentation", obj);
    yield put(cancelRequestedDocumentationSuccess(response?.data));
  } catch (err) {
    yield put(
      cancelRequestedDocumentationFail(err?.response?.data?.message || err?.message)
    );
    yield put(cancelRequestedDocumentationFail());
  }
}

function* getSpillAdminRequestedDocumentation({ obj }) {
  try {
    const response = yield doGet(
      `/spill/get_spill_admin_requested_documentation?spill_id=${obj?.spill_id}&spill_admin_id=${obj?.spill_admin_id}`
    );
    yield put(getSpillAdminRequestedDocumentationSuccess(response?.data?.data));
  } catch (err) {
    yield put(
      getSpillAdminRequestedDocumentationFail(
        err?.response?.data?.message || err?.message
      )
    );
    yield put(getSpillAdminRequestedDocumentationFail());
  }
}

function* getReminder() {
  try {
    const response = yield doGet(`/spill/notes/reminder`);
    yield put(getReminderSuccess(response?.data?.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(getReminderFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(getReminderFail());
  }
}

function* noteWorkTimeValidation({obj}) {
  try {
    const response = yield doPost(`/spill/notes/note_work_time_validation`,obj );
    yield put(noteWorkTimeValidationSuccess(response?.data?.data));
  } catch (err) {
    yield put(noteWorkTimeValidationFail(err?.response?.data?.data || err?.message));
  }
}

function* partialBillingDownload({obj}) {
  try {
    const response = yield doPost(`/download/partial_billing_events`,obj, null, REQUEST_TYPE.DOWNLOAD );
    yield put(partialBillingDownloadSuccess(response?.data?.data));
  } catch (err) {
    yield put(partialBillingDownloadFail(err?.response?.data?.data || err?.message));
  }
}

function* getDownloadRevenueHistoryReport() {
  try {
    const response = yield doGet(
        `/spill/get-revenue-history`
      );
      yield put(revenueHistoryReportSuccess(response?.data?.data));
    } catch (err) {
      yield put(revenueHistoryReportFail(err?.message));
  }
}

export default function* watcher() {
  yield all([takeLatest("GET_SPILLS", getSpills)]);
  yield all([takeLatest("CREATE_NEW_SPILL", createNewSpill)]);
  yield all([takeLatest("EDIT_SPILL", editSpill)]);
  yield all([takeLatest("CREATE_SINGLE_NOTE", createSingleNote)]);
  yield all([takeLatest("UPLOAD_NOTE_VIDEOS", uploadNoteVideos)]);
  yield all([takeLatest("GET_COMPLETE_SPILL", getCompleteSpill)]);
  yield all([takeLatest("SEARCH_SPILLS", searchSpills)]);
  yield all([takeLatest("EDIT_NOTES", editNotes)]);
  yield all([takeLatest("GET_CONTRACTOR_HISTORY", getContractorHistory)]);
  yield all([takeLatest("GET_NOTE_HISTORY", getNoteHistory)]);
  yield all([
    takeLatest("GET_TOTAL_NOTES_FOR_PRINT_SPILL", getTotalNotesForPrintSpill),
  ]);
  yield all([takeLatest("GET_PAGINATED_NOTES", getPaginatedNotes)]);
  yield all([
    takeLatest(
      "GET_SPILL_ADMIN_ATTACHMENT_HISTORY",
      getSpillAdminAttachmentHistory
    ),
  ]);
  yield all([takeLatest("GET_MY_SPILLS", getMySpills)]);
  yield all([takeLatest("CREATE_RESERVES", createReserves)]);
  yield all([takeLatest("EDIT_RESERVES", editReserves)]);
  yield all([takeLatest("CLOSE_SPILL", closeSpill)]);
  yield all([takeLatest("ADD_ATTACHMENTS", addAttachments)]);
  yield all([
    takeLatest("ASSOCIATED_NOTES_SERVICES", getClientAssociatedNotesServices),
  ]);
  yield all([takeLatest("GET_MATERIAL", getMaterial)]);
  yield all([takeLatest("GET_MATERIAL_CLASS", getMaterialClass)])
  yield all([takeLatest("DELETE_MATERIAL_CLASS", deleteMaterialClass)]);
  yield all([takeLatest("GET_STATUSES", getStatuses)]);
  yield all([takeLatest("GET_SPILL_SUMMARY", getSpillSummary)]);
  yield all([takeLatest("GET_CLOSURE_SPILLS", getClosureSpills)]);
  yield all([takeLatest("UPDATE_STATUS", updateStatus)]);
  yield all([takeLatest("CLEAR_SPILL_DATA", clearSpillData)]);
  yield all([takeLatest("GET_SPILL_STATUS_HISTORY", getSpillStatusHistory)]);
  yield all([
    takeLatest(
      "UPDATE_SPILL_FOR_ADDING_ARRIVED_ON_SITE_SERVICE",
      updateSpillForAddingArrivedOnService
    ),
  ]);
  //  (update-attachment-expiry)
  // yield all([
  //   takeLatest(
  //     'UPDATE_CONTRACTOR_ATTACHMENT_TYPES',
  //     updateContractorAttachmentTypes
  //   ),
  // ]);
  yield all([
    takeLatest(
      "GET_SPILL_ADMIN_ATTACHMENT_TYPES",
      getSpillAdminAttachmentTypes
    ),
  ]);

  yield all([takeLatest("GET_SCRIPT", getScript)]);
  yield all([
    takeLatest(
      "GET_DOCUMENTATION_IN_REVIEW_SPILLS",
      getDocumentationInReviewSpills
    ),
  ]);
  yield all([takeLatest("ASSIGN_PACKET_REVIEWER", assignPacketReviewer)]);

  yield all([
    takeLatest(
      "GET_SPILL_PACKET_ASSIGNMENT_HISTORY",
      getSpillPacketAssignmentHistory
    ),
  ]);

  yield all([takeLatest("REQUEST_DOCUMENTATION", requestDocumentation)]);
  yield all([takeLatest("CANCEL_REQUESTED_DOCUMENTATION", cancelRequestedDocumentation)])
  yield all([
    takeLatest(
      "GET_SPILL_ADMIN_REQUEST_DOCUMENTATION",
      getSpillAdminRequestedDocumentation
    ),
  ]);
  yield all([takeLatest("NOTE_WORK_TIME_VALIDATION", noteWorkTimeValidation)]);
  yield all([takeLatest("GET_REMINDER",getReminder)]);
  yield all([takeLatest("PARTIAL_BILLING_DOWNLOAD", partialBillingDownload)])
  yield all([takeLatest("GET_DOWNLOAD_REVENUE_HISTORY_REPORT", getDownloadRevenueHistoryReport)])
}
