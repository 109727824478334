export const categories = [
  "Business Hours On Call",
  "After Hours On Call (12 Hours)",
  "After Hours On Call (8 Hours)",
  "Off After On Call",
  "Weekend On Call",
  "Holiday Off Duty",
  "Holiday",
];

export const categoryAssignments = {
  "Business Hours On Call": [],
  "After Hours On Call (12 Hours)": [],
  "After Hours On Call (8 Hours)": [],
  "Off After On Call": [],
  "Weekend On Call": [],
  "Holiday Off Duty": [],
  "Holiday": [],
};

export const ROSTER_ACTIONS = {
  ASSIGN: "ASSIGN",
  UNASSIGN: "UNASSIGN",
  RESTORE_TO_PREVIOUS: "RESTORE_TO_PREVIOUS",
  FETCH: "FETCH",
};
