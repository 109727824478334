import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DndContext } from "@dnd-kit/core";
import { Box, Typography, Paper, Grid, CircularProgress, IconButton, makeStyles, InputLabel, Button } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";

import Draggable from "./components/Draggable.js";
import Droppable from "./components/Droppable.js";
import { categories, categoryAssignments as initialCategoryAssignments, ROSTER_ACTIONS } from "./constants/index";
import * as userActions from "../../../../../actionCreators/User";
import CustomConfirmBox from "../../../../../Components/CustomConfirmBox.js";

const useStyles = makeStyles((theme) => ({
  root: {
    '&$checked': {
      color: '#2F7D32',
    },
  },
  checked: {},
  avatar: {
    backgroundColor: green[500],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  hide: {
    visibility: 'none',
  },
  clearSearchButton: {
    textAlign: 'right',
  },
  checkboxColor: {
    color: '#2F7D32',
  },
  radioGroupDisplay: {
    display: 'inline',
  },
  leftAlign: {
    textAlign: 'left',
  },
  rightAlign: {
    textAlign: 'right',
  },
  containerPad: {
    paddingTop: 10,
  },
  customBtn: {
    display: 'flex',
    gap: '7px',
    padding: '12px'
  },
  restoreBtn: {
    fontWeight: 500,
    fontSize: "14px",
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    gridColumnGap: '40px',
    gridRowGap: '20px'
  },
  marginTop: {
    marginTop: '10px'
  },
  marginBottom: {
    marginBottom: '10px'
  },
  pmList: {
    maxHeight: 780,  // Set your desired max height
    overflowY: 'auto',
  },
}));

const Roster = ({
  loading,
  error,
  currentUser,
  allActivePms,
  getAllActivePMs,
  rosterLoading,
  rosterCategory,
  manageRosterCategory,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [pms, setPMs] = useState([]);
  const [categoryAssignments, setCategoryAssignments] = useState(initialCategoryAssignments);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmData, setConfirmData] = useState({});

  // Handle Confirm Box Open
  const handleOpenConfirm = () => {
    setConfirmData({
      title: "Are you sure?",
      message: "This will restore the roster to the previous day's assignments.",
      confirmText: "Restore to Previous Day",
    });
    setOpenConfirm(true);
  };

  // Handle Confirm Box Close
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  // Handle Confirm Box Confirm
  const handleConfirm = () => {
    handleRestoreToPreviousDay();
    setOpenConfirm(false);
  };

  // Fetch all active PMs and roster data on component mount
  useEffect(() => {
    getAllActivePMs();
    manageRosterCategory({ action: ROSTER_ACTIONS.FETCH });
  }, [getAllActivePMs, manageRosterCategory]);

  // Update PMs and categoryAssignments when rosterCategory.data changes
  useEffect(() => {
    if (rosterCategory) {
      // Map the roster data to categoryAssignments and add email from allActivePms
      const updatedAssignments = Object.keys(initialCategoryAssignments).reduce((acc, category) => {
        acc[category] = (rosterCategory[category] || []).map((user) => {
          // Find the corresponding PM from allActivePms by id
          const activePM = allActivePms?.find((pm) => pm.id === user.id);
          return activePM ? { ...user, email: activePM.email } : user; // Add email to the user
        });
        return acc;
      }, {});

      setCategoryAssignments(updatedAssignments);

      // Remove already assigned PMs from the active PMs list
      const assignedPMs = new Set(Object.values(updatedAssignments).flat().map((pm) => pm.id));
      const filteredPMs = allActivePms?.filter((pm) => !assignedPMs.has(pm.id));

      setPMs(filteredPMs);
    }
  }, [rosterCategory, allActivePms]);

  // Handle drag and drop
  const handleDragEnd = ({ active, over }) => {
    if (!over || active.id === over.id) return; // Do nothing if not dropped in a valid area
  
    const activeId = active.id;
    const targetCategory = over.id;
  
    setCategoryAssignments((prev) => {
      const updatedCategories = { ...prev };
  
      // Remove PM from the active PMs list
      setPMs((prevPMs) => prevPMs.filter((pm) => pm.id !== activeId));
  
      // Add the dragged PM to the target category
      const draggedPM = pms.find((pm) => pm.id === activeId);
      if (draggedPM) {
        updatedCategories[targetCategory] = [
          ...updatedCategories[targetCategory],
          { ...draggedPM, disabled: true },
        ];
      }

      // Call the API with ASSIGN action
      manageRosterCategory({
        user_id: draggedPM.id,
        user_name: draggedPM.full_name,
        category: targetCategory,
        action: ROSTER_ACTIONS.ASSIGN,
      });
  
      return updatedCategories;
    });
  };

  // Handle PM list reset
  const handleResetPM = (pmId, category) => {
    setCategoryAssignments((prev) => {
      const updatedCategories = { ...prev };
  
      // Remove PM from the category
      const [pmToReset] = updatedCategories[category].filter((pm) => pm.id === pmId);
      updatedCategories[category] = updatedCategories[category].filter(
        (pm) => pm.id !== pmId
      );
  
      // Add PM back to the active PMs list
      if (pmToReset) {
        setPMs((prevPMs) => [
          ...prevPMs,
          { ...pmToReset, disabled: false },
        ]);
      }

      // Call the API with UNASSIGN action
      manageRosterCategory({
        user_id: pmToReset.id,
        user_name: pmToReset.full_name,
        category,
        action: ROSTER_ACTIONS.UNASSIGN,
      });
  
      return updatedCategories;
    });
  };
  
  // Handle reset to previous day when confirm is accepted
  const handleRestoreToPreviousDay = () => {
    manageRosterCategory({ action: ROSTER_ACTIONS.RESTORE_TO_PREVIOUS });
  };

  // Handle Back to previous page
  const handleBack = () => {
    history.goBack();
  };

  return (
    <div>
      <Grid container spacing={2} className={classes.marginBottom}>
        <Grid item xs={6} className={classes.leftAlign}>
          <Typography variant='h5' gutterBottom>
            Roster
          </Typography>
          <InputLabel id="drag-and-drop-label">
            Manage and assign PMs to different categories. Easily organize and reset assignments.
          </InputLabel>
        </Grid>
        <Grid item xs={6} className={classes.rightAlign}>
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <Button variant="contained" color="primary" className={classes.restoreBtn} onClick={handleOpenConfirm}> 
                Restore Previous Day
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" className={classes.backBtn} onClick={handleBack}> 
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DndContext onDragEnd={handleDragEnd}>
        <Grid container spacing={2}>
          {/* PMs List */}
          <Grid item xs={3}>
            <Box>
              <Typography
                variant="h6"
                style={{
                  backgroundColor: "#2F7D32",
                  color: "#ffffff",
                  padding: "5px",
                  borderRadius: "5px",
                  textAlign: "center",
                  fontSize: "16px",
                }}
              >
                Active PMs List
              </Typography>
              <Box
                style={{
                  marginTop: "5px",
                  padding: "10px",
                  border: "2px solid #2F7D32",
                  borderRadius: "5px",
                  minHeight: "100px",
                  backgroundColor: "#fff",
                }}
                className={classes.pmList}
              >
                {loading ? (
                  <CircularProgress size={30} />
                ) : pms.length === 0 ? (
                  <Typography variant="body1" style={{ color: "#2F7D32", fontWeight: "bold", marginTop: "25px" }}>
                    All PMs are assigned or List is empty!
                  </Typography>
                ) : (
                  pms.map((pm) => (
                    <Draggable key={pm.id} id={pm.id} disabled={pm.disabled}>
                      <Typography variant="body2">
                        {`${pm.full_name}`} <br />
                        <span style={{ fontSize: "12px" }}>{`(${pm.email})`}</span>
                      </Typography>
                    </Draggable>
                  ))
                )}
              </Box>
            </Box>
          </Grid>

          {/* Categories */}
          <Grid item xs={9}>
            <Grid container spacing={2}>
              {categories.map((category, index) => (
                <Grid item xs={index === 0 ? 12 : 6} key={category}>
                  <Box>
                    <Typography
                      variant="h6"
                      style={{
                        backgroundColor: "#2F7D32",
                        color: "#ffffff",
                        padding: "5px",
                        borderRadius: "5px",
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {category}
                    </Typography>
                    <Droppable id={category}>
                      {categoryAssignments[category].map((pm) => (
                        <Paper
                          key={pm.id}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "8px",
                            marginBottom: "5px",
                            backgroundColor: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            textAlign: "left",
                          }}
                        >
                          <Typography variant="body2">
                            {`${pm.full_name}`} <br/> <span style={{ fontSize: "12px" }}>{`(${pm.email})`}</span>
                          </Typography>

                          <IconButton
                            size="small"
                            onClick={() => handleResetPM(pm.id, category)}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Paper>
                      ))}
                    </Droppable>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </DndContext>
      <CustomConfirmBox
        open={openConfirm}
        title={confirmData.title}
        message={confirmData.message}
        confirmText={confirmData.confirmText}
        onConfirm={handleConfirm}
        onCancel={handleCloseConfirm}
      />
    </div>
  );
};

const mapStateToProps = ({ 
  user: {
    currentUser,
    allActivePms,
    rosterCategory
  }
}) => ({
  currentUser: currentUser,
  loading: allActivePms?.loading,
  error: allActivePms?.error,
  allActivePms: allActivePms?.data,
  rosterLoading: rosterCategory?.loading,
  rosterCategory: rosterCategory?.data
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: bindActionCreators(userActions.getCurrentUser, dispatch),
  getAllActivePMs: bindActionCreators(userActions.getAllActivePMs, dispatch),
  manageRosterCategory: bindActionCreators(userActions.manageRosterCategory, dispatch),
});

Roster.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  allActivePms: PropTypes.array.isRequired,
  getAllActivePMs: PropTypes.func.isRequired,
  rosterLoading: PropTypes.bool.isRequired,
  rosterCategory: PropTypes.object.isRequired,
  manageRosterCategory: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Roster);
