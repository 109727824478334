import { call, put, takeLatest } from "redux-saga/effects";

import { 
  searchStaffingReportSuccess,
  searchStaffingReportFail,
  getPmActivityHeatmapDataSuccess,
  getPmActivityHeatmapDataFail,
  getPmEfficiencyChartDataSuccess,
  getPmEfficiencyChartDataFail,
  getPmAssignedSpillsChartDataSuccess,
  getPmAssignedSpillsChartDataFail,
  getPacketReviewerAssignedSpillsChartDataSuccess,
  getPacketReviewerAssignedSpillsChartDataFail,
  getDocInContractorRevisionStatusCountChartDataSuccess,
  getDocInContractorRevisionStatusCountChartDataFail,
  getPacketReviewerBillableAmountChartDataSuccess,
  getPacketReviewerBillableAmountChartDataFail,
  getPmNotesActivityHeatmapDataSuccess,
  getPmNotesActivityHeatmapDataFail,
  getPmNotesActivityChartDataSuccess,
  getPmNotesActivityChartDataFail,
  getPmClientCountChartDataFail,
  getPmClientCountChartDataSuccess,
  getPmSpillsNotesChartDataSuccess,
  getPmSpillsNotesChartDataFail,
  getPmInvoiceAmountChartDataSuccess,
  getPmInvoiceAmountChartDataFail,
  getPmPaidNotesChartDataSuccess,
  getPmPaidNotesChartDataFail,
  getPmUnpaidNotesChartDataFail,
  getPmUnpaidNotesChartDataSuccess,
  getDownloadStaffingReportSuccess,
  getDownloadStaffingReportFail
} from "../actionCreators/Staffing";
import { doPost, REQUEST_TYPE } from "../utils";

function* searchStaffingReport({ obj }) {
  try {
    const response = yield doPost(`/search`, obj, null, REQUEST_TYPE.STAFFING);
    
    yield put(searchStaffingReportSuccess(response.data));
  } catch (err) {
    const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
    yield put(searchStaffingReportFail(err.response.data.message || err.message));
    yield call(delay, 2000);
    yield put(searchStaffingReportFail());
  }
};

function* getPmActivityHeatmapData({ obj }) {
  try {
    const response = yield doPost(`/chart/pm-activity-heatmap`, obj, null, REQUEST_TYPE.STAFFING);
    
    yield put(getPmActivityHeatmapDataSuccess(response.data));
  } catch (err) {
    yield put(getPmActivityHeatmapDataFail(err.response.data.message || err.message));
    yield put(getPmActivityHeatmapDataFail());
  }
};

function* getPmEfficiencyChartData({ obj }) {
  try {
    const response = yield doPost(`/chart/pm-efficiency`, obj, null, REQUEST_TYPE.STAFFING);
    
    yield put(getPmEfficiencyChartDataSuccess(response.data));
  } catch (err) {
    yield put(getPmEfficiencyChartDataFail(err.response.data.message || err.message));
    yield put(getPmEfficiencyChartDataFail());
  }
};

function* getPmAssignedSpillsChartData({ obj }) {
  try {
    const response = yield doPost(`/chart/pm-assigned-spills`, obj, null, REQUEST_TYPE.STAFFING);
    
    yield put(getPmAssignedSpillsChartDataSuccess(response.data));
  } catch (err) {
    yield put(getPmAssignedSpillsChartDataFail(err.response.data.message || err.message));
    yield put(getPmAssignedSpillsChartDataFail());
  }
};

function* getPacketReviewerAssignedSpillsChartData({ obj }) {
  try {
    const response = yield doPost(`/chart/packet-reviewer-assigned-spills`, obj, null, REQUEST_TYPE.STAFFING);
    
    yield put(getPacketReviewerAssignedSpillsChartDataSuccess(response.data));
  } catch (err) {
    yield put(getPacketReviewerAssignedSpillsChartDataFail(err.response.data.message || err.message));
    yield put(getPacketReviewerAssignedSpillsChartDataFail());
  }
};

function* getDocInContractorRevisionStatusCountChartData({ obj }) {
  try {
    const response = yield doPost(`/chart/sent-back-to-contractor`, obj, null, REQUEST_TYPE.STAFFING);
    
    yield put(getDocInContractorRevisionStatusCountChartDataSuccess(response.data));
  } catch (err) {
    yield put(getDocInContractorRevisionStatusCountChartDataFail(err.response.data.message || err.message));
    yield put(getDocInContractorRevisionStatusCountChartDataFail());
  }
};

function* getPacketReviewerBillableAmountChartData({ obj }) {
  try {
    const response = yield doPost(`/chart/packet-reviewer-billable-amount`, obj, null, REQUEST_TYPE.STAFFING);
    
    yield put(getPacketReviewerBillableAmountChartDataSuccess(response.data));
  } catch (err) {
    yield put(getPacketReviewerBillableAmountChartDataFail(err.response.data.message || err.message));
    yield put(getPacketReviewerBillableAmountChartDataFail());
  }
};

function* getPmNotesActivityHeatmapData({ obj }) {
  try {
    const response = yield doPost(`/chart/pm-notes-activity-heatmap`, obj, null, REQUEST_TYPE.STAFFING);
    
    yield put(getPmNotesActivityHeatmapDataSuccess(response.data));
  } catch (err) {
    yield put(getPmNotesActivityHeatmapDataFail(err.response.data.message || err.message));
    yield put(getPmNotesActivityHeatmapDataFail());
  }
};

function* getPmNotesActivityChartData({ obj }) {
  try {
    const response = yield doPost(`/chart/pm-notes-activity`, obj, null, REQUEST_TYPE.STAFFING);
    
    yield put(getPmNotesActivityChartDataSuccess(response.data));
  } catch (err) {
    yield put(getPmNotesActivityChartDataFail(err.response.data.message || err.message));
    yield put(getPmNotesActivityChartDataFail());
  }
};

function* getPmClientCountChartData({ obj }) {
  try {
    const response = yield doPost(`/chart/pm-clients`, obj, null, REQUEST_TYPE.STAFFING);
    
    yield put(getPmClientCountChartDataSuccess(response.data));
  } catch (err) {
    yield put(getPmClientCountChartDataFail(err.response.data.message || err.message));
    yield put(getPmClientCountChartDataFail());
  }
};

function* getPmSpillsNotesChartData({ obj }) {
  try {
    const response = yield doPost(`/chart/pm-spills-notes`, obj, null, REQUEST_TYPE.STAFFING);
    
    yield put(getPmSpillsNotesChartDataSuccess(response.data));
  } catch (err) {
    yield put(getPmSpillsNotesChartDataFail(err.response.data.message || err.message));
    yield put(getPmSpillsNotesChartDataFail());
  }
};

function* getPmInvoiceAmountChartData({ obj }) {
  try {
    const response = yield doPost(`/chart/pm-invoice-amount`, obj, null, REQUEST_TYPE.STAFFING);
    
    yield put(getPmInvoiceAmountChartDataSuccess(response.data));
  } catch (err) {
    yield put(getPmInvoiceAmountChartDataFail(err.response.data.message || err.message));
    yield put(getPmInvoiceAmountChartDataFail());
  }
};

function* getPmPaidNotesChartData({ obj }) {
  try {
    const response = yield doPost(`/chart/pm-paid-notes`, obj, null, REQUEST_TYPE.STAFFING);
    
    yield put(getPmPaidNotesChartDataSuccess(response.data));
  } catch (err) {
    yield put(getPmPaidNotesChartDataFail(err.response.data.message || err.message));
    yield put(getPmPaidNotesChartDataFail());
  }
};

function* getPmUnpaidNotesChartData({ obj }) {
  try {
    const response = yield doPost(`/chart/pm-unpaid-notes`, obj, null, REQUEST_TYPE.STAFFING);
    
    yield put(getPmUnpaidNotesChartDataSuccess(response.data));
  } catch (err) {
    yield put(getPmUnpaidNotesChartDataFail(err.response.data.message || err.message));
    yield put(getPmUnpaidNotesChartDataFail());
  }
};

function* getDownloadStaffingReport({ obj }) {
  try {
    const response = yield doPost(`/download/search/report`, obj, null, REQUEST_TYPE.STAFFING);
    
    yield put(getDownloadStaffingReportSuccess(response.data));
  } catch (err) {
    yield put(getDownloadStaffingReportFail(err.response.data.message || err.message));
    yield put(getDownloadStaffingReportFail());
  }
};

export default function* watcher() {
  yield takeLatest("SEARCH_STAFFING_REPORT", searchStaffingReport);
  yield takeLatest("GET_PM_ACTIVITY_HEATMAP_DATA", getPmActivityHeatmapData);
  yield takeLatest("GET_PM_EFFICIENCY_CHART_DATA", getPmEfficiencyChartData);
  yield takeLatest("GET_PM_ASSIGNED_SPILLS_CHART_DATA", getPmAssignedSpillsChartData);
  yield takeLatest("GET_PACKET_REVIEWER_ASSIGNED_SPILLS_CHART_DATA", getPacketReviewerAssignedSpillsChartData);
  yield takeLatest("GET_DOC_IN_CONTRACTOR_REVISION_STATUS_COUNT_CHART_DATA", getDocInContractorRevisionStatusCountChartData);
  yield takeLatest("GET_PACKET_REVIEWER_BILLABLE_AMOUNT_CHART_DATA", getPacketReviewerBillableAmountChartData);
  yield takeLatest("GET_PM_NOTES_ACTIVITY_HEATMAP_DATA", getPmNotesActivityHeatmapData);
  yield takeLatest("GET_PM_NOTES_ACTIVITY_CHART_DATA", getPmNotesActivityChartData);
  yield takeLatest("GET_PM_CLIENT_COUNT_CHART_DATA", getPmClientCountChartData);
  yield takeLatest("GET_PM_SPILLS_NOTES_CHART_DATA", getPmSpillsNotesChartData);
  yield takeLatest("GET_PM_INVOICE_AMOUNT_CHART_DATA", getPmInvoiceAmountChartData);
  yield takeLatest("GET_PM_PAID_NOTES_CHART_DATA", getPmPaidNotesChartData);
  yield takeLatest("GET_PM_UNPAID_NOTES_CHART_DATA", getPmUnpaidNotesChartData);
  yield takeLatest("GET_DOWNLOAD_STAFFING_REPORT", getDownloadStaffingReport);
};