const initialState = {
  success: false,
  loading: false,
  error: null,
  isSidebarVisible: true,
  searchedStaffingReport: {
    data: [],
    pagination: {},
    loading: false,
    error: null,
  },
  staffingDataForFilter: {
    data: [],
    loading: false,
    error: null,
  },
  finalStaffingSearchData: {
    data: {},
    loading: false,
    error: null,
  },
  pmActivityHeatmapData: {
    data: [],
    loading: false,
    error: null,
  },
  pmEfficiencyChartData: {
    data: [],
    loading: false,
    error: null,
  },
  pmAssignedSpillsChartData: {
    data: [],
    loading: false,
    error: null,
  },
  packetReviewerAssignedSpillsChartData: {
    data: [],
    loading: false,
    error: null,
  },
  docInContractorRevisionStatusCountChartData: {
    data: [],
    loading: false,
    error: null,
  },
  packetReviewerBillableAmountChartData: {
    data: [],
    loading: false,
    error: null,
  },
  pmNotesActivityHeatmapData: {
    data: [],
    loading: false,
    error: null,
  },
  pmNotesActivityChartData: {
    data: [],
    loading: false,
    error: null,
  },
  pmClientCountChartData: {
    data: [],
    loading: false,
    error: null,
  },
  pmSpillsNotesChartData: {
    data: [],
    loading: false,
    error: null,
  },
  pmInvoiceAmountChartData: {
    data: [],
    loading: false,
    error: null,
  },
  pmPaidNotesChartData: {
    data: [],
    loading: false,
    error: null,
  },
  pmUnpaidNotesChartData: {
    data: [],
    loading: false,
    error: null,
  },
  downloadStaffingReport: {
    data: [],
    loading: false,
    error: null,
  },
};

const staffingReducer = (
  state = initialState,
  { obj, type, error, payload, data, pagination }
) => {
  switch (type) {
    case "SET_SIDEBAR_VISIBILITY":
      const invisiblePages = ['efficiency-report', 'packet-reviewer-report', 'notes-activity-report', 'roster'];
      return {
        ...state,
        isSidebarVisible: !invisiblePages.includes(payload),
      };

    case "SET_STAFFING_DATA_FOR_FILTER":
      return {
        ...state,
        staffingDataForFilter: {
          ...state.staffingDataForFilter,
          data: data,
        },
        type,
        loading: false,
      };
      
    case "SEARCH_STAFFING_REPORT":
      return {
        ...state,
        type,
        searchedStaffingReport: {
          ...state.searchedStaffingReport,
          loading: true
        }
      };

    case "SEARCH_STAFFING_REPORT_SUCCESS":
      return {
        ...state,
        success: true,
        type,
        payload,
        searchedStaffingReport: {
          ...state.searchedStaffingReport,
          data: data.data,
          pagination: { ...data.pagination },
          loading: false
        },
      }

    case "SEARCH_STAFFING_REPORT_FAILURE":
      return {
        ...state,
        error,
        success: false,
        searchedStaffingReport: {
          ...state.searchedStaffingReport,
          loading: false,
        },
        type,
      }

    case "SET_FINAL_STAFFING_SEARCH_DATA":
      return {
        ...state,
        finalStaffingSearchData: {
          ...state.finalStaffingSearchData,
          data: data,
        },
        type,
        loading: false,
      };

    case "GET_PM_ACTIVITY_HEATMAP_DATA":
      return {
        ...state,
        type,
        pmActivityHeatmapData: {
          ...state.pmActivityHeatmapData,
          loading: true,
        }
      };

    case "GET_PM_ACTIVITY_HEATMAP_DATA_SUCCESS":
      return {
        ...state,
        type,
        success: true,
        pmActivityHeatmapData: {
          ...state.pmActivityHeatmapData,
          data: data.data,
          loading: false
        },
      };

    case "GET_PM_ACTIVITY_HEATMAP_DATA_FAILURE":
      return {
        ...state,
        error,
        success: false,
        type,
        pmActivityHeatmapData: {
          ...state.pmActivityHeatmapData,
          loading: false,
        }
      };
    
    case "GET_PM_EFFICIENCY_CHART_DATA":
      return {
        ...state,
        type,
        pmEfficiencyChartData: {
          ...state.pmEfficiencyChartData,
          loading: true,
        }
      };

    case "GET_PM_EFFICIENCY_CHART_DATA_SUCCESS":
      return {
        ...state,
        type,
        success: true,
        pmEfficiencyChartData: {
          ...state.pmEfficiencyChartData,
          data: data.data,
          loading: false
        },
      };

    case "GET_PM_EFFICIENCY_CHART_DATA_FAILURE":
      return {
        ...state,
        error,
        success: false,
        type,
        pmEfficiencyChartData: {
          ...state.pmEfficiencyChartData,
          loading: false,
        }
      }

    case "GET_PM_ASSIGNED_SPILLS_CHART_DATA":
      return {
        ...state,
        type,
        pmAssignedSpillsChartData: {
          ...state.pmAssignedSpillsChartData,
          loading: true
        }
      };

    case "GET_PM_ASSIGNED_SPILLS_CHART_DATA_SUCCESS":
      return {
        ...state,
        type,
        success: true,
        pmAssignedSpillsChartData: {
          ...state.pmAssignedSpillsChartData,
          data: data.data,
          loading: false
        },
      };

    case "GET_PM_ASSIGNED_SPILLS_CHART_DATA_FAILURE":
      return {
        ...state,
        error,
        success: false,
        type,
        pmAssignedSpillsChartData: {
          ...state.pmAssignedSpillsChartData,
          loading: false
        }
      };
      
    case "GET_PACKET_REVIEWER_ASSIGNED_SPILLS_CHART_DATA":
      return {
        ...state,
        type,
        packetReviewerAssignedSpillsChartData: {
          ...state.packetReviewerAssignedSpillsChartData,
          loading: true
        }
      };

    case "GET_PACKET_REVIEWER_ASSIGNED_SPILLS_CHART_DATA_SUCCESS":
      return {
        ...state,
        type,
        success: true,
        packetReviewerAssignedSpillsChartData: {
          ...state.packetReviewerAssignedSpillsChartData,
          data: data.data,
          loading: false
        },
      };

    case "GET_PACKET_REVIEWER_ASSIGNED_SPILLS_CHART_DATA_FAILURE":
      return {
        ...state,
        error,
        success: false,
        type,
        packetReviewerAssignedSpillsChartData: {
          ...state.packetReviewerAssignedSpillsChartData,
          loading: false
        }
      };
    
    case "GET_DOC_IN_CONTRACTOR_REVISION_STATUS_COUNT_CHART_DATA":
      return {
        ...state,
        type,
        docInContractorRevisionStatusCountChartData: {
          ...state.docInContractorRevisionStatusCountChartData,
          loading: true
        }
      };

    case "GET_DOC_IN_CONTRACTOR_REVISION_STATUS_COUNT_CHART_DATA_SUCCESS":
      return {
        ...state,
        type,
        success: true,
        docInContractorRevisionStatusCountChartData: {
          ...state.docInContractorRevisionStatusCountChartData,
          data: data.data,
          loading: false
        },
      };

    case "GET_DOC_IN_CONTRACTOR_REVISION_STATUS_COUNT_CHART_DATA_FAILURE":
      return {
        ...state,
        error,
        success: false,
        type,
        docInContractorRevisionStatusCountChartData: {
          ...state.docInContractorRevisionStatusCountChartData,
          loading: false
        }
      };
    
    case "GET_PACKET_REVIEWER_BILLABLE_AMOUNT_CHART_DATA":
      return {
        ...state,
        type,
        packetReviewerBillableAmountChartData: {
          ...state.packetReviewerBillableAmountChartData,
          loading: true
        }
      };

    case "GET_PACKET_REVIEWER_BILLABLE_AMOUNT_CHART_DATA_SUCCESS":
      return {
        ...state,
        type,
        success: true,
        packetReviewerBillableAmountChartData: {
          ...state.packetReviewerBillableAmountChartData,
          data: data?.data?.map(row => ({ ...row, y: parseFloat(row?.y) })),
          loading: false
        },
      };
    
    case "GET_PACKET_REVIEWER_BILLABLE_AMOUNT_CHART_DATA_FAILURE":
      return {
        ...state,
        error,
        success: false,
        type,
        packetReviewerBillableAmountChartData: {
          ...state.packetReviewerBillableAmountChartData,
          loading: false
        }
      };

    case "GET_PM_NOTES_ACTIVITY_HEATMAP_DATA":
      return {
        ...state,
        type,
        pmNotesActivityHeatmapData: {
          ...state.pmNotesActivityHeatmapData,
          loading: true,
        }
      };

    case "GET_PM_NOTES_ACTIVITY_HEATMAP_DATA_SUCCESS":
      return {
        ...state,
        type,
        success: true,
        pmNotesActivityHeatmapData: {
          ...state.pmNotesActivityHeatmapData,
          data: data.data,
          loading: false
        },
      };

    case "GET_PM_NOTES_ACTIVITY_HEATMAP_DATA_FAILURE":
      return {
        ...state,
        error,
        success: false,
        type,
        pmNotesActivityHeatmapData: {
          ...state.pmNotesActivityHeatmapData,
          loading: false,
        }
      };

    case "GET_PM_NOTES_ACTIVITY_CHART_DATA":
      return {
        ...state,
        type,
        pmNotesActivityChartData: {
          ...state.pmNotesActivityChartData,
          loading: true
        }
      };
    
    case "GET_PM_NOTES_ACTIVITY_CHART_DATA_SUCCESS":
      return {
        ...state,
        type,
        success: true,
        pmNotesActivityChartData: {
          ...state.pmNotesActivityChartData,
          data: data?.data,
          loading: false
        },
      };
    
    case "GET_PM_NOTES_ACTIVITY_CHART_DATA_FAILURE":
      return {
        ...state,
        error,
        success: false,
        type,
        pmNotesActivityChartData: {
          ...state.pmNotesActivityChartData,
          loading: false
        }
      };

    case "GET_PM_CLIENT_COUNT_CHART_DATA":
      return {
        ...state,
        type,
        pmClientCountChartData: {
          ...state.pmClientCountChartData,
          loading: true
        }
      };
    
    case "GET_PM_CLIENT_COUNT_CHART_DATA_SUCCESS":
      return {
        ...state,
        type,
        success: true,
        pmClientCountChartData: {
          ...state.pmClientCountChartData,
          data: data?.data,
          loading: false
        },
      };
    
    case "GET_PM_CLIENT_COUNT_CHART_DATA_FAILURE":
      return {
        ...state,
        error,
        success: false,
        type,
        pmClientCountChartData: {
          ...state.pmClientCountChartData,
          loading: false
        }
      };
    
    case "GET_PM_SPILLS_NOTES_CHART_DATA":
      return {
        ...state,
        type,
        pmSpillsNotesChartData: {
          ...state.pmSpillsNotesChartData,
          loading: true
        }
      };
    
    case "GET_PM_SPILLS_NOTES_CHART_DATA_SUCCESS":
      return {
        ...state,
        type,
        success: true,
        pmSpillsNotesChartData: {
          ...state.pmSpillsNotesChartData,
          data: data?.data?.map(row => ({ ...row, y: parseFloat(row?.y) })),
          loading: false
        },
      };
    
    case "GET_PM_SPILLS_NOTES_CHART_DATA_FAILURE":
      return {
        ...state,
        error,
        success: false,
        type,
        pmSpillsNotesChartData: {
          ...state.pmSpillsNotesChartData,
          loading: false
        }
      };

    case "GET_PM_INVOICE_AMOUNT_CHART_DATA":
      return {
        ...state,
        type,
        pmInvoiceAmountChartData: {
          ...state.pmInvoiceAmountChartData,
          loading: true
        }
      };
    
    case "GET_PM_INVOICE_AMOUNT_CHART_DATA_SUCCESS":
      return {
        ...state,
        type,
        success: true,
        pmInvoiceAmountChartData: {
          ...state.pmInvoiceAmountChartData,
          data: data?.data?.map(row => ({ ...row, y: parseFloat(row?.y) })),
          loading: false
        },
      };
    
    case "GET_PM_INVOICE_AMOUNT_CHART_DATA_FAILURE":
      return {
        ...state,
        error,
        success: false,
        type,
        pmInvoiceAmountChartData: {
          ...state.pmInvoiceAmountChartData,
          loading: false
        }
      };

    case "GET_PM_PAID_NOTES_CHART_DATA":
      return {
        ...state,
        type,
        pmPaidNotesChartData: {
          ...state.pmPaidNotesChartData,
          loading: true
        }
      };
    
    case "GET_PM_PAID_NOTES_CHART_DATA_SUCCESS":
      return {
        ...state,
        type,
        success: true,
        pmPaidNotesChartData: {
          ...state.pmPaidNotesChartData,
          data: data?.data?.map(row => ({ ...row, y: parseFloat(row?.y) })),
          loading: false
        },
      };
    
    case "GET_PM_PAID_NOTES_CHART_DATA_FAILURE":
      return {
        ...state,
        error,
        success: false,
        type,
        pmPaidNotesChartData: {
          ...state.pmPaidNotesChartData,
          loading: false
        }
      };

    case "GET_PM_UNPAID_NOTES_CHART_DATA":
      return {
        ...state,
        type,
        pmUnpaidNotesChartData: {
          ...state.pmUnpaidNotesChartData,
          loading: true
        }
      };
    
    case "GET_PM_UNPAID_NOTES_CHART_DATA_SUCCESS":
      return {
        ...state,
        type,
        success: true,
        pmUnpaidNotesChartData: {
          ...state.pmUnpaidNotesChartData,
          data: data?.data?.map(row => ({ ...row, y: parseFloat(row?.y) })),
          loading: false
        },
      };
    
    case "GET_PM_UNPAID_NOTES_CHART_DATA_FAILURE":
      return {
        ...state,
        error,
        success: false,
        type,
        pmUnpaidNotesChartData: {
          ...state.pmUnpaidNotesChartData,
          loading: false
        }
      };

    case "GET_DOWNLOAD_STAFFING_REPORT":
      return {
        ...state,
        type,
        downloadStaffingReport: {
          ...state.downloadStaffingReport,
          loading: true
        }
      };
    
    case "GET_DOWNLOAD_STAFFING_REPORT_SUCCESS":
      return {
        ...state,
        type,
        success: true,
        downloadStaffingReport: {
          ...state.downloadStaffingReport,
          data: data,
          loading: false
        },
      };
    
    case "GET_DOWNLOAD_STAFFING_REPORT_FAILURE":
      return {
        ...state,
        error,
        success: false,
        type,
        downloadStaffingReport: {
          ...state.downloadStaffingReport,
          loading: false
        }
      };

    default:
      return state;
  }
};

export default staffingReducer;