import moment from "moment";
import { PDFDocument } from "pdf-lib";

// escape special characters
export default function escapeRegExp(string) {
  return string?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const formatCurrencyToUSD = (amount) => {
  amount = amount?.replace(/,/g, '');
  return Number(amount)?.toLocaleString('en-US', {
    minimumFractionDigits: 2
  });
};

export const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

// Function to check if PDF is valid using pdf-lib
export const isPdfValid = async (file) => {
  try {
    const arrayBuffer = await file.arrayBuffer();
    await PDFDocument.load(arrayBuffer); // Try to load the PDF
    return true; // Valid PDF
  } catch (error) {
    return false; // Invalid PDF
  }
};

// Function to calculate fiscal values based on the selected range
export const getFiscalConditions = (fiscalRange, fiscalWeek = 0, fiscalYear = moment().year()) => {
  const currentDate = moment(); // Current date
  let fiscalConditions = [];
  let date_from = "";
  let date_to = "";

  switch (fiscalRange) {
    case "FISCAL_WEEKS":  
      const fiscalYearStart = moment(`${fiscalYear}-01-01`).startOf("year"); // Start of the current year
      const selectedWeekStart = fiscalYearStart.clone().add(fiscalWeek, "weeks").startOf("week");
      const selectedWeekEnd = selectedWeekStart.clone().add(6, "days");
    
      // Set date range
      date_from = selectedWeekStart;
      date_to = selectedWeekEnd;

      // Handle edge case where last week spans two different years
      if (selectedWeekStart.year() !== selectedWeekEnd.year()) {
        fiscalConditions.push({ week: 0, year: selectedWeekEnd.year() });
        fiscalConditions.push({ week: 52, year: selectedWeekStart.year() });
      } else {
        fiscalConditions.push({ week: fiscalWeek, year: fiscalYearStart.year() });
      }
      break;

    case "ONE_MONTH":
      const oneMonthAgo = currentDate.clone().subtract(1, "month");

      // Set date range
      date_from = oneMonthAgo.clone().startOf("month");
      date_to = oneMonthAgo.clone().endOf("month");

      fiscalConditions.push({ month: oneMonthAgo.month() + 1, year: oneMonthAgo.year() });
      break;

    case "TWO_MONTH":
      const twoMonthsAgo = currentDate.clone().subtract(2, "months");
      const lastMonth = currentDate.clone().subtract(1, "month");

      // Set date range
      date_from = twoMonthsAgo.clone().startOf("month");
      date_to = lastMonth.clone().endOf("month");

      for (let i = 2; i >= 1; i--) {
        const pastMonth = currentDate.clone().subtract(i, "months");
        fiscalConditions.push({ month: pastMonth.month() + 1, year: pastMonth.year() });
      }
      break;

    case "THREE_MONTH":
      const threeMonthsAgo = currentDate.clone().subtract(3, "months");
      const lastMonthThree = currentDate.clone().subtract(1, "month");

      // Set date range
      date_from = threeMonthsAgo.clone().startOf("month");
      date_to = lastMonthThree.clone().endOf("month");

      for (let i = 3; i >= 1; i--) {
        const pastMonth = currentDate.clone().subtract(i, "months");
        fiscalConditions.push({ month: pastMonth.month() + 1, year: pastMonth.year() });
      }
      break;

    default:
      return null; // Return null for invalid input
  }

  return { 
    fiscal_conditions: fiscalConditions, 
    fiscal_range: { date_from, date_to } 
  };
};