export const setStaffingDataForFilter = (data) => ({
  type: "SET_STAFFING_DATA_FOR_FILTER",
  data
});

export const searchStaffingReport = (obj) => ({
  type: "SEARCH_STAFFING_REPORT",
  obj,
});

export const searchStaffingReportSuccess = (data) => ({
  type: "SEARCH_STAFFING_REPORT_SUCCESS",
  data,
});

export const searchStaffingReportFail = (error) => ({
  type: "SEARCH_STAFFING_REPORT_FAIL",
  error,
});

export const setFinalStaffingSearchData = (data) => ({
  type: "SET_FINAL_STAFFING_SEARCH_DATA",
  data,
});

export const getPmActivityHeatmapData = (obj) => ({
  type: "GET_PM_ACTIVITY_HEATMAP_DATA",
  obj
});

export const getPmActivityHeatmapDataSuccess = (data) => ({
  type: "GET_PM_ACTIVITY_HEATMAP_DATA_SUCCESS",
  data
});

export const getPmActivityHeatmapDataFail = (error) => ({
  type: "GET_PM_ACTIVITY_HEATMAP_DATA_FAIL",
  error
});

export const getPmEfficiencyChartData = (obj) => ({
  type: "GET_PM_EFFICIENCY_CHART_DATA",
  obj,
});

export const getPmEfficiencyChartDataSuccess = (data) => ({
  type: "GET_PM_EFFICIENCY_CHART_DATA_SUCCESS",
  data,
});

export const getPmEfficiencyChartDataFail = (error) => ({
  type: "GET_PM_EFFICIENCY_CHART_DATA_FAIL",
  error,
});

export const getPmAssignedSpillsChartData = (obj) => ({
  type: "GET_PM_ASSIGNED_SPILLS_CHART_DATA",
  obj
});

export const getPmAssignedSpillsChartDataSuccess = (data) => ({
  type: "GET_PM_ASSIGNED_SPILLS_CHART_DATA_SUCCESS",
  data
});

export const getPmAssignedSpillsChartDataFail = (error) => ({
  type: "GET_PM_ASSIGNED_SPILLS_CHART_DATA_FAIL",
  error
});

export const getPacketReviewerAssignedSpillsChartData = (obj) => ({
  type: "GET_PACKET_REVIEWER_ASSIGNED_SPILLS_CHART_DATA",
  obj
});

export const getPacketReviewerAssignedSpillsChartDataSuccess = (data) => ({
  type: "GET_PACKET_REVIEWER_ASSIGNED_SPILLS_CHART_DATA_SUCCESS",
  data
});

export const getPacketReviewerAssignedSpillsChartDataFail = (error) => ({
  type: "GET_PACKET_REVIEWER_ASSIGNED_SPILLS_CHART_DATA_FAIL",
  error
});

export const getDocInContractorRevisionStatusCountChartData = (obj) => ({
  type: "GET_DOC_IN_CONTRACTOR_REVISION_STATUS_COUNT_CHART_DATA",
  obj
});

export const getDocInContractorRevisionStatusCountChartDataSuccess = (data) => {
  return ({
  type: "GET_DOC_IN_CONTRACTOR_REVISION_STATUS_COUNT_CHART_DATA_SUCCESS",
  data
})};

export const getDocInContractorRevisionStatusCountChartDataFail = (error) => ({
  type: "GET_DOC_IN_CONTRACTOR_REVISION_STATUS_COUNT_CHART_DATA_FAIL",
  error
});

export const getPacketReviewerBillableAmountChartData = (obj) => ({
  type: "GET_PACKET_REVIEWER_BILLABLE_AMOUNT_CHART_DATA",
  obj
});

export const getPacketReviewerBillableAmountChartDataSuccess = (data) => ({
  type: "GET_PACKET_REVIEWER_BILLABLE_AMOUNT_CHART_DATA_SUCCESS",
  data
});

export const getPacketReviewerBillableAmountChartDataFail = (error) => ({
  type: "GET_PACKET_REVIEWER_BILLABLE_AMOUNT_CHART_DATA_FAIL",
  error
});

export const getPmNotesActivityHeatmapData = (obj) => ({
  type: "GET_PM_NOTES_ACTIVITY_HEATMAP_DATA",
  obj
});

export const getPmNotesActivityHeatmapDataSuccess = (data) => ({
  type: "GET_PM_NOTES_ACTIVITY_HEATMAP_DATA_SUCCESS",
  data
});

export const getPmNotesActivityHeatmapDataFail = (error) => ({
  type: "GET_PM_NOTES_ACTIVITY_HEATMAP_DATA_FAIL",
  error
});

export const getPmNotesActivityChartData = (obj) => ({
  type: "GET_PM_NOTES_ACTIVITY_CHART_DATA",
  obj
});

export const getPmNotesActivityChartDataSuccess = (data) => ({
  type: "GET_PM_NOTES_ACTIVITY_CHART_DATA_SUCCESS",
  data
});

export const getPmNotesActivityChartDataFail = (error) => ({
  type: "GET_PM_NOTES_ACTIVITY_CHART_DATA_FAIL",
  error
});

export const getPmClientCountChartData = (obj) => ({
  type: "GET_PM_CLIENT_COUNT_CHART_DATA",
  obj
});

export const getPmClientCountChartDataSuccess = (data) => ({
  type: "GET_PM_CLIENT_COUNT_CHART_DATA_SUCCESS",
  data
});

export const getPmClientCountChartDataFail = (error) => ({
  type: "GET_PM_CLIENT_COUNT_CHART_DATA_FAIL",
  error
});

export const getPmSpillsNotesChartData = (obj) => ({
  type: "GET_PM_SPILLS_NOTES_CHART_DATA",
  obj
});

export const getPmSpillsNotesChartDataSuccess = (data) => ({
  type: "GET_PM_SPILLS_NOTES_CHART_DATA_SUCCESS",
  data
});

export const getPmSpillsNotesChartDataFail = (error) => ({
  type: "GET_PM_SPILLS_NOTES_CHART_DATA_FAIL",
  error
});

export const getPmInvoiceAmountChartData = (obj) => ({
  type: "GET_PM_INVOICE_AMOUNT_CHART_DATA",
  obj
});

export const getPmInvoiceAmountChartDataSuccess = (data) => ({
  type: "GET_PM_INVOICE_AMOUNT_CHART_DATA_SUCCESS",
  data
});

export const getPmInvoiceAmountChartDataFail = (error) => ({
  type: "GET_PM_INVOICE_AMOUNT_CHART_DATA_FAIL",
  error
});

export const getPmPaidNotesChartData = (obj) => ({
  type: "GET_PM_PAID_NOTES_CHART_DATA",
  obj
});

export const getPmPaidNotesChartDataSuccess = (data) => ({
  type: "GET_PM_PAID_NOTES_CHART_DATA_SUCCESS",
  data
});

export const getPmPaidNotesChartDataFail = (error) => ({
  type: "GET_PM_PAID_NOTES_CHART_DATA_FAIL",
  error
});

export const getPmUnpaidNotesChartData = (obj) => ({
  type: "GET_PM_UNPAID_NOTES_CHART_DATA",
  obj
});

export const getPmUnpaidNotesChartDataSuccess = (data) => ({
  type: "GET_PM_UNPAID_NOTES_CHART_DATA_SUCCESS",
  data
});

export const getPmUnpaidNotesChartDataFail = (error) => ({
  type: "GET_PM_UNPAID_NOTES_CHART_DATA_FAIL",
  error
});

export const getDownloadStaffingReport = (obj) => ({
  type: "GET_DOWNLOAD_STAFFING_REPORT",
  obj
});

export const getDownloadStaffingReportSuccess = (data) => ({
  type: "GET_DOWNLOAD_STAFFING_REPORT_SUCCESS",
  data
});

export const getDownloadStaffingReportFail = (error) => ({
  type: "GET_DOWNLOAD_STAFFING_REPORT_FAIL",
  error
});

export const setSidebarVisibility = (pageType) => ({
  type: 'SET_SIDEBAR_VISIBILITY',
  payload: pageType,
});
