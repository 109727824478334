import {
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Switch,
  Typography,
  makeStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { CustomProgressLoader } from '../../../../../Components';
import ReactSelect from 'react-select';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { useForm } from 'react-hook-form';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import * as clientActions from '../../../../../actionCreators/Client';
import * as spillActions from '../../../../../actionCreators/Spill';
import * as contractorAction from '../../../../../actionCreators/Contractor';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import {
  USER_TYPE,
  calculateContractors,
  contractorColourStyles,
  convertToGroupedOptions,
  filterOption,
  forceToCST,
  getContractorOptions,
  orderAlphabaticallyByKey
} from '../../../../../utils';

const useStyles = makeStyles((theme) => ({
  alignLeft: {
    textAlign: 'left'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  root: {
    paddingTop: theme.spacing(2)
  },
  form: {
    width: '100%'
  },
  title: {
    textAlign: 'left',
    marginBottom: theme.spacing(2)
  },
  clearButton: {
    textAlign: 'right'
  },
  leftAlign: {
    textAlign: 'left'
  }
}));

const ContractorRevisionReport = ({
  reportsAllow,
  goToSpills,
  batchUpdateAllow,
  switchReportsHandler,
  history,
  loading,
  clientOrganizationNames,
  getOrganizationNames,
  setSpillDataForFilter,
  currentUser,
  setFinalSpillsSearchData,
  searchSpills,
  updateContractorRevisionReportDownloadState,
  contractorRevisionReportDownload,
  contractorsWithAddress,
  getContractorsWithAddress
}) => {
  const classes = useStyles();
  const { handleSubmit, register } = useForm();
  document.title = 'Contractor Revision Report Search';
  const location = history.location;

  const [snackBarSeverity, setSnackBarSeverity] = useState('');
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  // Organizations Related States
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [selectedChildOrganizations, setSelectedChildOrganizations] = useState(
    []
  );

  // Organization State
  const [organizations, setOrganizations] = useState([]);

  // Child organization switch
  const [isChildOrganizationSelected, setIsChildOrganizationSelected] =
    useState(false);

  // Child organization State
  const [childOrganizations, setChildOrganizations] = useState([]);

  // Selected Contractor State
  const [contractorOptions, setContractorOptions] = useState([]);
  const [selectedContractors, setSelectedContractors] =
    useState([]);

  // Date Range Related States
  const [openedOn, setOpenedOn] = useState(null);
  const [openedTo, setOpenedTo] = useState(null);
  const [closedOn, setClosedOn] = useState(null);
  const [closedTo, setClosedTo] = useState(null);

  const [textFieldsData, setTextFieldsData] = React.useState({
    job_no: ''
  });

  const handleFieldChange = (key) => (event) => {
    setTextFieldsData((val) => ({ ...val, [key]: event?.target?.value }));
  };

  useEffect(() => {
    handleClearSearch();
  }, [location.key]);

  // fetch the client organizations and contractor with addresses
  useEffect(() => {
    getOrganizationNames();
    getContractorsWithAddress({ withAttachmentExpiry: false });
  }, []);

  // prepopulate with the data from redux
  useEffect(() => {
    setSelectedOrganizations(
      contractorRevisionReportDownload?.data?.selectedOrganizations || []
    );
    setSelectedChildOrganizations(
      contractorRevisionReportDownload?.data?.selectedChildOrganizations || []
    );
    setOpenedOn(contractorRevisionReportDownload?.data?.openedOn || null);
    setOpenedTo(contractorRevisionReportDownload?.data?.openedTo || null);
    setClosedOn(contractorRevisionReportDownload?.data?.closedOn || null);
    setClosedTo(contractorRevisionReportDownload?.data?.closedTo || null);
    setIsChildOrganizationSelected(
      contractorRevisionReportDownload?.data?.isChildOrganizationSelected ||
        false
    );
    setSelectedContractors(
      contractorRevisionReportDownload?.data?.selectedContractors || []
    );
  }, []);

  // update the redux state
  useEffect(() => {
    updateContractorRevisionReportDownloadState({
      selectedChildOrganizations,
      selectedOrganizations,
      openedOn,
      openedTo,
      closedOn,
      closedTo,
      isChildOrganizationSelected,
      selectedContractors: selectedContractors ?? []
    });
  }, [
    selectedChildOrganizations,
    selectedOrganizations,
    openedOn,
    openedTo,
    closedOn,
    closedTo,
    isChildOrganizationSelected,
    selectedContractors
  ]);

  // Map the organization into value, label and associated Org
  useEffect(() => {
    const organizationOptions = clientOrganizationNames?.data?.map(
      (organization) => {
        const value = {
          value: organization.id,
          label: organization.name,
          associated_orgs: organization.AssociatedOrganizations
        };
        const allOptions = { ...value };

        return allOptions;
      }
    );

    setOrganizations(organizationOptions);
  }, [clientOrganizationNames]);

  useEffect(() => {
    let newContractors = getContractorOptions(contractorsWithAddress?.data);
    setContractorOptions(convertToGroupedOptions(newContractors));
  }, [contractorsWithAddress]);

  // Validation function for date format
  const isValidDate = (value) => {
    // Skip validation if the value is empty
    if (!value) {
      return true;
    }
    return moment(value, 'MM-DD-YYYY hh:mm a', true).isValid();
  };

  const handleClearSearch = () => {
    // Reset organization selected state
    setSelectedOrganizations([]);

    // Reset child organization state
    setSelectedChildOrganizations([]);

    // Reset child organization switch
    setIsChildOrganizationSelected(false);

    // clear child organization options
    setChildOrganizations([]);

    // clear contractor selected state
    setSelectedContractors([]);

    // Reset date range related states
    setOpenedOn(null);
    setOpenedTo(null);
    setClosedOn(null);
    setClosedTo(null);
  };

  const dateKeys = {
    openedOn: 'opened_on',
    openedTo: 'opened_to',
    closedOn: 'closed_on',
    closedTo: 'closed_to'
  };

  // Handle Date Related States
  const handleDateRange = (key) => (date) => {
    switch (key) {
      case dateKeys.openedOn:
        setOpenedOn(date);
        break;
      case dateKeys.openedTo:
        setOpenedTo(date);
        break;
      case dateKeys.closedOn:
        setClosedOn(date);
        break;
      case dateKeys.closedTo:
        setClosedTo(date);
        break;

      default:
        break;
    }
  };

  const handleSnackBarClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  };

  const handleChildToggle = () => {
    setIsChildOrganizationSelected(!isChildOrganizationSelected);

    if (selectedOrganizations && Array.isArray(selectedOrganizations)) {
      for (let organization of selectedOrganizations) {
        // Iterate over the associated organizations of the client
        let associatedOrganizationsOfClient = organization?.associated_orgs;
        if (Array.isArray(associatedOrganizationsOfClient)) {
          for (const childOrganization of associatedOrganizationsOfClient) {
            // Create a new child organization object
            const newChildOrg = {
              label: childOrganization.client_organization.name,
              value: childOrganization.client_organization.id,
              parent: childOrganization.org_id
            };

            // Update the state with the new child organization
            setChildOrganizations((previousChildOrg) => {
              const filteredPreviousChildOrg = previousChildOrg.filter(
                (org) => org.value !== newChildOrg.value
              );
              return [...filteredPreviousChildOrg, newChildOrg];
            });
          }
        }
      }
    }
  };

  /**
   * Handles the change event of the organization select component.
   * @param {Array} selectedOptions - The selected options from the organization select component.
   * @param {Object} - An object containing the action and removedValue.
   * @property {string} action - The action performed on the select component.
   * @property {Object} removedValue - The removed value from the select component.
   */
  const handleOrganizationChange = (
    selectedOptions,
    { action, removedValue }
  ) => {
    // Update the selected organizations state
    setSelectedOrganizations(selectedOptions);

    // Clear child organizations and selected child organizations when 'clear' action is performed
    if (action === 'clear') {
      setChildOrganizations([]);
      setSelectedChildOrganizations([]);
    }

    // Remove child organizations and selected child organizations when 'remove-value' action is performed
    if (action === 'remove-value') {
      setChildOrganizations((prevChildOrgnanizations) => {
        return prevChildOrgnanizations?.filter(
          (org) => org?.parent !== removedValue?.value
        );
      });
      setSelectedChildOrganizations((prevSelectedChildOrg) => {
        return prevSelectedChildOrg?.filter(
          (org) => org?.parent !== removedValue?.value
        );
      });
    }

    if (
      isChildOrganizationSelected &&
      selectedOptions &&
      selectedOptions.length > 0
    ) {
      // Get the last selected organization
      let lastSelectedOption = selectedOptions[selectedOptions.length - 1];

      // Get the associated organizations of the last selected organization
      let associatedOrganizationsOfClient = lastSelectedOption?.associated_orgs;

      // If the last selected organization has associated organizations
      if (associatedOrganizationsOfClient?.length > 0) {
        for (const childOrganization of associatedOrganizationsOfClient) {
          const newChildOrg = {
            label: childOrganization?.client_organization?.name,
            value: childOrganization?.client_organization?.id,
            parent: childOrganization?.org_id
          };

          setChildOrganizations((previousChildOrg) => {
            const filteredPreviousChildOrg = previousChildOrg?.filter(
              (org) => org?.value !== newChildOrg?.value
            );
            return [...filteredPreviousChildOrg, newChildOrg];
          });
        }
      }
    }
  };

  const handleChildOrganizationChange = (selectedOptions) => {
    setSelectedChildOrganizations(selectedOptions);
  };

  const onFinish = async () => {
    // Validate date format before submission
    if (!isValidDate(openedOn) || !isValidDate(openedTo)) {
      setSnackBarSeverity('error');
      setSnackBarMessage('Please enter valid date');
      setSnackBarOpen(true);
      return;
    }

    // Manage the search spill filter state
    const searchData = {
      opened_on: forceToCST(openedOn),
      opened_to: forceToCST(openedTo),
      organizations: selectedOrganizations,
      childOrganizations: selectedChildOrganizations,
      selectContractors: calculateContractors(
        selectedContractors,
        contractorOptions
      ),
      contractors: [],
      statusData: [],
      job_no: textFieldsData?.job_no || null,
      id: currentUser?.data?.id,
      role: currentUser?.data?.role?.role,
      permission: currentUser?.data?.role?.permission?.view_related_spills,
      page: 0,
      contractorRevisionReport: true
    };

    const mappedSelectedOrg = selectedOrganizations?.map((org) => {
      return org?.label;
    });

    const mappedSelectedChildOrg = selectedChildOrganizations?.map((org) => {
      return org?.label;
    });

    const selectContractors = selectedContractors?.map((contractor) => {
      return contractor.label;
    });

    const dataForFilter = [
      openedOn ? `Opened On: ${openedOn}, ` : null,
      openedTo ? `Opened To: ${openedTo}, ` : null,
      mappedSelectedOrg?.length > 0
        ? `Organizations: [${mappedSelectedOrg}], `
        : null,
      selectContractors?.length > 0
        ? `Selected Contractors: [${selectContractors}], `
        : null,
      isChildOrganizationSelected && mappedSelectedChildOrg?.length > 0
        ? `Child Organizations: [${mappedSelectedChildOrg}], `
        : null
    ];

    setSpillDataForFilter(dataForFilter);

    setFinalSpillsSearchData(searchData);
    searchSpills({
      ...searchData,
      userType: currentUser?.data?.test_user
        ? USER_TYPE.TEST
        : USER_TYPE.GENERAL
    });
    history.push(
      `/dashboard/search-results?token=${batchUpdateAllow}&report=${reportsAllow}&contractor-revision=true`
    );
  };

  return (
    <>
      <div className={classes.root} />

      <Snackbar
        open={snackBarOpen}
        autoHideDuration={5000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackBarClose} severity={snackBarSeverity}>
          {snackBarMessage}
        </Alert>
      </Snackbar>

      <Container maxWidth='lg'>
        <form
          onSubmit={handleSubmit(() => onFinish())}
          className={classes.form}
        >
          <Grid container spacing={3}>
            <Grid item xs={8} className={classes.title}>
              <Typography variant='h5' gutterBottom>
                Contractor Revision Report
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.clearButton}>
              <Button
                variant='contained'
                color='primary'
                type='reset'
                onClick={() => handleClearSearch()}
              >
                Clear filters
              </Button>
            </Grid>
          </Grid>
          {loading ? (
            <CustomProgressLoader show={true} />
          ) : (
            <Grid container spacing={3}>
              <Grid
                item
                xs={2}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                Job Number
              </Grid>

              <Grid item xs={10}>
                <TextField
                  id='job_no'
                  name='job_no'
                  inputProps={{
                    value: textFieldsData.job_no
                  }}
                  onChange={handleFieldChange('job_no')}
                  fullWidth
                  inputRef={register}
                  autoComplete='job_no'
                />
              </Grid>
              <Grid item xs={2}>
                Organizations
              </Grid>
              <Grid item xs={7} className={classes.alignLeft}>
                <ReactSelect
                  value={selectedOrganizations}
                  required
                  isMulti
                  onChange={handleOrganizationChange}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.label}
                  options={orderAlphabaticallyByKey(organizations)}
                  closeMenuOnSelect={false}
                />
              </Grid>
              <Grid item xs={3} className={classes.leftAlign}>
                {' '}
                <Switch
                  labelId='default-user-label'
                  id='default-switch-parent-user'
                  name='canSeeChildOrg'
                  color='primary'
                  checked={isChildOrganizationSelected}
                  onChange={handleChildToggle}
                />
                <Typography variant='subtitle' gutterBottom>
                  Child Organization
                </Typography>
              </Grid>

              {isChildOrganizationSelected ? (
                <>
                  <Grid item xs={2}>
                    Child Organizations
                  </Grid>
                  <Grid item xs={10} className={classes.alignLeft}>
                    <ReactSelect
                      value={selectedChildOrganizations}
                      required
                      isMulti
                      onChange={handleChildOrganizationChange}
                      options={orderAlphabaticallyByKey(childOrganizations)}
                      closeMenuOnSelect={false}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12}>
                <Typography variant='subtitle2'>
                  Open (MM-DD-YYYY hh:mm am/pm)
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    value={openedOn}
                    onChange={handleDateRange(dateKeys.openedOn)}
                    label={'Opened on'}
                    showTodayButton
                    ampmInClock={true}
                    clearable={true}
                    format='MM-DD-YYYY hh:mm a'
                    initialFocusedDate={moment(new Date())
                      .startOf('day')
                      .format('MM-DD-YYYY hh:mm a')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    value={openedTo}
                    onChange={handleDateRange(dateKeys.openedTo)}
                    label={'Opened to'}
                    showTodayButton
                    ampmInClock={true}
                    clearable={true}
                    format='MM-DD-YYYY hh:mm a'
                    initialFocusedDate={moment(new Date())
                      .startOf('day')
                      .format('MM-DD-YYYY hh:mm a')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={2}>
                <Typography variant='subtitle2'>Contractors</Typography>
              </Grid>
              <Grid item xs={10} className={classes.alignLeft}>
                <ReactSelect
                  required
                  isMulti
                  value={selectedContractors}
                  options={contractorOptions}
                  styles={contractorColourStyles()}
                  closeMenuOnSelect={false}
                  filterOption={filterOption}
                  onChange={setSelectedContractors}
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'right' }}>
                <Button
                  className={classes.button}
                  variant='contained'
                  color='primary'
                  type='submit'
                >
                  Search
                </Button>
                <Button
                  className={classes.button}
                  variant='contained'
                  color='primary'
                  onClick={() =>
                    (switchReportsHandler && switchReportsHandler(null)) ||
                    goToSpills()
                  }
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </form>
      </Container>
    </>
  );
};

const mapStateToProps = ({
  spill: { loading, contractorRevisionReportDownload },
  user,
  client,
  agency,
  contractor
}) => ({
  loading:
    user.loading ||
    loading ||
    client.loading ||
    agency.loading ||
    contractor.loading,
  clientOrganizationNames: client.clientOrganizationNames,
  currentUser: user.currentUser,
  contractorRevisionReportDownload,
  contractorsWithAddress: contractor.contractorsWithAddress
});

const mapDispatchToProps = (dispatch) => ({
  getOrganizationNames: bindActionCreators(
    clientActions.getOrganizationNames,
    dispatch
  ),
  setSpillDataForFilter: bindActionCreators(
    spillActions.setSpillDataForFilter,
    dispatch
  ),
  setFinalSpillsSearchData: bindActionCreators(
    spillActions.setFinalSpillsSearchData,
    dispatch
  ),
  searchSpills: bindActionCreators(spillActions.searchSpills, dispatch),
  updateContractorRevisionReportDownloadState: bindActionCreators(
    spillActions.updateContractorRejectionReportDownloadState,
    dispatch
  ),
  getContractorsWithAddress: bindActionCreators(
    contractorAction.getContractorsWithAddress,
    dispatch
  )
});

ContractorRevisionReport.propTypes = {
  getOrganizationNames: PropTypes.func.isRequired,
  clientOrganizationNames: PropTypes.object.isRequired,
  reportsAllow: PropTypes.bool.isRequired,
  goToSpills: PropTypes.func.isRequired,
  batchUpdateAllow: PropTypes.bool.isRequired,
  switchReportsHandler: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  updateContractorRevisionReportDownloadState: PropTypes.func.isRequired,
  contractorRevisionReportDownload: PropTypes.object.isRequired,
  getContractorsWithAddress: PropTypes.func.isRequired
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContractorRevisionReport)
);
