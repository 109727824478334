import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Button, makeStyles } from '@material-ui/core';
import Moment from "moment";

import { csvDownloader } from '../utils/csvDownloader';
import * as downloadActions from '../actionCreators/Download';
import {
  doPost,
  getSearchResultTableHeaders,
  REQUEST_TYPE,
  USER_TYPE,
  XLSX_DATATYPE
} from '../utils';
import { CustomProgressLoader } from '.';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 16
  }
}));
const DownloadFullCSV = ({
  type,
  loading,
  subType,
  showSpinner,
  currentUser,
  downloadType,
  downloadData,
  downloadRevenueReport,
  dataForSearch,
  searchFilters,
  clearDownload,
  searchQueryData,
  dataForDownload,
  simpleExportData,
  isSubrogationActive,
  isPendingDisposalActive,
  getAllfacilities,
  TeamData,
  isNotICS,
  isSubrogationReport,
  isRevenueAccrualReport,
  isContractorRevisionReport
}) => {
  const classes = useStyles();
  const [disable, setDisable] = React.useState(false);
  const [downloadSubType, setDownloadSubType] = React.useState('');
  const timeZoneOffSet = Intl.DateTimeFormat().resolvedOptions().timeZone;

  React.useEffect(() => {
    setDisable(loading);
    showSpinner && showSpinner(loading);
  }, [loading]);

  React.useEffect(() => {
    if (dataForDownload?.data?.length > 0) {
      fetchDataAndDownload();
    }
  }, [dataForDownload]);

  const fetchDataAndDownload = () => {
    if (
      dataForDownload?.data?.length > 0 ||
      getAllfacilities?.length > 0 ||
      (TeamData && Object.keys(TeamData)?.length > 0)
    ) {
      let mergedData = [];
      let title = "";
      let includeTitle = false;
      switch (type) {
        case 'User': {
          const tableHeaders = [
            { title: 'Name', key: 'full_name', type: XLSX_DATATYPE.STRING },
            { title: 'E-Mail', key: 'email', type: XLSX_DATATYPE.STRING },
            { title: 'Phone', key: 'phone', type: XLSX_DATATYPE.STRING },
            { title: 'Role', key: 'roleName', type: XLSX_DATATYPE.STRING },
            { title: 'Active', key: 'active', type: XLSX_DATATYPE.NUMBER }
          ];
          ['Contractor Admin']?.includes(currentUser?.data?.role?.role) ||
            tableHeaders.splice(2, 0, {
              title: 'Organization',
              key: 'organizationName',
              type: XLSX_DATATYPE.STRING
            });

          const headerTitles = tableHeaders.map((item) => {
            return item.title;
          });

          const allData = dataForDownload.data.map((item) => {
            const tableItems = [
              item?.full_name,
              item?.email,
              item?.phone,
              item?.role?.role,
              item?.active
            ];
            ['Contractor Admin']?.includes(currentUser?.data?.role?.role) ||
              tableItems.splice(2, 0, item?.client_organization?.name);
            return tableItems;
          });

          mergedData = [tableHeaders, headerTitles, ...allData];

          break;
        }
        case 'Contractor': {
          const tableHeaders = [
            {
              title: 'Contractor Name',
              key: 'name',
              type: XLSX_DATATYPE.STRING
            },
            { title: 'E-Mail', key: 'email', type: XLSX_DATATYPE.STRING },
            { title: 'Address', key: 'address', type: XLSX_DATATYPE.STRING },
            { title: 'Phone', key: 'phone', type: XLSX_DATATYPE.STRING },
            { title: 'Fax', key: 'fax', type: XLSX_DATATYPE.STRING },
            { title: 'Zip Code', key: 'zip_code', type: XLSX_DATATYPE.STRING },
            { title: 'City', key: 'city', type: XLSX_DATATYPE.STRING },
            { title: 'State', key: 'state', type: XLSX_DATATYPE.STRING },
            { title: 'Country', key: 'country', type: XLSX_DATATYPE.STRING }
          ];

          const headerTitles = tableHeaders.map((item) => {
            return item.title;
          });

          const allData = dataForDownload.data.map((item) => {
            const tableItems = [
              item?.name,
              item?.email,
              item?.address?.replace(/,/g, ' '),
              item?.phone,
              item?.fax,
              item?.zip_code,
              item?.city,
              item?.state,
              item?.country
            ];
            return tableItems;
          });

          mergedData = [tableHeaders, headerTitles, ...allData];

          break;
        }
        case 'Agency': {
          const tableHeaders = [
            { title: 'Agency Name', key: 'name', type: XLSX_DATATYPE.STRING },
            { title: 'Contact', key: 'contact', type: XLSX_DATATYPE.STRING },
            {
              title: 'Jurisdiction',
              key: 'jurisdiction',
              type: XLSX_DATATYPE.STRING
            },
            { title: 'Phone', key: 'phone', type: XLSX_DATATYPE.STRING },
            { title: 'Fax', key: 'fax', type: XLSX_DATATYPE.STRING },
            { title: 'Address', key: 'address', type: XLSX_DATATYPE.STRING },
            { title: 'City', key: 'city', type: XLSX_DATATYPE.STRING },
            { title: 'State', key: 'state', type: XLSX_DATATYPE.STRING },
            { title: 'Country', key: 'country', type: XLSX_DATATYPE.STRING }
          ];

          const headerTitles = tableHeaders.map((item) => {
            return item.title;
          });

          const allData = dataForDownload.data.map((item) => {
            const tableItems = [
              item?.name,
              item?.contact,
              item?.jurisdiction,
              item?.phone,
              item?.fax,
              item?.address?.replace(/,/g, ' '),
              item?.city,
              item?.state,
              item?.country
            ];
            return tableItems;
          });

          mergedData = [tableHeaders, headerTitles, ...allData];

          break;
        }
        case 'Client': {
          const tableHeaders = [
            {
              title: 'Organization Name',
              key: 'name',
              type: XLSX_DATATYPE.STRING
            },
            { title: 'Code', key: 'code', type: XLSX_DATATYPE.STRING },
            {
              title: 'Rate Type',
              key: 'rate_type',
              type: XLSX_DATATYPE.STRING
            },
            { title: 'Rate', key: 'rate', type: XLSX_DATATYPE.NUMBER },
            {
              title: 'Sales Person',
              key: 'sales_person',
              type: XLSX_DATATYPE.STRING
            },
            { title: 'Tax Id', key: 'tax_id', type: XLSX_DATATYPE.STRING },
            { title: 'Phone', key: 'phone', type: XLSX_DATATYPE.STRING },
            { title: 'Address', key: 'address', type: XLSX_DATATYPE.STRING },
            { title: 'City', key: 'city', type: XLSX_DATATYPE.STRING },
            { title: 'State', key: 'state', type: XLSX_DATATYPE.STRING },
            { title: 'Country', key: 'country', type: XLSX_DATATYPE.STRING }
          ];

          const headerTitles = tableHeaders.map((item) => {
            return item.title;
          });

          const allData = dataForDownload.data.map((item) => {
            const tableItems = [
              item?.name,
              item?.code,
              item?.rate_type,
              item?.rate,
              item?.sales_person,
              item?.tax_id,
              item?.phone,
              item?.address?.replace(/,/g, ' '),
              item?.city,
              item?.state,
              item?.country
            ];
            return tableItems;
          });

          mergedData = [tableHeaders, headerTitles, ...allData];

          break;
        }
        case 'SearchResults': {
          const tableHeaders = getSearchResultTableHeaders(
            downloadSubType,
            isSubrogationActive,
            currentUser,
            isPendingDisposalActive,
            searchQueryData?.isReport,
            isSubrogationReport,
            isRevenueAccrualReport,
            dataForDownload?.historyDate
          );
          if (isRevenueAccrualReport) {
            title = `Revenue Accrual Report Download: ${Moment()?.format("MM-DD-YYYY h:mm a")}`;
            includeTitle = true;
          }

          if (currentUser?.data?.role?.role === 'Corporate Admin') {
            tableHeaders.push({
              title: 'DAYS TO CLOSE',
              type: XLSX_DATATYPE.NUMBER
            });
            tableHeaders.push({
              title: 'DAYS TO PAY',
              type: XLSX_DATATYPE.NUMBER
            });
          }
          const headerTitles = tableHeaders.map((item) => {
            return item.title;
          });

          mergedData = [tableHeaders, headerTitles, ...dataForDownload.data];

          break;
        }
        case 'Facility': {
          const tableHeaders = [
            { title: 'Facility Name', type: XLSX_DATATYPE.STRING },
            { title: 'Internal Id', type: XLSX_DATATYPE.STRING },
            { title: 'Generator Status', type: XLSX_DATATYPE.STRING },
            { title: 'EPA Id', type: XLSX_DATATYPE.NUMBER },
            { title: 'Address', type: XLSX_DATATYPE.STRING },
            { title: 'City', type: XLSX_DATATYPE.STRING },
            { title: 'State', type: XLSX_DATATYPE.STRING },
            { title: 'Country', type: XLSX_DATATYPE.STRING },
            { title: 'Zip Code', type: XLSX_DATATYPE.STRING },
            { title: 'Phone Number', type: XLSX_DATATYPE.STRING },
            { title: 'Region', type: XLSX_DATATYPE.STRING },
            { title: 'Is Active', type: XLSX_DATATYPE.STRING }
          ];

          const headerTitles = tableHeaders.map((item) => {
            return item.title;
          });

          const allData = getAllfacilities?.map((item) => {
            const tableItems = [
              item?.name,
              item?.internal_id,
              item?.generator_status,
              item?.epa_id,
              item?.address,
              item?.city,
              item?.state,
              item?.country,
              item?.zip_code,
              item?.phone_number,
              item?.region,
              item?.is_active
            ];
            return tableItems;
          });
          mergedData = [tableHeaders, headerTitles, ...allData];
          break;
        }
        case 'Terminal': {
          const tableHeaders = [
            { title: 'Terminal Name', type: XLSX_DATATYPE.STRING },
            { title: 'Abbreviation', type: XLSX_DATATYPE.STRING },
            { title: 'EPA', type: XLSX_DATATYPE.STRING },
            { title: 'Phone', type: XLSX_DATATYPE.STRING },
            { title: 'State', type: XLSX_DATATYPE.STRING },
            { title: 'Address', type: XLSX_DATATYPE.STRING },
            { title: 'City', type: XLSX_DATATYPE.STRING },
            { title: 'Country', type: XLSX_DATATYPE.STRING },
            { title: 'Zip Code', type: XLSX_DATATYPE.STRING },
            { title: 'Team', type: XLSX_DATATYPE.STRING },
            { title: 'ClientName', type: XLSX_DATATYPE.STRING }
          ];

          const headerTitles = tableHeaders.map((item) => {
            return item.title;
          });

          const flatArray = [];
          for (const key in TeamData) {
            const terminals = TeamData[key];
            terminals.forEach((terminal) => {
              flatArray.push([
                terminal?.terminalName,
                terminal?.abbreviation,
                terminal?.epa,
                terminal?.phone,
                terminal?.state,
                terminal?.address,
                terminal?.city,
                terminal?.country || null,
                terminal?.zip,
                key,
                terminal?.clientName
              ]);
            });
          }

          mergedData = [tableHeaders, headerTitles, ...flatArray];
          break;
        }
        default: {
          break;
        }
      }
      let downloadName = '';
      if (type === 'SearchResults') {
        downloadName =
          downloadSubType !== 'Admin Download'
            ? 'searched-data-spreadsheet'
            : 'searched-data-spreadsheet(Admin)';
      } else {
        downloadName = `${dataForSearch ? 'searched-' : ''}${type}${
          dataForSearch ? '-(' + dataForSearch + ')-' : ''
        }`;
      }

      if (type === 'SearchResults') {
        const reportSubtypes = [
          'invoice_download_report',
          'contractor_rejections_report',
          'shipper_report',
          'revenue_accrual_report',
          'Admin Download'
        ];
        const subtypeMapping = {
          invoice_download_report: 'invoice-download-report',
          contractor_rejections_report: 'contractor-rejections-report',
          shipper_report: 'shipper-report',
          revenue_accrual_report: 'revenue-accrual-report'
        };
        downloadName = subtypeMapping[downloadSubType] || downloadName;
        if (downloadSubType === 'Download') {
          if (isSubrogationActive) {
            csvDownloader(mergedData, downloadName);
          } else if (!isSubrogationActive) {
            csvDownloader(mergedData, downloadName);
          } else if (isPendingDisposalActive) {
            csvDownloader(mergedData, downloadName);
          }
        } else if (isRevenueAccrualReport) {
            csvDownloader(mergedData, downloadName, title, includeTitle);
        } else if (reportSubtypes?.includes(downloadSubType)) {
          csvDownloader(mergedData, downloadName);
        }
      } else {
        csvDownloader(mergedData, downloadName);
      }

      clearDownload();
      setDownloadSubType('');
    }
  };

  const fetchData = async () => {
    if (type === 'Facility' || type === 'Terminal') {
      fetchDataAndDownload();
      return; // Exit early for special cases
    }
    setDownloadSubType(subType);
    let obj = {};
    if (type === 'User') {
      obj = {
        type,
        searchText: dataForSearch ? dataForSearch.searchText ?? null : null,
        searchFilters
      };
    } else if (type === 'SearchResults') {
      obj = {
        type,
        searchText: trimSearchQueryData(searchQueryData),
        subType: subType
      };
    } else {
      obj = {
        type,
        searchText: dataForSearch ?? null
      };
    }
    if (type === 'download') {
      obj = {
        ...obj,
        userType: currentUser?.data?.test_user
          ? USER_TYPE.TEST
          : USER_TYPE.GENERAL
      };
    }
    if (isSubrogationReport) {
      obj.subType = 'subrogation_report';
    }
    obj.timeZoneOffsetInMinutes = new Date().getTimezoneOffset();
    if (isContractorRevisionReport) {
      const response = await doPost(
        '/download/contractor-revision-report',
        { ...obj, timeZoneOffSet },
        null,
        REQUEST_TYPE.DOWNLOAD
      );
      let element = document.createElement('a');
      element.setAttribute('href', response?.data?.url);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      return false;
    }

    if (isRevenueAccrualReport) {
      obj = {
        ...obj,
        userType: currentUser?.data?.test_user
          ? USER_TYPE.TEST
          : USER_TYPE.GENERAL
      };
      downloadRevenueReport({ ...obj, timeZoneOffSet });
    } else {
      downloadData({ ...obj, timeZoneOffSet });
    }
  };

  function trimSearchQueryData(data) {
    const trimmedData = {};
    for (const key in data) {
      if (typeof data[key] === 'string') {
        trimmedData[key] = data[key].trim();
      } else {
        trimmedData[key] = data[key];
      }
    }
    return trimmedData;
  }

  return !loading ? (
    <Button
      color='primary'
      onClick={() => fetchData()}
      disabled={disable || isNotICS}
      className={classes.root}
    >
      {/* subType === "contractor_revision_report" */}
      {type === 'SearchResults'
        ? subType === 'shipper_report' ||
          subType === 'invoice_download_report' ||
          subType === 'contractor_rejections_report'
          ? 'Download Spreadsheet'
          : subType === 'revenue_accrual_report'
          ? ' Admin Download Spreadsheet'
          : subType + ' Spreadsheet'
        : 'Download'}
    </Button>
  ) : showSpinner ? (
    <> </>
  ) : (
    <CustomProgressLoader show={loading} />
  );
};

const mapStateToProps = ({
  user,
  download: { dataForDownload, downloadType, loading }
}) => ({
  currentUser: user.currentUser,
  dataForDownload,
  downloadType,
  loading
});

const mapDispatchToProps = (dispatch) => ({
  downloadData: bindActionCreators(downloadActions.download, dispatch),
  downloadRevenueReport: bindActionCreators(
    downloadActions.downloadRevenueReport,
    dispatch
  ),
  clearDownload: bindActionCreators(downloadActions.clearDownload, dispatch)
});

DownloadFullCSV.prototype = {
  dataToDownload: PropTypes.object.isRequired
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DownloadFullCSV)
);
