import { getFormattedTime } from "../utils";

const initialState = {
  success: false,
  loading: false,
  error: null,
  monthCount: null,
  spillSummary: {
    loading: false,
  },
  currentSpill: {
    data: null,
    loading: false,
    success: false,
    error: null,
  },
  updateStatusState: {
    success: false,
    loading: false,
    error: null,
    closedOn: "",
    updatedStatus: "",
  },
  currentSpillNotes: {
    data: null,
    loading: false,
    error: null,
  },
  editNoteState: {
    success: false,
    loading: false,
    error: null,
  },
  currentNoteHistory: {
    data: null,
    loading: false,
    error: null,
  },
  totalPrintableNotes: {
    data: null,
    loading: false,
    error: null,
  },
  paginatedNotes: {
    data: null,
    loading: false,
    error: null,
  },
  currentContractorHistory: {
    data: null,
    loading: false,
    error: null,
  },
  currentSpillServices: {
    data: null,
    loading: false,
    error: null,
  },
  spills: {
    data: [],
    pagination: {},
    loading: false,
    error: null,
  },
  material: {
    materials: [],
    materialsLoading: false,
    error: null,
  },
  materialClass: {
    data: [],
    loading: false,
    succes: false,
    error: null
  },
  deleteMaterialClass: {
    loading: false,
    success: false,
    error: false
  },
  status: {
    statuses: [],
    statusesLoading: false,
    error: null,
  },
  searchedSpills: {
    data: [],
    pagination: {},
    loading: false,
    error: null,
  },
  newNoteCreation: {
    newNoteId: null,
    loading: false,
    success: null,
  },
  noteVideosUpload: {
    data: [],
    loading: false,
    success: false,
    error: null,
  },
  spillFiltersState: {
    data: {},
    loading: false,
    error: null,
  },
  spillDataForFilterState: {
    data: [],
    loading: false,
    error: null,
  },
  finalSpillsSearchData: {
    data: {},
    loading: false,
    error: null,
  },
  selectedSpills: {
    data: [],
    loading: false,
    error: null,
  },
  spillAdminAttachmentHistory: {
    data: [],
    loading: false,
    error: null,
  },
  spillStatusHistory: {
    data: [],
    success: false,
    loading: false,
    error: null,
  },
  spillContractorsUpdateData: {
    loading: false,
    success: false,
    error: null,
  },
  adminAttachmentTypes: {
    data: [],
    error: null,
    loading: false,
    success: false,
  },
  scriptResponse: {
    data: [],
    error: null,
    success: false,
    loading: false,
  },
  documentationInReviewSpillsData: {
    data: [],
    error: null,
    success: false,
    loading: false,
  },
  packetReviewerAssignment: {
    data: [],
    error: null,
    success: false,
    loading: false,
  },
  packetAssignmentHistory: {
    data: [],
    success: false,
    loading: false,
    error: null,
  },
  requestDocuments: {
    data: {},
    error: null,
    success: false,
    loading: false,
  },
  cancelRequestedDocuments: {
    data: {},
    error: null,
    success: false,
    loading: false,
  },
  spillAdminRequestedDocumentation: {
    data: [],
    error: null,
    success: false,
    loading: false,
  },
  noteWorkStartTimeValidation: {
    data: {},
    error: null,
    success: false,
    loading: false,
  },
  reminder: {
    data: null,
    error: null,
    success: false,
    loading: false,
  },
  partialBillingDownload: {
    data: {},
    error: null,
    success: false,
    loading: false
  },
  invoiceReportDownload: {
    data: {},
    error: null,
    succes: false,
    loading: false
  },
  contractorRejectionReportDownload : {
    data: {},
    error: null,
    succes: false,
    loading: false
  },
  invoiceSubmittedToClientReportDownload: {
    data: {},
    error: null,
    succes: false,
    loading: false
  },
  revenueHistoryReport: {
    data: [],
    error: null,
    success: false,
    loading: false,
  },
};

const spillReducer = (
  state = initialState,
  { obj, type, error, payload, data, pagination }
) => {
  switch (type) {
    case "RESET_SPILL":
      return initialState;

    case "GET_SPILLS":
      return {
        ...initialState,
        type,
        loading: true,
      };

    case "GET_SPILLS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        spills: {
          ...state.spills,
          data: data.data.map((spill) => ({
            ...spill,
            closed_on: getFormattedTime(spill.closed_on),
          })),
          pagination: data.pagination,
        },
      };

    case "GET_SPILLS_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "GET_SPILL_STATUS_HISTORY":
      return {
        ...state,
        type,
        spillStatusHistory: {
          ...state.spillStatusHistory,
          loading: true,
        },
      };

    case "GET_SPILL_STATUS_HISTORY_SUCCESS":
      return {
        ...state,
        type,
        payload,
        spillStatusHistory: {
          ...state.spillStatusHistory,
          loading: false,
          success: true,
          data: data,
        },
      };

    case "GET_SPILL_STATUS_HISTORY_FAIL":
      return {
        ...state,
        type,
        spillStatusHistory: {
          ...state.spillStatusHistory,
          loading: false,
          success: false,
          error,
        },
      };

    case "GET_NOTE_HISTORY":
      return {
        ...state,
        type,
        currentNoteHistory: {
          ...state.currentNoteHistory,
          loading: true,
        },
      };

    case "GET_NOTE_HISTORY_SUCCESS":
      return {
        ...state,
        success: true,
        type,
        payload,
        currentNoteHistory: {
          ...state.currentNoteHistory,
          loading: false,
          data: data,
        },
        currentSpill: state.currentSpill,
      };

    case "GET_NOTE_HISTORY_FAIL":
      return {
        ...state,
        error,
        success: false,
        type,
        currentNoteHistory: {
          ...state.currentNoteHistory,
          loading: false,
        },
      };

    case "GET_TOTAL_NOTES_FOR_PRINT_SPILL":
      return {
        ...state,
        type,
        totalPrintableNotes: {
          ...state.totalPrintableNotes,
          loading: true,
        },
      };

    case "GET_TOTAL_NOTES_FOR_PRINT_SPILL_SUCCESS":
      return {
        ...state,
        success: true,
        payload,
        type,
        totalPrintableNotes: {
          ...state.totalPrintableNotes,
          loading: false,
          data: data,
        },
      };

    case "GET_TOTAL_NOTES_FOR_PRINT_SPILL_FAIL":
      return {
        ...state,
        success: false,
        type,
        totalPrintableNotes: {
          ...state.totalPrintableNotes,
          loading: false,
          error,
        },
      };

    case "CLEAR_SPILL_DATA":
      return {
        ...state,
        success: false,
        type,
        currentSpill: {
          ...state.currentSpill,
          loading: true,
          success: false,
        },
      };

    case "CLEAR_SPILL_DATA_SUCCESS":
      return {
        ...state,
        success: true,
        payload,
        type,
        currentSpill: {
          ...state.currentSpill,
          success: true,
          loading: false,
          data: { ...data },
        },
      };

    case "CLEAR_SPILL_DATA_FAIL":
      return {
        ...state,
        success: false,
        type,
        currentSpill: {
          ...state.currentSpill,
          loading: false,
          error,
        },
      };

    case "GET_PAGINATED_NOTES":
      return {
        ...state,
        type,
        paginatedNotes: {
          ...state.paginatedNotes,
          loading: true,
        },
      };

    case "GET_PAGINATED_NOTES_SUCCESS":
      return {
        ...state,
        success: true,
        payload,
        type,
        paginatedNotes: {
          ...state.paginatedNotes,
          loading: false,
          data: data.filter((element, index) => index < data?.length - 1),
          totalArea: data.slice(-1),
        },
        currentSpill: {
          ...state.currentSpill,
          data: {
            ...state.currentSpill?.data,
            spill: {
              ...state.currentSpill?.data?.spill,
              spill_notes_data: {
                ...state.currentSpill?.data?.spill.spill_notes_data,
                count: data[data?.length - 1]?.totalNotesCount,
                amount: data[data?.length - 1]?.totalNotesAmount,
                hour: data[data?.length - 1]?.totalNotesHours,
                totalNotesAttachments:
                  data[data?.length - 1]?.totalNotesAttachments,
                total_permeable_area: data[data?.length - 1]?.total_permeable_area,
                total_impermeable_area: data[data?.length - 1]?.total_impermeable_area
              },
              spill_notes: data.filter(
                (element, index) => index < data?.length - 1
              ),
            },
          },
        },
      };

    case "GET_PAGINATED_NOTES_FAIL":
      return {
        ...state,
        success: false,
        type,
        paginatedNotes: {
          ...state.paginatedNotes,
          loading: false,
          error,
        },
      };

    case "GET_CONTRACTOR_HISTORY":
      return {
        ...state,
        type,
        currentContractorHistory: {
          ...state.currentContractorHistory,
          loading: true,
        },
      };

    case "GET_CONTRACTOR_HISTORY_SUCCESS":
      return {
        ...state,
        success: true,
        type,
        payload,
        currentContractorHistory: {
          ...state.currentContractorHistory,
          loading: false,
          data: data,
        },
        currentSpill: state.currentSpill,
      };

    case "GET_CONTRACTOR_HISTORY_FAIL":
      return {
        ...state,
        error,
        success: false,
        type,
        currentContractorHistory: {
          ...state.currentContractorHistory,
          loading: false,
        },
      };

    case "CREATE_NEW_SPILL":
      return {
        ...state,
        type,
        loading: true,
        currentSpill: {
          loading: true,
        },
      };

    case "CREATE_NEW_SPILL_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        spills: {
          ...state.spills,
          data: [...state.spills.data, data],
        },
        currentSpill: {
          data,
          success: true,
          loading: false,
        },
      };

    case "CREATE_NEW_SPILL_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "CURRENT_SPILL_RESET":
      return {
        ...state,
        currentSpill: {
          data: null,
          loading: false,
          success: false,
          error: null,
        },
      }

    case "UPDATE_SPILL_FOR_ADDING_ARRIVED_ON_SITE_SERVICE":
      return {
        ...state,
        spillContractorsUpdateData: {
          ...state.spillContractorsUpdateData,
          loading: true,
        },
      };

    case "UPDATE_SPILL_FOR_ADDING_ARRIVED_ON_SITE_SERVICE_SUCCESS":
      return {
        ...state,
        type,
        payload,
        spillContractorsUpdateData: {
          ...state.spillContractorsUpdateData,
          loading: false,
          success: true,
        },
        currentSpill: {
          data,
          loading: false,
          success: true,
        },
      };

    case "UPDATE_SPILL_FOR_ADDING_ARRIVED_ON_SITE_SERVICE_FAIL":
      return {
        ...state,
        type,
        spillContractorsUpdateData: {
          loading: false,
          success: false,
          error: error,
        },
      };

    case "EDIT_SPILL":
      return {
        ...state,
        type,
        loading: true,
      };

    case "EDIT_SPILL_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        spills: {
          ...state.spills,
          data: state.spills.data.map((spillToUpdate) => {
            if (spillToUpdate.id === data.spill.id) {
              spillToUpdate = data.spill;
            }
            let updatedSpill = { ...spillToUpdate };
            return updatedSpill;
          }),
        },
        currentSpill: {
          data,
          loading: false,
          success: true,
          refreshMaterialClass: true,
          fetchCertainApiOnce: true
        },
      };

    case "RESET_FETCH_CERTAIN_API_FOR_EDIT_SPILL":
      return {
        ...state,
        currentSpill: {
          ...state?.currentSpill,
          fetchCertainApiOnce: false
        }
      }

    case "EDIT_SPILL_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };
    
    case "REFRESH_MATERIAL_CLASS_RESET":
      return {
        ...state,
        type,
        payload,
        currentSpill: {
          ...state.currentSpill,
          refreshMaterialClass: false
        },
      }

    case "UPLOAD_NOTE_VIDEOS":
      return {
        ...state,
        noteVideosUpload: {
          ...state.noteVideosUpload,
          loading: true,
        },
      };

    case "UPLOAD_NOTE_VIDEOS_SUCCESS":
      return {
        ...state,
        noteVideosUpload: {
          ...state.noteVideosUpload,
          data: data,
          success: true,
          loading: false,
        },
        currentSpill: {
          ...state.currentSpill,
          data: {
            ...state.currentSpill.data,
            spill: {
              ...state.currentSpill.data.spill,
              spill_notes: [...state.currentSpill.data.spill.spill_notes]?.map(
                (noteToUpdate, index, spill_notes) => {
                  if (spill_notes?.length - 1 === index) {
                    noteToUpdate = { ...noteToUpdate, note_attachments: data };
                  }
                  return noteToUpdate;
                }
              ),
            },
          },
        },
      };

    case "UPLOAD_NOTE_VIDEOS_FAIL":
      return {
        ...state,
        noteVideosUpload: {
          ...state.noteVideosUpload,
          success: false,
          loading: false,
          error,
        },
      };

    case "CREATE_SINGLE_NOTE":
      return {
        ...state,
        newNoteCreation: {
          ...state.newNoteCreation,
          loading: true,
          success: null,
        },
      };

    case "CREATE_SINGLE_NOTE_SUCCESS":
      return {
        ...state,
        newNoteCreation: {
          newNoteId: data?.note?.id,
          newNoteAttachments: data?.note?.note_attachments,
          loading: false,
          success: true,
        },
        currentSpill: {
          ...state.currentSpill,
          data: {
            ...state.currentSpill.data,
            connection:
              Object.keys(data?.connection)?.length > 0
                ? { ...data?.connection }
                : { ...state?.currentSpill?.data?.connection },
            spill: {
              ...state.currentSpill.data.spill,
              spill_notes:
                data.note.append_last === true
                  ? [...state.currentSpill.data.spill.spill_notes, data.note]
                  : [...state.currentSpill.data.spill.spill_notes],
            },
          },
        },
      };

    case "CREATE_SINGLE_NOTE_FAIL":
      return {
        ...state,
        newNoteCreation: {
          ...state.newNoteCreation,
          loading: false,
          success: false,
        },
      };

    case "GET_COMPLETE_SPILL":
      return {
        ...state,
        type,
        loading: true,
        currentSpill: {
          data: null,
          loading: true,
          success: false,
        },
      };

    case "GET_COMPLETE_SPILL_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        currentSpill: {
          // ...state.currentSpill,
          data: data,
          loading: false,
          success: true,
        },
      };

    case "GET_COMPLETE_SPILL_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        currentSpill: {
          // ...state.currentSpill,
          loading: false,
          success: false,
        },
        type,
      };

    case "UPDATE_STATUS":
      return {
        ...state,
        type,
        loading: true,
        currentSpill: {
          ...state.currentSpill,
          loading: true,
          success: false,
        },
        updateStatusState: {
          ...state.updateStatusState,
          loading: true,
        },
      };

    case "UPDATE_STATUS_SUCCESS":
      const currentSpill = data.batchUpdate
        ? state.currentSpill
        : {
          ...state.currentSpill,
          data: {
            ...state.currentSpill.data,
            spill: {
              ...state.currentSpill.data.spill,
              status: data.status,
              closed_on: data.closed_on,
            },
          },
          loading: false,
          success: true,
        };
      const searchedSpills = data.batchUpdate
        ? {
          data: data.spillsData,
          count: data.count,
        }
        : state.searchedSpills;
      return {
        ...state,
        loading: false,
        success: true,
        type,
        currentSpill: currentSpill,
        searchedSpills: searchedSpills,
        updateStatusState: {
          ...state.updateStatusState,
          success: true,
          loading: false,
          closedOn: data?.closed_on,
          status: data?.status,
        },
      };

    case "UPDATE_STATUS_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        currentSpill: {
          // ...state.currentSpill,
          loading: false,
          success: false,
        },
        updateStatusState: {
          ...state.updateStatusState,
          loading: false,
          error,
        },
        type,
      };

    case "SEARCH_SPILLS":
      return {
        ...state,
        type,
        loading: true,
      };

    case "SEARCH_SPILLS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        searchedSpills: {
          data: data.spillsData,
          count: data.count,
        },
      };

    case "SEARCH_SPILLS_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "SET_FINAL_SPILLS_SEARCH_DATA":
      return {
        ...state,
        finalSpillsSearchData: {
          ...state.finalSpillsSearchData,
          data: data,
        },
        type,
        loading: false,
      };

    case "SET_SPILL_FILTERS":
      return {
        ...state,
        spillFiltersState: {
          ...state.spillFiltersState,
          data: data,
        },
        type,
        loading: false,
      };

    case "SET_SELECTED_SPILLS":
      return {
        ...state,
        selectedSpills: {
          ...state.selectedSpills,
          data: data,
        },
        type,
        loading: false,
      };

    case "SET_SPILL_DATA_FOR_FILTER":
      return {
        ...state,
        spillDataForFilterState: {
          ...state.spillDataForFilterState,
          data: data,
        },
        type,
        loading: false,
      };

    case "CLEAR_SPILLS_SEARCH":
      return initialState;

    case "EDIT_NOTES":
      return {
        ...state,
        type,
        loading: true,
        editNoteState: {
          ...state.editNoteState,
          loading: true,
        },
      };


    case "EDIT_NOTES_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        editNoteState: {
          ...state.editNoteState,
          success: true,
          loading: false,
        },
        currentSpill: {
          ...state.currentSpill,
          data: {
            ...state.currentSpill.data,
            connection: { ...data?.data?.spillConnections },
            spill: {
              ...state.currentSpill.data.spill,
              spill_notes_data: data?.spill_notes_data,
              spill_notes: state.currentSpill.data.spill.spill_notes.map(
                (spillNoteToUpdate) => {
                  if (spillNoteToUpdate.id !== data?.data?.id) {
                    if (spillNoteToUpdate.id) {
                      let spillNote = {
                        ...spillNoteToUpdate,
                        total_permeable_area:
                          data[data?.length - 1]?.total_permeable_area || null,
                        total_impermeable_area:
                          data[data?.length - 1]?.total_impermeable_area || null,
                      };
                      return spillNote;
                    } else {
                      if (
                        data.data?.service_id === spillNoteToUpdate.service_id
                      ) {
                        let newSpillNote = { ...data?.data };
                        return newSpillNote;
                      }
                    }
                  }

                  if (spillNoteToUpdate.id === data.data?.id) {
                    spillNoteToUpdate = data?.data;
                  }
                  let updatedSpillNote = { ...spillNoteToUpdate };

                  return updatedSpillNote;
                }
              ),
            },
          },
          loading: false,
          success: true,
        },
      };


    case "EDIT_NOTES_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
        editNoteState: {
          ...state.editNoteState,
          loading: false,
          success: false,
          error,
        },
      };

    case "GET_MY_SPILLS":
      return {
        ...state,
        type,
        loading: true,
      };

    case "GET_MY_SPILLS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        spills: {
          data: data,
        },
      };

    case "GET_MY_SPILLS_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "CREATE_RESERVES":
      return {
        ...state,
        type,
        loading: true,
      };

    case "CREATE_RESERVES_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        currentSpill: {
          ...state.currentSpill,
          data: {
            ...state.currentSpill.data,
            spill: {
              ...state.currentSpill.data.spill,
              reserves: data,
            },
          },
          loading: false,
        },
      };

    case "CREATE_RESERVES_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "EDIT_RESERVES":
      return {
        ...state,
        type,
        loading: true,
      };

    case "EDIT_RESERVES_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        currentSpill: {
          ...state.currentSpill,
          data: {
            ...state.currentSpill.data,
            spill: {
              ...state.currentSpill.data.spill,
              reserves: data,
            },
          },
          loading: false,
        },
      };

    case "EDIT_RESERVES_FAIL":
      return {};

    case "CLOSE_SPILL":
      return {
        ...state,
        loading: true,
        type,
      };

    case "CLOSE_SPILL_SUCCESS":
      return {
        ...state,
        success: true,
        loading: false,
        type,
        currentSpill: {
          ...state.currentSpill,
          data: {
            ...state.currentSpill.data,
            spill: {
              ...state.currentSpill.data.spill,
              status: "Closed: Closed",
              closed_on: data.closed_on,
            },
          },
          loading: false,
        },
      };

    case "CLOSE_SPILL_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "ADD_ATTACHMENTS":
      return {
        ...state,
        type,
        loading: true,
      };

    case "ADD_ATTACHMENTS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        currentSpill: {
          ...state.currentSpill,
          data: { ...state.currentSpill.data, spill: data },
          loading: false,
        },
      };

    case "ADD_ATTACHMENTS_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "ASSOCIATED_NOTES_SERVICES":
      return {
        ...state,
        currentSpillServices: {
          data: null,
        },
      };

    case "ASSOCIATED_NOTES_SERVICES_SUCCESS":
      return {
        ...state,
        currentSpillServices: {
          data: data,
        },
      };

    case "ASSOCIATED_NOTES_SERVICES_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        type,
      };

    case "GET_MATERIAL":
      return {
        ...state,
        material: {
          materials: null,
          materialsLoading: true,
        },
      };

    case "GET_MATERIAL_SUCCESS":
      return {
        ...state,
        material: {
          materials: data,
          materialsLoading: false,
        },
      };

    case "GET_MATERIAL_FAIL":
      return {
        ...state,
        material: {
          ...state.material,
          materialsLoading: false,
        },
        error,
        success: false,
        loading: false,
        type,
      };

    case "GET_MATERIAL_CLASS":
      return {
        ...state,
        type,
        payload,
        materialClass: {
          ...state.materialClass,
          loading: true,
        },
      };

    case "GET_MATERIAL_CLASS_SUCCESS":
      return {
        ...state,
        type,
        payload,
        materialClass: {
          data: data,
          loading: false,
          success: true,
          error: null
        }
      };

    case "GET_MATERIAL_CLASS_FAIL":
      return {
        ...state,
        type,
        payload,
        materialClass: {
          data: [],
          loading: false,
          success: false,
          error,
        },
      };

    case "GET_MATERIAL_CLASS_RESET": 
      return {
        ...state,
        type,
        payload,
        materialClass: {
          ...state.materialClass,
          loading: false,
          success: false,
          error:null
        }
      }

    case "DELETE_MATERIAL_CLASS":
      return {
        ...state,
        type,
        payload,
        deleteMaterialClass: {
          laoding: true,
          success: false,
          error: false
        }
      }

    case "DELETE_MATERIAL_CLASS_SUCCESS":
      return {
        ...state,
        type,
        payload,
        deleteMaterialClass: {
          laoding: false,
          success: true,
          error: false
        }
      }

    case "DELETE_MATERIAL_CLASS_FAIL": 
      return {
        ...state,
        type,
        payload,
        deleteMaterialClass: {
          laoding: false,
          success: false,
          error
        }
      }

    case "DELETE_MATERIAL_CLASS_RESET":
      return {
        ...state,
        type,
        payload,
        deleteMaterialClass: {
          laoding: false,
          success: false,
          error: false
        }
      }

    case "UPDATE_MATERIAL_CLASS_ON_DELETE_SUCCESS":
      return {
        ...state,
        type,
        payload,
        materialClass: {
          ...state.materialClass,
          data: obj
        }
      }

    case "GET_STATUSES":
      return {
        ...state,
        status: {
          statuses: null,
          statusesLoading: true,
        },
      };

    case "GET_STATUSES_SUCCESS":
      return {
        ...state,
        status: {
          statuses: data,
          statusesLoading: false,
        },
      };

    case "GET_STATUSES_FAIL":
      return {
        ...state,
        status: {
          ...state.status,
          statusesLoading: false,
        },
        error,
        success: false,
        loading: false,
      };

    case "GET_SPILL_SUMMARY":
      return {
        ...state,
        loading: true,
        type,
        spillSummary: { loading: true },
      };

    case "GET_SPILL_SUMMARY_SUCCESS":
      return {
        ...state,
        spillSummary: { ...data, loading: false },
        success: true,
        loading: false,
        type,
      };

    case "GET_SPILL_SUMMARY_FAIL":
      return {
        ...state,
        success: false,
        loading: false,
        spillSummary: { loading: false },
        error,
        type,
      };

    case "GET_CLOSURE_SPILLS":
      return {
        ...state,
        type,
        spills: {
          ...state.spills,
          loading: true,
        },
      };

    case "GET_CLOSURE_SPILLS_SUCCESS":
      return {
        ...state,
        success: true,
        type,
        payload,
        spills: {
          ...state.spills,
          data: data.data,
          pagination: data.pagination,
          loading: false,
        },
      };

    case "GET_CLOSURE_SPILLS_FAIL":
      return {
        ...state,
        error,
        success: false,
        spills: {
          ...state.spills,
          loading: false,
        },
        type,
      };

    case "GET_SPILL_ADMIN_ATTACHMENT_HISTORY":
      return {
        ...state,
        type,
        spillAdminAttachmentHistory: {
          ...state.spillAdminAttachmentHistory,
          loading: true,
        },
      };

    case "GET_SPILL_ADMIN_ATTACHMENT_HISTORY_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        type,
        payload,
        spillAdminAttachmentHistory: {
          data: data,
          loading: false,
          error: null,
        },
      };

    case "GET_SPILL_ADMIN_ATTACHMENT_HISTORY_FAIL":
      return {
        ...state,
        error,
        success: false,
        loading: false,
        spillAdminAttachmentHistory: {
          ...state.spillAdminAttachmentHistory,
          loading: false,
        },
        type,
      };

    case "GET_SPILL_ADMIN_ATTACHMENT_TYPES":
      return {
        ...state,
        type,
        adminAttachmentTypes: {
          ...state.adminAttachmentTypes,
          loading: true,
        },
      };

    case "GET_SPILL_ADMIN_ATTACHMENT_TYPES_SUCCESS":
      return {
        ...state,
        type,
        payload,
        adminAttachmentTypes: {
          ...state.adminAttachmentTypes,
          loading: false,
          success: true,
          data: data?.data,
        },
      };

    case "GET_SPILL_ADMIN_ATTACHMENT_TYPES_FAIL":
      return {
        ...state,
        type,
        payload,
        adminAttachmentTypes: {
          ...state.adminAttachmentTypes,
          loading: false,
          success: false,
          error,
        },
      };

    case "GET_SCRIPT":
      return {
        ...state,
        type,
        payload,
        scriptResponse: {
          ...state.scriptResponse,
          loading: true,
        },
      };

    case "GET_SCRIPT_SUCCESS":
      return {
        ...state,
        type,
        payload,
        scriptResponse: {
          ...state.scriptResponse,
          data: data?.data?.success,
          success: true,
          loading: false,
        },
      };

    case "GET_SCRIPT_FAIL":
      return {
        ...state,
        type,
        payload,
        scriptResponse: {
          ...state.scriptResponse,
          success: false,
          loading: false,
          error,
        },
      };

    case "GET_DOCUMENTATION_IN_REVIEW_SPILLS":
      return {
        ...state,
        type,
        payload,
        documentationInReviewSpillsData: {
          ...state.documentationInReviewSpillsData,
          loading: true,
        },
      };

    case "GET_DOCUMENTATION_IN_REVIEW_SPILLS_SUCCESS":
      return {
        ...state,
        type,
        payload,
        documentationInReviewSpillsData: {
          ...state.documentationInReviewSpillsData,
          data: data,
          loading: false,
          success: true,
        },
      };

    case "GET_DOCUMENTATION_IN_REVIEW_SPILLS_FAIL":
      return {
        ...state,
        type,
        payload,
        documentationInReviewSpillsData: {
          ...state.documentationInReviewSpillsData,
          loading: false,
          success: false,
          error,
        },
      };

    case "ASSIGN_PACKET_REVIEWER":
      return {
        ...state,
        type,
        payload,
        packetReviewerAssignment: {
          ...state.packetReviewerAssignment,
          loading: true,
        },
      };

    case "ASSIGN_PACKET_REVIEWER_SUCCESS":
      return {
        ...state,
        type,
        payload,
        packetReviewerAssignment: {
          ...state.packetReviewerAssignment,
          data: data,
          loading: false,
          success: true,
        },
      };

    case "ASSIGN_PACKET_REVIEWER_FAIL":
      return {
        ...state,
        type,
        payload,
        packetReviewerAssignment: {
          ...state.packetReviewerAssignment,
          loading: false,
          success: false,
          error,
        },
      };

    case "GET_SPILL_PACKET_ASSIGNMENT_HISTORY":
      return {
        ...state,
        type,
        payload,
        packetAssignmentHistory: {
          ...state.packetAssignmentHistory,
          loading: true,
        },
      };

    case "GET_SPILL_PACKET_ASSIGNMENT_HISTORY_SUCCESS":
      return {
        ...state,
        success: true,
        type,
        payload,
        packetAssignmentHistory: {
          ...state.packetAssignmentHistory,
          loading: false,
          data: data,
        },
        currentSpill: state.currentSpill,
      };

    case "GET_SPILL_PACKET_ASSIGNMENT_HISTORY_FAIL":
      return {
        ...state,
        error,
        success: false,
        type,
        packetAssignmentHistory: {
          ...state.packetAssignmentHistory,
          loading: false,
          success: false,
          error,
        },
      };

    case "REQUEST_DOCUMENTATION":
      return {
        ...state,
        type,
        payload,
        requestDocuments: {
          ...state.requestDocuments,
          loading: true,
        },
      };

    case "REQUEST_DOCUMENTATION_SUCCESS":
      return {
        ...state,
        type,
        payload,
        requestDocuments: {
          ...state.requestDocuments,
          loading: false,
          success: true,
        },
        spillAdminRequestedDocumentation: {
          ...state.spillAdminRequestedDocumentation,
          data: data?.data,
        },
      };

    case "REQUEST_DOCUMENTATION_FAIL":
      return {
        ...state,
        type,
        payload,
        requestDocuments: {
          ...state.requestDocuments,
          loading: false,
          success: false,
          error,
        },
      };
    case "REQUEST_DOCUMENTATION_RESET":
      return {
        ...state,
        type,
        payload,
        requestDocuments: {
          data:{},
          error: null,
          success: false,
          loading: false,
        },
      }  

    case "CANCEL_REQUESTED_DOCUMENTATION":
      return {
        ...state,
        type,
        payload,
        cancelRequestedDocuments: {
          ...state.cancelRequestedDocuments,
          loading: true,
        },
      };

    case "CANCEL_REQUESTED_DOCUMENTATION_SUCCESS":
      return {
        ...state,
        type,
        payload,
        cancelRequestedDocuments: {
          ...state.cancelRequestedDocuments,
          data:{ pendingRequestedDocForSpill : data?.data?.pendingRequestedDocForSpill },
          loading: false,
          success: true,
        },
        spillAdminRequestedDocumentation: {
          ...state.spillAdminRequestedDocumentation,
          data: data?.data?.fetchSpillAdminRequested,
        },
      };
    case "CANCEL_REQUESTED_DOCUMENTATION_FAIL":
      return {
        ...state,
        type,
        payload,
        cancelRequestedDocuments: {
          ...state.cancelRequestedDocuments,
          loading: false,
          success: false,
          error,
        },
      };

    case "CANCEL_REQUESTED_DOCUMENTATION_RESET":
      return {
        ...state,
        type,
        payload,
        cancelRequestedDocuments: {
          data:{},
          error: null,
          success: false,
          loading: false
        },
      };
  
    case "GET_SPILL_ADMIN_REQUEST_DOCUMENTATION":
      return {
        ...state,
        type,
        payload,
        spillAdminRequestedDocumentation: {
          ...state.spillAdminRequestedDocumentation,
          loading: true,
        },
      };

    case "GET_SPILL_ADMIN_REQUEST_DOCUMENTATION_SUCCESS":
      return {
        ...state,
        type,
        payload,
        spillAdminRequestedDocumentation: {
          ...state.spillAdminRequestedDocumentation,
          data: data,
          loading: false,
          success: true,
        },
      };

    case "GET_SPILL_ADMIN_REQUEST_DOCUMENTATION_FAIL":
      return {
        ...state,
        type,
        payload,
        spillAdminRequestedDocumentation: {
          ...state.spillAdminRequestedDocumentation,
          loading: false,
          success: false,
          error,
        },
      };
    case "GET_REMINDER":
      return {
        ...state,
        type,
        payload,
      };

    case "GET_REMINDER_SUCCESS":
      return {
        ...state,
        success: true,
        type,
        payload,
        reminder: data,
      };

    case "GET_REMINDER_FAIL":
      return {
        ...state,
        error,
        success: false,
        type,
      };
    case "NOTE_WORK_TIME_VALIDATION":
      return {
        ...state,
        type,
        payload,
        noteWorkStartTimeValidation: {
          loading: true,
        },
      };
    case "NOTE_WORK_TIME_VALIDATION_FAIL":
      return {
        ...state,
        type,
        payload,
        noteWorkStartTimeValidation: {
          loading: false,
          success: false,
          error,
        },
      };
    case "NOTE_WORK_TIME_VALIDATION_SUCCESS":
      return {
        ...state,
        type,
        payload,
        noteWorkStartTimeValidation: {
          data: data,
          loading: false,
          success: true,
        }
      }
    case "NOTE_WORK_TIME_VALIDATION_RESET":
      return {
        ...state,
        type,
        payload,
        noteWorkStartTimeValidation: {
          loading: false,
          success: false,
          error: null,
        }
      }

    case "PARTIAL_BILLING_DOWNLOAD":
      return {
        ...state,
        type,
        payload,
        partialBillingDownload: {
          loading: true
        }
      }
    case "PARTIAL_BILLING_DOWNLOAD_SUCCESS":
      return {
        ...state,
        type,
        payload,
        partialBillingDownload: {
          data: data,
          loading: false,
          success: true,
          error: null
        }
      }
    case "PARTIAL_BILLING_DOWNLOAD_FAIL":
      return {
        ...state,
        type,
        payload,
        partialBillingDownload: {
          data: {},
          loading: false,
          success: false,
          error,
        }
      }
    case "PARTIAL_BILLING_DOWNLOAD_RESET":
      return {
        ...state,
        type,
        payload,
        partialBillingDownload: {
          ...state?.partialBillingDownload,
          loading: false,
          success: false,
        }
      }
    case "UPDATE_INVOICE_REPORT_DOWNLOAD_STATE":
      return {
        ...state,
        type,
        invoiceReportDownload: {
          ...state.invoiceReportDownload,
          data: obj
        }
      }
    case "UPDATE_CONTRACTOR_REJECTION_REPORT_DOWNLOAD_STATE":
      return {
        ...state,
        type,
        contractorRejectionReportDownload: {
          ...state.contractorRejectionReportDownload,
          data: obj
        }
      }
    case "UPDATE_INVOICE_SUBMITTED_TO_CLIENT_REPORT_DOWNLOAD_STATE":
      return {
        ...state,
        type,
        invoiceSubmittedToClientReportDownload: {
          ...state.invoiceSubmittedToClientReportDownload,
          data: obj
        }
      }
    case "GET_DOWNLOAD_REVENUE_HISTORY_REPORT":
        return {
            ...state,
            type,
            revenueHistoryReport: {
                data: [],
                loading: true,
                success: false,
                error,
              }
        };
    case "REVENUE_HISTORY_REPORT_SUCCESS":
        console.log('data', data);
      return {
        ...state,
        loading: false,
        success: false,
        type,
        payload,
        revenueHistoryReport: {
            data: data,
            loading: false,
            success: true,
        },
      };
    case "REVENUE_HISTORY_REPORT_FAIL":
      return {
        ...state,
        type,
        payload,
        revenueHistoryReport: {
          data: [],
          loading: false,
          success: false,
          error,
        }
      }
    default:
      return state;
  }
};

export default spillReducer;
