import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from "@material-ui/core";

const CustomConfirmBox = ({ open, title, message, confirmText, onConfirm, onCancel }) => {
  return (
    <Dialog open={open} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle style={{ textAlign: "center" }}>{title || "Confirm Action"}</DialogTitle>
      <DialogContent style={{ textAlign: "center", padding: "0px 20px" }}>
        <Typography variant="body1">{message || "Are you sure you want to proceed?"}</Typography>
      </DialogContent>
      <DialogActions style={{ margin: "20px 0", justifyContent: "center", gap: "10px" }}>
        <Button onClick={onCancel} variant="contained">
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="contained" color="primary">
          { confirmText || "Confirm" }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomConfirmBox;
