import React from "react";
import { useDroppable } from "@dnd-kit/core";
import { Box, Typography } from "@material-ui/core";

const Droppable = React.memo(({ id, children }) => {
  const { isOver, setNodeRef } = useDroppable({ id });

  const style = {
    backgroundColor: isOver ? "#e0f7fa" : "#fafafa",
    padding: "10px",
    border: `2px dashed ${isOver ? "#2F7D32" : "#ccc"}`,
    borderRadius: "8px",
    minHeight: "150px",
    textAlign: "center",
    marginTop: "5px",
  };

  return (
    <Box 
      ref={setNodeRef} 
      style={style}
    >
      {children.length === 0 ? (
        <Typography variant="body2" style={{ color: "#999", marginTop: "40px" }}>
          Drag PMs here
        </Typography>
      ) : (
        children
      )}
    </Box>
  );
});

// Optimize re-renders by preventing updates if props remain unchanged
export default React.memo(Droppable);
