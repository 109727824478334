import Highcharts from 'highcharts';
import HeatmapModule from 'highcharts/modules/heatmap';
import Highcharts3D from 'highcharts/highcharts-3d';
import HighchartsMoreModules from 'highcharts/highcharts-more';
import SolidGaugeModule from 'highcharts/modules/solid-gauge';
import ExportingModule from 'highcharts/modules/exporting';
import ExportDataModule from 'highcharts/modules/export-data';
import AccessibilityModule from 'highcharts/modules/accessibility';
import NoDataToDisplayModule from 'highcharts/modules/no-data-to-display';
import BoostModule from 'highcharts/modules/boost';

const modules = [
  HeatmapModule,
  Highcharts3D,
  HighchartsMoreModules,
  SolidGaugeModule,
  ExportingModule,
  ExportDataModule,
  AccessibilityModule,
  NoDataToDisplayModule,
  BoostModule
];

export const initializeHighchartsModules = () => {
  modules.forEach(module => {
    if (typeof module === 'function') {
      module(Highcharts);
    }
  });
};