import React, { useRef, useEffect, useState } from "react";
import { useDraggable } from "@dnd-kit/core";
import { Paper } from "@material-ui/core";

const Draggable = React.memo(({ id, disabled, children }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({ id });
  const dragRef = useRef(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const style = {
    position: isDragging ? "fixed" : "relative", // Make it fixed only while dragging
    zIndex: isDragging ? 1000 : 10,
    width: isDragging ? 420 : 'auto',
    cursor: disabled ? "not-allowed" : "grab",
    userSelect: "none",
    opacity: disabled ? 0.5 : 1,
  };

  const draggableStyle = {
    padding: "4px 8px",
    marginBottom: "10px",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "12.5px",
    fontWeight: 500,
    textAlign: "left",
    transition:"all 0.05s linear",
  };

  return (
    <Paper
      ref={(node) => {
        setNodeRef(node);
        dragRef.current = node;
      }}
      {...(!disabled ? { ...listeners, ...attributes } : {})}
      style={{
        ...style,
        ...draggableStyle,
        transform: !isDragging ? `translate3d(${transform?.x || 0}px, ${transform?.y || 0}px, 0)` : undefined,
        top: isDragging ? mousePosition.y - (dragRef.current?.offsetHeight / 2 || 0) : undefined,
        left: isDragging ? mousePosition.x - (dragRef.current?.offsetWidth / 2 || 0) : undefined,
      }}
    >
      {children}
    </Paper>
  );
});

// Optimize re-renders by preventing updates if props remain unchanged
export default React.memo(Draggable);
