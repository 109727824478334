import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
  Switch,
  Container
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { pdf } from "@react-pdf/renderer";
import * as spillActions from "../../../../../actionCreators/Spill";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import PropTypes from "prop-types";
import SpillPdf from "./SpillPdf";
import PesCoverLetter from "./PesCoverLetter";

import AttachmentGrid from "../../../../../Components/AttachmentGrid";
import RejectDialog from "./RejectionDialog";
import CompleteSubmissionDialog from "./CompleteSubmissionDialog";

import {
  orderAlphabaticallyByKey,
  getUploadTypes,
  getCompletedAttachmentUploadTypes,
  isContractorUser,
  getUploadTypesConditionally,
  specificAttachmentTypes,
  isPESUser,
  doPost,
  REQUEST_TYPE,
  uptoDecimalPlaces,
  extractFileExtensionFromString,
  getRequestedDocTypes,
  SPILL_STATUSES,
  STATUSES,
} from "../../../../../utils";
import CurrencyFieldInput from "../../../../../Components/CurrencyFieldInput";
import * as downloadActions from "../../../../../actionCreators/Download";
import { bindActionCreators } from "redux";
import {
  RequestDocumentationPrompt,
  WholeScreenModal,
} from "../../../../../Components";
import { cancelRequestedDocumentationModalText, progressiveBillingModalText, requestDocumentationModalText } from "../../../../../utils/language/english";
import ProgressiveBillingModal from './ProgressiveBillingModal';
import ProgressiveBillingTable from './ProgressiveBillingTable';
import Moment from "moment";
import CancelRequestedDocumentationPrompt from '../../../../../Components/CancelRequestedDocumentationPrompt';
import { transformSpillNotes } from "./helperSpills";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  rightAlign: {
    textAlign: "right",
  },
  leftAlign: {
    textAlign: "left",
  },
  statusLabel: {
    color: "#616161",
    fontSize: "14px",
  },
  subHeading: {
    color: "#295115",
    fontWeight: 800,
  },
  button: {
    margin: "13px",
  },
  formControl: {
    minWidth: "200px",
  },
  groupButton: {
    margin: "0 3px",
  },
  fullWidth: {
    width: "100%",
  },
  adminFormWrapper: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    backgroundColor: "#F5F5F5",
  },
  adminField: {
    padding: "0 10px",
  },
  responseTime: {
    padding: "10px",
  },
  adminActionMessage: {
    color: "red",
    fontSize: "smaller",
    display: "flex",
    flexDirection: "row-reverse",
  },
  notRequestedSwitch: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    justifyContent: "end",
  },
  submissionCheckBox: {
    display: "flex",
    alignItems: "center",
    padding: "20px 0",
    justifyContent: "flex-end",
  },
  partialBillingEventContainer: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "10px 0 10px 10px",
  },
  partialBillingEventHeading: {
    fontWeight: "initial",
    fontSize: "18px",
    letterSpacing: 0.5,
    color: "#686868"
  }
}));

const AdminForm = ({
  admin,
  index,
  setAdmin,
  userName,
  download,
  isInactive,
  isUserAdmin,
  currentUser,
  currentSpill,
  downloadFile,
  selectedFiles,
  contractorInv,
  attachmentTypes,
  isCorporateUser,
  requestDocuments,
  setContractorInv,
  attachmentHistory,
  onFileChangeHandler,
  onFileCancelHandler,
  missingInvoiceAlert,
  totalPrintableNotes,
  requestDocumentation,
  requestDocumentationReset,
  cancelRequestedDocumentation,
  cancelRequestedDocuments,
  cancelRequestedDocumentationReset,
  setSnackbarMessageCb,
  setMissingInvoiceAlert,
  updateRejectedAttachment,
  viewAdminAttachmentHistory,
  spillAdminRequestedDocumentation,
  getSpillAdminRequestedDocumentation,
  getPartialBillingDownload,
  partialBillingDownloadData, //state data with loading,success, error and data object
  adminId,
  updateStatus,
  partialBillingDownloadReset,
  errorMessage,
  setErrorMessage
}) => {
  const classes = useStyles();
  const disableFields = admin[index]?.isNotRequiredCheck || false;
  const [isNotRequiredState, setIsNotRequiredState] = useState(
    admin[index]?.isNotRequiredCheck || false
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [generatePDFReport, setGeneratePDFReport] = useState(false);
  const [uploadType, setUploadType] = useState("");
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [isCompleteDialogOpen, setIsCompleteDialogOpen] = useState(false);
  const [adminAttachmentActions, setAdminAttachmentActions] = useState({
    display: false,
    message: "",
  });
  const [contractorInvNo, setContractorInvNo] = useState(admin[index].invNo);
  const [attachmentToDisableIds, setAttachmentToDisableIds] = useState([]);
  const [
    previouslyUploadedAttachmentTypes,
    setPreviouslyUploadedAttachmentsTypes,
  ] = useState([]);
  const [showReqDocModal, setshowReqDocModal] = useState(false);
  const [showCancelReqDocModal, setShowCancelReqDocModal] = useState(false)
  const [requestedDocuments, setRequestedDocuments] = useState({
    data: [],
    mappedLabels: [],
  });
  const [progressiveBilling, setProgressiveBilling] = useState(
    admin[index]?.progressive_billing || false
  );

  const [showModal, setShowModal] = React.useState(false);
  const [progressiveBillingStartDate, setProgressiveBillingStartDate] = useState(null)//set default date to lcoal from api	
  const [progressiveBillingEndDate, setProgressiveBillingEndDate] = useState(null)
  const [progressiveBillingInDb,] = useState(admin[index]?.progressive_billing || false)
  const [finalInvoiceFlag, setFinalInvoiceFlag] = useState(false)
  const [downloadDisabled, setDownloadDisabled] = useState(false)

  useEffect(() => {
    if (selectedAttachments?.length > 0 && admin[index]?.isComplete === false) {
      setAdminAttachmentActions({
        display: true,
        message: "Contractor has not yet submitted documents for review",
      });
    } else {
      setAdminAttachmentActions({
        display: false,
        message: "",
      });
    }

    if (admin[index]) {
      let disabledAttachments = admin[index]?.attachments_with_expiry?.filter(
        (attachment) => attachment?.disabled === 1
      );
      const disabledIds = disabledAttachments?.map(
        (attachment) => attachment?.attachment_id
      );

      const attachments = admin[index].attachments?.filter((attachment) =>
        specificAttachmentTypes?.includes(attachment?.type)
      );

      const attachmentTypesUploaded = [];

      attachments?.map((attachment) =>
        attachmentTypesUploaded.push({
          type: attachment?.type,
          user: attachment?.user?.role?.role,
        })
      );

      let uniqueAttachmentsTypeAndUsersList = attachmentTypesUploaded?.filter(
        (type, index, attachmentTypes) => {
          return (
            index ===
            attachmentTypes?.findIndex(
              (item) => item?.type === type?.type && item?.user === type?.user
            )
          );
        }
      );

      setPreviouslyUploadedAttachmentsTypes(uniqueAttachmentsTypeAndUsersList);

      setAttachmentToDisableIds(disabledIds);
    }
  }, [admin, index, selectedAttachments]);

  useEffect(() => {
    if (currentSpill?.data?.spill?.id) {
      getSpillAdminRequestedDocumentation({
        spill_id: currentSpill?.data?.spill?.id,
        spill_admin_id: admin[index]?.adminId,
      });
    }
  }, []);

  useEffect(() => {
    if (spillAdminRequestedDocumentation?.data?.length) {
      const labelsToRender = getRequestedDocTypes(
        spillAdminRequestedDocumentation?.data
      );
      if (labelsToRender?.length) {
        setRequestedDocuments({
          mappedLabels: labelsToRender,
          data: spillAdminRequestedDocumentation?.data,
        });
      }
    } else {
      setRequestedDocuments({
        mappedLabels: [],
        data: [],
      });
    }
  }, [spillAdminRequestedDocumentation]);

  useEffect(() => {
    if (
      download?.success === true &&
      download?.loading === false &&
      generatePDFReport === true
    ) {
      setSnackbarMessageCb("PDF is downloading", "success");
      setGeneratePDFReport(false);
    }
    if (
      download?.success === false &&
      download?.loading === false &&
      download.error === "File Not Found"
    ) {
      setSnackbarMessageCb("Your PDF is Empty", "error");
      download.error = "";
    }
    if (download?.loading === false) {
      setGeneratePDFReport(false);
    }
  }, [download]);

  // This useEffect will trigger only when any of the requested document is cancelled successfully
  useEffect(() => {
    if (cancelRequestedDocuments?.success) {
      /* If all the documents which are requested are rejected for all te contractor admins and the status is documentation sent back to contractor then update the status back to Documentation in review and refresh the request document state */
      if (!cancelRequestedDocuments?.data?.pendingRequestedDocForSpill && currentSpill?.data?.spill?.status === STATUSES.OPEN_DOCUMENTATION_SENT_BACK_TO_CONTRACTOR_FOR_REVISION) {
        updateStatus({
          status: STATUSES?.OPEN_DOCUMENTATION_IN_REVIEW,
          spill_id: currentSpill?.data?.spill?.id,
          job_no: currentSpill?.data?.spill?.job_no,
          selected: null,
          selectAll: null,
          searchQuery: null,
          batchUpdate: null,
          system_generated_status: true
        });
      }
    }
    // Either API requets is success or fail reset the cancel requested document
    cancelRequestedDocumentationReset()
  }, [cancelRequestedDocuments?.success, cancelRequestedDocuments?.error])

  // This useEffect will trigger only document request is successfully
  useEffect(() => {
    if (requestDocuments?.success) {
      /* If the current state is not document sent back to contractor then update the state to OPEN_DOCUMENTATION_SENT_BACK_TO_CONTRACTOR_FOR_REVISION */
      if (currentSpill?.data?.spill?.status !== STATUSES.OPEN_DOCUMENTATION_SENT_BACK_TO_CONTRACTOR_FOR_REVISION) {
        updateStatus({
          status: STATUSES?.OPEN_DOCUMENTATION_SENT_BACK_TO_CONTRACTOR_FOR_REVISION,
          spill_id: currentSpill?.data?.spill?.id,
          job_no: currentSpill?.data?.spill?.job_no,
          selected: null,
          selectAll: null,
          searchQuery: null,
          batchUpdate: null,
          system_generated_status: true
        });
      }
    }
  requestDocumentationReset()

  }, [requestDocuments?.success, requestDocuments?.error])

  const handleAdminChange = (index, key) => (event) => {
    let tempAdmin = JSON.parse(JSON.stringify(admin));
    if (
      uploadType === "contractorInv" &&
      ((key === "contractorInv" && !event?.target?.value) ||
        (key === "invNo" && !event?.target?.value))
    ) {
      setMissingInvoiceAlert(true);
      setUploadType("");
    } else {
      setMissingInvoiceAlert(false);
    }

    if (key === "invNo") {
      const value = event?.target?.value.replace(/\s+/g, "");
      if (value === "" || /^\d+$/.test(value)) {
        setContractorInvNo(value);
      }
    }

    if (key === "contractorInv") {
      setContractorInv(parseFloat(event?.target?.value));
    } else {
      tempAdmin[index][key] = event?.target?.value;
    }

    if (key === "isNotRequiredCheck") {
      tempAdmin[index][key] = event?.target?.checked;

      setIsNotRequiredState(event?.target?.checked);
    }

    if (key === "progressive_billing") {
      tempAdmin[index][key] = event?.target?.checked;
      setProgressiveBilling(event?.target?.checked);
    }

    setAdmin(tempAdmin);
  };

  const handleAdminDateChange = (index, key) => (date) => {
    let tempAdmin = [...admin];
    if (date) {
      // if the date is valid then change the date or else allow to add manual date
      tempAdmin[index][key] = date?.isValid ? date : date?._i
    } else {
      tempAdmin[index][key] = null;
    }
    setAdmin(tempAdmin);
  };

  const handleAgencyReport = (index, key) => async () => {
    if (totalPrintableNotes?.data?.length) {
      setGeneratePDFReport(true);
      let updatedSpill = {
        ...currentSpill,
        data: {
          ...currentSpill?.data,
          spill: {
            ...currentSpill?.data?.spill,
            spill_notes: totalPrintableNotes?.data?.filter(
              (note) => note?.deleted_at === null
            ),
          },
        },
      };

      await generatePdfDocument(updatedSpill, index, "Agency Report");
    }

  };

  function getUtcDate(date) {
    let momentDate = moment(date)
    return momentDate?._d instanceof Date ? momentDate?.utc()?.format() : null;
  }


  const handleGenerateInvRepPkg = async (index, key, refreshPartialBillingTableData=false) => {
    // convert start date and end date to UTC
    if (totalPrintableNotes?.data?.length === 0) return false;
    setGeneratePDFReport(true);
    // if progressive billing, consider the date range and also add the notes only if the progressive billing and final invoice is checked
    let updatedSpill = {
      ...currentSpill,
      data: {
        ...currentSpill?.data,
        spill: {
          ...currentSpill?.data?.spill,
          spill_notes: (finalInvoiceFlag && progressiveBilling || !progressiveBilling) ? totalPrintableNotes?.data?.filter(
            (note) => note?.deleted_at === null
          ) : []
        }
      }
    };
    await generatePdfDocument(updatedSpill, index, "Invoice/Report Package", refreshPartialBillingTableData);

    // Assuming the download is successful
    if (download?.success == true) {
      let updatedAdmin = [...admin];
      updatedAdmin[index] = {
        ...updatedAdmin[index],
        invoice_report_download: moment().format("YYYY-MM-DD"),
      };
    }
  };

  const checkWhetherCallUpdateSpillApiOrNot = () => {

    let callUpdateStatusApi = true;
    let billingEventData = partialBillingDownloadData?.["data"]?.["adminData"]

    for (let eachAdmin of admin) {
      // check if the progressive billing is checked
      if (eachAdmin["progressive_billing"]) {
        // check the final invoice is generated or not for this admin
        const billingDataOfAdmin = billingEventData[eachAdmin["adminId"]]?.["billingData"]

        const hasFinalInvoice = billingDataOfAdmin?.some(billingEvent => {
          return billingEvent?.final_invoice !== null;
        })

        if (!hasFinalInvoice) {
          callUpdateStatusApi = false;
          break;
        }
      }
    }

    if (callUpdateStatusApi) {
      updateStatus({
        status: STATUSES?.OPEN_READY_TO_CLOSE,
        spill_id: currentSpill?.data?.spill?.id,
        job_no: currentSpill?.data?.spill?.job_no,
        selected: null,
        selectAll: null,
        searchQuery: null,
        batchUpdate: null,
        system_generated_status: true
      });
    }

  }

  const generatePdfDocument = async (updatedSpill, index, fileName, refreshPartialBillingTableData) => {
    try {

      const progressiveBillingStartDateUtc = getUtcDate(progressiveBillingStartDate);
      const progressiveBillingEndDateUtc = getUtcDate(progressiveBillingEndDate);

      if (updatedSpill) {
        const { data, totalTally } = transformSpillNotes(updatedSpill?.data?.spill?.spill_notes ?? []);

        const contractor_ids = currentSpill?.data?.admin.map((admin) => admin.contractor_id);
        const blob = await pdf(
          <SpillPdf data={updatedSpill} userMetaData={currentUser} notesSummary={data} totalTally={totalTally} />
        ).toBlob();

        let currentDate = new Date();
        const blob2 = await pdf(<PesCoverLetter data={currentDate} />).toBlob();

        let formdata = new FormData();
        formdata.append("spill_id", currentSpill?.data?.spill?.id);
        formdata.append(`printspill`, blob);
        formdata.append(`pesCoverLetter`, blob2);
        formdata.append(`spill_job_no`, currentSpill?.data?.spill?.job_no);
        formdata.append(`spill_admin_id`, currentSpill?.data?.admin[index]?.id);
        formdata.append(
          `contractor_id`,
          currentSpill?.data?.admin[index]?.contractor_id
        );
        formdata.append(`contractor_ids`, contractor_ids);
        formdata.append(`fileName`, fileName);
        progressiveBillingInDb && fileName === "Invoice/Report Package" && formdata.append(`report_start_date`, progressiveBillingStartDateUtc);
        progressiveBillingInDb && fileName === "Invoice/Report Package" && formdata.append(`report_end_date`, progressiveBillingEndDateUtc)
        progressiveBillingInDb && fileName === "Invoice/Report Package" && formdata.append("final_invoice", finalInvoiceFlag)

        const response = await doPost(
          "/download/pdfReport",
          formdata,
          null,
          REQUEST_TYPE.DOWNLOAD
        );

        setTimeout(() => {
          setGeneratePDFReport(false);
        }, 2000);

        // var element = document.createElement("a");
        // element.setAttribute("href", response?.data?.url);
        // element.style.display = "none";
        // document.body.appendChild(element);
        // element.click();
        // document.body.removeChild(element);
      }
      //Refresh the partial billing table data only if the partial invouice report is downloaded form the partial billing modal
      refreshPartialBillingTableData && await getPartialBillingDownload({ spill_id: currentSpill?.data?.spill?.id })
    } catch (error) {
      setGeneratePDFReport(false);
      console.log(error);
    }
  };

  useEffect(() => {

    /* if partial billing api result is success then check the scenario to call the spill update api and also check if the status is opened if closed then dont check and make unneccesary check and call */

    const status = currentSpill?.data?.spill?.status;
    const statusNeedToUpdate = status?.includes("Open") ? (status === STATUSES?.OPEN_READY_TO_CLOSE ? false : true) : false;

    // only check whether to call update status for the spill api only if the progressiveBilling for that admin is checked else no need to check and also if the statusNeedToUpdate for that spill
    if (partialBillingDownloadData?.success && statusNeedToUpdate && progressiveBillingInDb) {
      checkWhetherCallUpdateSpillApiOrNot()
      // reset the success state of partial billing data
    }
    partialBillingDownloadReset()

  }, [partialBillingDownloadData?.success, partialBillingDownloadData?.error])

  const handleAdminDateClear = (index, key) => {
    let tempAdmin = [...admin];
    tempAdmin[index][key] = null;
    setAdmin(tempAdmin);
  };

  const handleSetUploadType = (uploadType) => {
    if (uploadType === "contractorInv") {
      if (contractorInv && admin[index].invNo) {
        setUploadType(uploadType);
      } else {
        setUploadType("");
        setMissingInvoiceAlert(true);
      }
    } else {
      setUploadType(uploadType);
      setMissingInvoiceAlert(false);
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogReject = (data) => {
    let date = new Date();
    setIsDialogOpen(false);
    let tempAdmin = [...admin];
    setAdmin(tempAdmin);
    for (const arrIndex in selectedAttachments) {
      tempAdmin[index].attachments[selectedAttachments[arrIndex]].status =
        "reject";
      // tempAdmin[index].attachments[selectedAttachments[arrIndex]].updated_at =
      //   moment(new Date(date))?.format("MM-DD-YYYY HH:mm:ss");
      tempAdmin[index].attachments[selectedAttachments[arrIndex]].admin_note =
        data[arrIndex];
      tempAdmin[index].attachments[selectedAttachments[arrIndex]].first_time_rejection = true
    }
    setSelectedAttachments([]);
    setAdmin(tempAdmin);
  };

  const handleAttachmentEdit = (key, data, fileIndex) => {
    let tempAdmin = [...admin];
    tempAdmin[index].attachments[fileIndex][key] = data;
    setAdmin(tempAdmin);
  };

  const handleApprove = () => {
    let date = new Date();
    for (const fileIndex of selectedAttachments) {
      let tempAdmin = [...admin];
      tempAdmin[index].attachments[fileIndex].status = "approve";
      // tempAdmin[index].attachments[fileIndex].updated_at = moment(new Date(date))?.format("MM-DD-YYYY HH:mm:ss");
      if (tempAdmin[index]?.attachments[fileIndex]?.type === "contractorInv") {
        const extension = extractFileExtensionFromString(
          tempAdmin[index]?.attachments[fileIndex]?.key
        );
        tempAdmin[index].attachments[
          fileIndex
        ].name = `Final Contractor Invoice.${extension}`;
      }
      setSelectedAttachments([]);
      setAdmin(tempAdmin);
    }
  };

  const handleDocumentRequest = () => {
    setshowReqDocModal(true);
  };

  const handleReqDocSubmit = async (response) => {
    if (response?.length > 0) {
      const adminObj = {
        spill_id: currentSpill?.data?.spill?.id,
        contractor_id: admin[index]?.contractorId,
        contractor_address_id: admin[index]?.contractorAddressId,
        spill_admin_id: admin[index]?.adminId,
        spill_job_no: currentSpill?.data?.spill?.job_no,
        spill_pm_user_id: currentSpill?.data?.spill?.user_id,
      };

      let currentDate = new Date();
      currentDate = Moment(currentDate)?.format("YYYY-MM-DDTHH:mm:ss.SSSSZ");

      for (let i = 0; i < response?.length; i++) {
        response[i].created_at = currentDate;
        response[i].updated_at = currentDate;
      }

      await requestDocumentation({
        requestedDocumentsData: response,
        adminData: adminObj,
      });
    }
  };

  const handleCancelReqDocSubmit = async(cancelRequestedDocuments) => {
    if(cancelRequestedDocuments?.length) {
      // CALL THE API TO CANCEL THE REQUESTED DOCUMENTS
      await cancelRequestedDocumentation({
        canceled_requested_docs: cancelRequestedDocuments,
        spill_id: currentSpill?.data?.spill?.id,
        contractor_id: admin[index]?.contractorId,
        spill_job_no: currentSpill?.data?.spill?.job_no,
        spill_admin_id: admin[index]?.adminId,
        spill_pm_user_id: currentSpill?.data?.spill?.user_id,
      })
    }
  }

  const handleCompleteCheck = (index, value) => {
    if (value) {
      setIsCompleteDialogOpen(true);
    } else {
      let tempAdmin = [...admin];
      tempAdmin[index].isComplete = false;
      setAdmin(tempAdmin);
      setIsCompleteDialogOpen(false);
    }
  };

  const handleConfirmComplete = () => {
    let tempAdmin = [...admin];
    tempAdmin[index].isComplete = true;
    setIsCompleteDialogOpen(false);
    setAdmin(tempAdmin);
  };

  const handleRejection = () => {
    // look for any attachment that is already rejected
    let alreadyRejectedFiles = [];
    selectedAttachments.forEach((attachmentIndex) => {
      try {
        const fileToCheck =
          admin[index]?.attachments[selectedAttachments[attachmentIndex]];
        if (fileToCheck?.status === "reject") {
          alreadyRejectedFiles = [...alreadyRejectedFiles, fileToCheck];
        }
      } catch (err) {
        setSnackbarMessageCb("Something went wrong", "error");
      }
    });
    if (alreadyRejectedFiles.length > 0) {
      const fileNames = alreadyRejectedFiles.map(({ name }) => name);
      const filesCount = fileNames.length;
      const snackbarMessage = `${fileNames.join(",")} ${filesCount > 1 ? "are" : "is"
        } already rejected. Please uncheck ${filesCount > 1 ? "them" : "it"
        } to proceed`;
      setSnackbarMessageCb(snackbarMessage, "warning");
    } else {
      setIsDialogOpen(true);
    }
  };

  const isValidDecimal = (value, keyCode) => {
    if (keyCode === 8 && value[value.length - 1] === "." && value.length <= 2)
      return true;
    if (keyCode >= 48 && keyCode <= 57) value += String.fromCharCode(keyCode);
    else if (keyCode === 190) value += ".0"; //for skip immediate check of .
    else if (keyCode === 8 && value[value.length - 1] === ".")
      value = value.substring(0, value.length - 2); //for skip ,backspace with .

    let isValid = !/^\s*$/.test(value) && !isNaN(value);
    const initialArr = value.split(".");
    const firstHalf = initialArr[0];
    let secondHalf = initialArr[1];
    if (firstHalf === "") isValid = false;
    if (secondHalf === "") isValid = false;

    if (!isValid) return isValid;

    if (secondHalf !== undefined) {
      secondHalf = secondHalf.substring(0, 5); //for roundoff upto 5
      if (firstHalf + "." + secondHalf === value) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const handleBlur = (e) => {
    //e.target.name , e.target.value
    let prevVal = e.target.value;
    if (prevVal[prevVal.length - 1] === ".") prevVal = prevVal.slice(0, -1);
    e.target.value = prevVal;
    let decimalFix = handleAdminChange(index, e.target.name);
    decimalFix(e);
  };

  const restrictKey = (e) => {
    const code = e.keyCode;
    const flag = isValidDecimal(e.target.value, code);
    if (!flag) {
      e.preventDefault();
      return;
    }
    if ((code < 48 || code > 57) && code !== 190 && code !== 8)
      e.preventDefault();
  };

  const handleProgressiveModal = () => {
    setShowModal(true)
  }


  // handle the download previouos billing event click
  const handleAttachmentDownload = async (partialBillingRecord) => {
    try {

      setDownloadDisabled(true)

      const response = await doPost(
        "/download/getUrl",
        { urlList: [partialBillingRecord?.key], job_no: currentSpill?.data?.spill?.job_no },
        null,
        REQUEST_TYPE.DOWNLOAD
      );

      const fileUrlData = response?.data?.data[0]?.url;
      var element = document.createElement("a");
      element.setAttribute("href", fileUrlData);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      // enable the downlaodpartial bulling record data after 5 second of delay
      setTimeout(() => {
        setDownloadDisabled(false);
      }, 5000);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      <div className={classes.adminFormWrapper}>
        {generatePDFReport && <PDFPopUp />}
        <Grid container spacing={3}>
          {disableFields && (
            <Grid item xs={12}>
              <label style={{ color: "red", textAlign: "center" }}>
                No Invoice or Documents will be Submitted for this Response
              </label>
            </Grid>
          )}
          {requestedDocuments?.data?.length ? (
            <Grid item xs={12}>
              <label style={{ color: "red", textAlign: "center" }}>
                Following documents were requested from you:{" "}
                <b>
                  {requestedDocuments?.mappedLabels
                    ?.map((item) => item?.label)
                    .join(", ")}
                </b>
              </label>
            </Grid>
          ) : null}
          {isUserAdmin && (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 10,
                padding: "0",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={progressiveBilling}
                    onChange={handleAdminChange(index, "progressive_billing")}
                    name="progressive_billing"
                    color="primary"
                  />
                }
                label="Progressive Billing"
                style={{ fontSize: "14px", color: "#686868" }}
              />
              <div>
                <Button
                  style={{ textAlign: "center", marginRight: "10px", marginBottom: "10px" }}
                  variant="contained"
                  color="primary"
                  disabled={currentSpill?.data?.admin[index] === undefined}
                  onClick={handleAgencyReport(index, "agencyReport")}
                >
                  Generate Agency Report
                </Button>
                <Button
                  style={{ textAlign: "center", marginBottom: "10px" }}
                  variant="contained"
                  color="primary"
                  disabled={currentSpill?.data?.admin[index] === undefined}
                  onClick={() => {
                    if (progressiveBillingInDb) {
                      handleProgressiveModal()
                    } else {
                      handleGenerateInvRepPkg(index, "InvoiceReportPkg")
                    }
                  }
                  }
                >
                  Generate Invoice/Report Package
                </Button>
              </div>
            </Grid>
          )}
          {!isContractorUser(currentUser?.data?.role?.role) ? (
            <Grid item xs={12}>
              <TextField
                disabled={disableFields}
                id="info"
                name="info"
                label="Info"
                fullWidth
                autoComplete="info"
                multiline
                inputProps={{
                  value: admin[index].info,
                }}
                onChange={handleAdminChange(index, "info")}
              />
            </Grid>
          ) : null}

          <Grid item xs={6}>
            <CurrencyFieldInput
              decimalScale={5}
              name="contractorInv"
              value={uptoDecimalPlaces(contractorInv, 2)}
              allowNegative={false}
              label="Contractor Invoice $"
              onChange={handleAdminChange(index, "contractorInv")}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled={disableFields}
              id="invNo"
              name="invNo"
              label="Inv No"
              fullWidth
              autoComplete="invNo"
              inputProps={{
                value: contractorInvNo,
              }}
              onChange={handleAdminChange(index, "invNo")}
            />
          </Grid>
          <Grid item xs={12}>
            {isUserAdmin && (
              <Paper elevation={3} className={classes.paper}>
                <Grid container>
                  {currentUser?.data?.role.permission.edit_spill_admin &&
                    isUserAdmin && (
                      <>
                        <Grid item xs={6} className={classes.adminField}>
                          <TextField
                            onBlur={handleBlur}
                            onKeyDown={restrictKey}
                            id="finalContractorInv"
                            name="finalContractorInv"
                            label="Final Contractor Invoice"
                            fullWidth
                            autoComplete="finalContractorInv"
                            inputProps={{
                              value: admin[index].finalContractorInv,
                            }}
                            onChange={handleAdminChange(
                              index,
                              "finalContractorInv"
                            )}
                          />
                        </Grid>

                        <Grid item xs={6} className={classes.adminField}>
                          <TextField
                            id="savings"
                            name="savings"
                            label="Savings $"
                            fullWidth
                            autoComplete="savings"
                            inputProps={{
                              value: admin[index]?.savings && Math?.abs(admin[index]?.savings),
                              readOnly: true,
                              disabled: true,
                            }}
                            onChange={handleAdminChange(index, "savings")}
                          />
                        </Grid>

                        <Grid item xs={6} className={classes.adminField}>
                          <TextField
                            onBlur={handleBlur}
                            onKeyDown={restrictKey}
                            id="pesInvNo"
                            name="pesInvNo"
                            label="PES Inv No"
                            fullWidth
                            autoComplete="pesInvNo"
                            inputProps={{
                              value: admin[index].pesInvNo,
                            }}
                            onChange={handleAdminChange(index, "pesInvNo")}
                          />
                        </Grid>

                        <Grid item xs={6} className={classes.adminField}>
                          <TextField
                            onBlur={handleBlur}
                            onKeyDown={restrictKey}
                            id="pesInvAmount"
                            name="pesInvAmount"
                            label="PES Inv Amount"
                            fullWidth
                            autoComplete="pesInvAmount"
                            inputProps={{
                              value: admin[index].pesInvAmount,
                            }}
                            onChange={handleAdminChange(index, "pesInvAmount")}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <div>
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/DD/YYYY"
                                margin="normal"
                                id="pesPaid_picker_inline"
                                label="Pes Paid"
                                value={admin[index]?.pesPaid}
                                onChange={handleAdminDateChange(
                                  index,
                                  "pesPaid"
                                )}
                                KeyboardButtonProps={{
                                  aria_label: "change date",
                                }}
                                autoOk={true}
                              />
                            </div>
                            <Button
                              style={{ textAlign: "center" }}
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleAdminDateClear(index, "pesPaid")
                              }
                            >
                              Clear
                            </Button>
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={6}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <div>
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/DD/YYYY"
                                margin="normal"
                                id="contractorPaid_picker_inline"
                                label="Contractor Paid"
                                value={admin[index]?.contractorPaid}
                                onChange={handleAdminDateChange(
                                  index,
                                  "contractorPaid"
                                )}
                                KeyboardButtonProps={{
                                  aria_label: "change date",
                                }}
                                autoOk={true}
                              />
                            </div>
                            <Button
                              style={{ textAlign: "center" }}
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleAdminDateClear(index, "contractorPaid")
                              }
                            >
                              Clear
                            </Button>
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={6}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <div>
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/DD/YYYY"
                                margin="normal"
                                id="transToCt_picker_inline"
                                label="Trans To CT"
                                value={admin[index]?.transToCt}
                                onChange={handleAdminDateChange(
                                  index,
                                  "transToCt"
                                )}
                                KeyboardButtonProps={{
                                  aria_label: "change date",
                                }}
                                autoOk={true}
                              />
                            </div>
                            <Button
                              style={{ textAlign: "center" }}
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleAdminDateClear(index, "transToCt")
                              }
                            >
                              Clear
                            </Button>
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={6}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <div>
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/DD/YYYY"
                                margin="normal"
                                id="payBy_picker_inline"
                                label="Pay By"
                                value={admin[index]?.payBy}
                                onChange={handleAdminDateChange(index, "payBy")}
                                KeyboardButtonProps={{
                                  aria_label: "change date",
                                }}
                                autoOk={true}
                              />
                            </div>
                            <Button
                              style={{ textAlign: "center" }}
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleAdminDateClear(index, "payBy")
                              }
                            >
                              Clear
                            </Button>
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={6} className={classes.responseTime}>
                          <TextField
                            disabled={disableFields}
                            id="responseTime"
                            name="responseTime"
                            label="Response Time (hh:mm)"
                            fullWidth
                            autoComplete="responseTime"
                            inputProps={{
                              value: admin[index].responseTime,
                            }}
                            onChange={handleAdminChange(index, "responseTime")}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={6}
                          className={classes.notRequestedSwitch}
                        >
                          <Typography>No Invoice/Documents Required</Typography>
                          <Switch
                            color="primary"
                            checked={isNotRequiredState}
                            onChange={handleAdminChange(
                              index,
                              "isNotRequiredCheck"
                            )}
                          />
                        </Grid>
                      </>
                    )}
                </Grid>
              </Paper>
            )}
          </Grid>

          <Grid item xs={12}>
            <div className={classes.leftAlign}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  I Want to Upload
                </InputLabel>
                <Select
                  disabled={disableFields}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={uploadType}
                  onChange={(event) => handleSetUploadType(event.target.value)}
                >
                  <MenuItem value="">
                    <em>Select Upload Type</em>
                  </MenuItem>
                  {orderAlphabaticallyByKey(
                    admin[index]?.isComplete &&
                      isContractorUser(currentUser?.data?.role?.role)
                      ? requestedDocuments?.mappedLabels?.length
                        ? [
                          ...attachmentTypes?.completed,
                          ...requestedDocuments?.mappedLabels,
                        ]
                        : attachmentTypes?.completed
                      : getUploadTypesConditionally(
                        currentUser?.data?.role?.role,
                        attachmentTypes
                      ),
                    "label"
                  ).map((type, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={type.value}
                        disabled={
                          attachmentToDisableIds?.includes(type?.id) ||
                          previouslyUploadedAttachmentTypes?.some(
                            (attachment) => {
                              return (
                                attachment?.type === type?.value &&
                                currentUser?.data?.role?.role !==
                                attachment?.user
                              );
                            }
                          )
                        }
                      >
                        {type.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <Button
                variant="contained"
                component="label"
                color="primary"
                className={classes.button}
                disabled={disableFields || !uploadType}
              >
                + Select Files
                <input
                  type="file"
                  accept=".jpg, .png, .jpeg, .TIF, .pdf, .doc, .docx, .xls, .xlsx, .txt"
                  onChange={(event) => {
                    const requestedDocumentsMappedLables =
                      requestedDocuments?.mappedLabels;
                    const requestedDocumentsData = requestedDocuments?.data;
                    onFileChangeHandler(
                      event,
                      index,
                      uploadType,
                      index,
                      requestedDocumentsData,
                      requestedDocumentsMappedLables
                    );
                  }}
                  hidden
                  multiple
                  name="adminFiles"
                  id="adminFiles"
                />
              </Button>
            </div>
            <div className={classes.rightAlign}>
              {errorMessage && (
                <Alert severity="error" className="error_snackbar" style={{ minWidth: "100%", fontWeight: 600 }}>
                  {errorMessage}
                </Alert>
              )}
            </div>
          </Grid>

          {missingInvoiceAlert && (
            <Grid item xs={12}>
              <span style={{ color: "red" }}>
                You need to add contractor invoice and amount in order to upload
                a Contractor Invoice
              </span>
            </Grid>
          )}

          {isUserAdmin && (
            <div className={classes.fullWidth}>
              <div className={classes.rightAlign}>
                {requestedDocuments?.data?.length ? (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setShowCancelReqDocModal(true)}
                    className={classes.groupButton}
                  >
                    Cancel Requested Document
                  </Button>
                ) : null}
                {currentSpill?.data?.spill?.status ===
                  "Open: Documentation In Review" ||
                  currentSpill?.data?.spill?.status ===
                  "Open: Documentation Sent Back to Contractor for Revision" ? (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleDocumentRequest()}
                    className={classes.groupButton}
                  >
                    Request Documentation
                  </Button>
                ) : null}
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleApprove()}
                  className={classes.groupButton}
                  disabled={
                    selectedAttachments?.length < 1 ||
                    admin[index].isComplete === false ||
                    disableFields
                  }
                >
                  Approve
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleRejection}
                  className={classes.groupButton}
                  disabled={
                    selectedAttachments?.length < 1 ||
                    admin[index].isComplete === false ||
                    disableFields
                  }
                >
                  Reject
                </Button>
              </div>
              <div>
                {adminAttachmentActions?.display && (
                  <p className={classes.adminActionMessage}>
                    {adminAttachmentActions?.message}
                  </p>
                )}
              </div>
            </div>
          )}

          <Grid item xs={12}>
            <AttachmentGrid
              uploadType={uploadType}
              isNotRequired={disableFields}
              contractorInv={contractorInv}
              files={admin[index].attachments}
              adminIndex={index}
              preview={selectedFiles[index]}
              selectedFiles={selectedFiles}
              attachmentHistory={attachmentHistory}
              handleCancel={(fileIndex) =>
                onFileCancelHandler(index, fileIndex)
              }
              handleEdit={(key, data, fileIndex) => {
                handleAttachmentEdit(key, data, fileIndex);
              }}
              isEditable={true}
              isInactive={isInactive}
              isUserAdmin={isUserAdmin}
              contractorInvNo={contractorInvNo}
              isCorporateUser={isCorporateUser}
              userName={userName}
              selectedAttachments={selectedAttachments}
              updateRejectedAttachment={updateRejectedAttachment}
              setSelectedAttachments={setSelectedAttachments}
              isComplete={admin[index].isComplete}
              viewAdminAttachmentHistory={viewAdminAttachmentHistory}
            />
          </Grid>

          {!isUserAdmin && (
            <Grid item xs={12} className={classes.rightAlign}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      typeof admin[index].isComplete === "undefined"
                        ? false
                        : admin[index].isComplete
                    }
                    disabled={
                      admin[index].isComplete ||
                      (!admin[index].attachments?.filter((o) => !o.isDelete)
                        ?.length &&
                        !selectedFiles[index]?.length) ||
                      disableFields
                    }
                    onChange={(event) => {
                      handleCompleteCheck(index, event.target.checked);
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Mark submission as complete"
              />
            </Grid>
          )}
        </Grid>
        {isDialogOpen && (
          <RejectDialog
            isOpen={isDialogOpen}
            handleDone={handleDialogReject}
            handleCancel={handleDialogClose}
            selectedAttachments={selectedAttachments}
            attachmentInfo={admin[index].attachments}
          ></RejectDialog>
        )}
        {isCompleteDialogOpen && (
          <CompleteSubmissionDialog
            isOpen={isCompleteDialogOpen}
            handleDone={handleConfirmComplete}
            handleCancel={() => setIsCompleteDialogOpen(false)}
          ></CompleteSubmissionDialog>
        )}
        {isPESUser(currentUser?.data?.role?.role) && (
          <div className={classes.submissionCheckBox}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    typeof admin[index].isComplete === "undefined"
                      ? false
                      : admin[index].isComplete
                  }
                  disabled={admin[index].attachments?.every(
                    (o) => o.status === "approve"
                  )}
                  onChange={(event) => {
                    handleCompleteCheck(index, event.target.checked);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Mark submission as complete"
            />
          </div>
        )}
        {/* show progressive billing table only if the partial billing is checked */}
        {/* show progressive billing table only if the partial billing is checked */}
        {progressiveBillingInDb && partialBillingDownloadData?.data?.["adminData"]?.[adminId]?.["billingData"]?.length > 0 && (
          <Grid item xs={12}>
              <Container className={classes.partialBillingEventContainer}>
                <Typography className={classes.partialBillingEventHeading}>Partial Billing Events</Typography>
              </Container>
              <ProgressiveBillingTable
                partialBillingDownloadData={partialBillingDownloadData?.data?.["adminData"]?.[adminId]?.["billingData"]}
                handleAttachmentDownload={handleAttachmentDownload}
                downloadDisabled={downloadDisabled}
              />
          </Grid>
        )}
      </div>


      <WholeScreenModal
        show={showCancelReqDocModal}
        hide={() => setShowCancelReqDocModal(false)}
      >
        <CancelRequestedDocumentationPrompt
          attachmentTypes={attachmentTypes}
          response={(modalDisplay, cancelRequestedDocuments) => {
            setShowCancelReqDocModal(modalDisplay);
            handleCancelReqDocSubmit(cancelRequestedDocuments);
          }}
          headingText={cancelRequestedDocumentationModalText.headingText}
          question={cancelRequestedDocumentationModalText.question}
          acceptText={cancelRequestedDocumentationModalText.acceptText}
          cancelText={cancelRequestedDocumentationModalText.cancelText}
          rejectText={cancelRequestedDocumentationModalText.rejectText}
          generateRequestText={
            cancelRequestedDocumentationModalText.generateRequestText
          }
          noDocumentsSelectedText={
            cancelRequestedDocumentationModalText.noDocumentsSelectedText
          }
          docsReadyForRequestText={
            cancelRequestedDocumentationModalText.selectedDocumentsText
          }
          modalDefaultText={cancelRequestedDocumentationModalText.modalDefaultText}
          requestedDocuments={requestedDocuments}
          spillAdminId={adminId}
          spillId={currentSpill?.data?.spill?.id}
        />
      </WholeScreenModal>

      <WholeScreenModal
        show={showReqDocModal}
        hide={() => setshowReqDocModal(false)}
      >
        <RequestDocumentationPrompt
          attachmentTypes={attachmentTypes}
          response={(modalDisplay, requestedDocument) => {
            setshowReqDocModal(modalDisplay);
            handleReqDocSubmit(requestedDocument);
          }}
          headingText={requestDocumentationModalText.headingText}
          question={requestDocumentationModalText.question}
          acceptText={requestDocumentationModalText.acceptText}
          cancelText={requestDocumentationModalText.cancelText}
          rejectText={requestDocumentationModalText.rejectText}
          generateRequestText={
            requestDocumentationModalText.generateRequestText
          }
          noDocumentsSelectedText={
            requestDocumentationModalText.noDocumentsSelectedText
          }
          docsReadyForRequestText={
            requestDocumentationModalText.selectedDocumentsText
          }
          modalDefaultText={requestDocumentationModalText.modalDefaultText}
        />
      </WholeScreenModal>
      <WholeScreenModal
        show={showModal}
        hide={() => setShowModal(false)}
      >
        <ProgressiveBillingModal
          headingText={progressiveBillingModalText.headingText}
          startDateText={progressiveBillingModalText.startDateText}
          endDateText={progressiveBillingModalText.endDateText}
          submitText={progressiveBillingModalText.submitText}
          setProgressiveBillingStartDate={setProgressiveBillingStartDate}
          setProgressiveBillingEndDate={setProgressiveBillingEndDate}
          progressiveBillingStartDate={progressiveBillingStartDate}
          progressiveBillingEndDate={progressiveBillingEndDate}
          setShowModal={setShowModal}
          handleGenerateInvRepPkg={handleGenerateInvRepPkg}
          partialBillingDownloadData={partialBillingDownloadData?.data?.["adminData"]?.[adminId]}
          index={index}
          defaultReportStartTime={partialBillingDownloadData?.data?.["defaultReportStartTime"]}
          finalInvoiceFlag={finalInvoiceFlag}
          setFinalInvoiceFlag={setFinalInvoiceFlag}
          handleAttachmentDownload={handleAttachmentDownload}
          downloadDisabled={downloadDisabled}
        />
      </WholeScreenModal>
    </>
  );
};

const PDFPopUp = () => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          background: "#00000070",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          gap: 10,
          flexDirection: "column",
          zIndex: 1000,
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
        }}
      >
        <div
          style={{
            margin: "35%",
            padding: "20px",
            width: "500px",
            minHeight: "230px",
            gridTemplateRows: "20px 50px 1fr 50px",
            borderRadius: "10px",
            background: "#FFFFFF",
            justifyContent: "center",
            alignContent: "center",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.25)",
          }}
        >
          <div>
            <CircularProgress />
          </div>
          <h3>Your report generation started...</h3>
          <p>You will receive an email with download link once it is ready.</p>
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  downloadFile: bindActionCreators(downloadActions.downloadFile, dispatch),
  getSpillAdminRequestedDocumentation: bindActionCreators(
    spillActions.getSpillAdminRequestedDocumentation,
    dispatch
  ),
  requestDocumentation: bindActionCreators(
    spillActions.requestDocumentation,
    dispatch
  ),
  requestDocumentationReset: bindActionCreators(spillActions.requestDocumentationReset, dispatch),
  cancelRequestedDocumentation: bindActionCreators(spillActions.cancelRequestedDocumentation, dispatch),
  cancelRequestedDocumentationReset: bindActionCreators(spillActions.cancelRequestedDocumentationReset, dispatch),
  getPartialBillingDownload: bindActionCreators(spillActions.partialBillingDownload, dispatch),
  updateStatus: bindActionCreators(spillActions.updateStatus, dispatch),
  partialBillingDownloadReset: bindActionCreators(spillActions.partialBillingDownloadReset, dispatch)
});

const mapStateToProps = ({ user, spill, download }) => ({
  download: download,
  currentUser: user.currentUser,
  currentSpill: spill.currentSpill,
  requestDocuments: spill.requestDocuments,
  totalPrintableNotes: spill.paginatedNotes,
  spillAdminRequestedDocumentation: spill.spillAdminRequestedDocumentation,
  cancelRequestedDocuments: spill.cancelRequestedDocuments
});

AdminForm.propTypes = {
  downloadFile: PropTypes.func,
  admin: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  setAdmin: PropTypes.func.isRequired,
  requestDocumentation: PropTypes.func,
  cancelRequestedDocumentation:PropTypes.func,
  userName: PropTypes.string.isRequired,
  isUserAdmin: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentSpill: PropTypes.object.isRequired,
  requestDocuments: PropTypes.object.isRequired,
  requestDocumentationReset: PropTypes.func.isRequired,
  cancelRequestedDocuments:PropTypes.object.isRequired,
  cancelRequestedDocumentationReset: PropTypes.func.isRequired,
  handleCompleteClick: PropTypes.func.isRequired,
  totalPrintableNotes: PropTypes.object.isRequired,
  spillAdminRequestedDocumentation: PropTypes.object.isRequired,
  getPartialBillingDownload: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  partialBillingDownloadReset: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminForm);