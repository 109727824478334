import { all } from "redux-saga/effects";

import userWatcher from "./User";
import agencyWatcher from "./Agency";
import clientWatcher from "./Client";
import contractorWatcher from "./Contractor";
import spillWatcher from "./Spill";
import serviceWatcher from "./Service";
import searchWatcher from "./Search";
import downloadWatcher from "./Download";
import actionWatcher from "./Action";
import analyticsWatcher from "./Analytics";
import staffingWatcher from "./Staffing";

export default function* rootWatchers() {
  yield all([
    userWatcher(),
    agencyWatcher(),
    contractorWatcher(),
    clientWatcher(),
    spillWatcher(),
    serviceWatcher(),
    searchWatcher(),
    downloadWatcher(),
    actionWatcher(),
    analyticsWatcher(),
    staffingWatcher(),
  ]);
}
