import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Typography,
  Container,
  InputLabel,
  Button,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import InvoiceSubmittedReport from './InvoiceSubmittedReport';
import SearchSpill from './SearchSpills';
import { getCurrentUser } from '../../../../../actionCreators/User';
import { ROLES } from '../../../../../utils/keyMappers';
import InvoiceReportDownload from './InvoiceReportDownload';
import RevenueAccrualReport from './RevenueAccrualReport';
import ContractorRejectionReport from './ContractorRejectionReport';
import { isPESUser_4, isSUPERUSER } from '../../../../../utils';
import ContractorRevisionReport from './ContractorRevisionReport';

const useStyles = makeStyles((theme) => ({
  root: {
    '&$checked': {
      color: '#2F7D32',
    },
  },
  checked: {},
  avatar: {
    backgroundColor: green[500],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  hide: {
    visibility: 'none',
  },
  clearSearchButton: {
    textAlign: 'right',
  },
  checkboxColor: {
    color: '#2F7D32',
  },
  radioGroupDisplay: {
    display: 'inline',
  },
  leftAlign: {
    textAlign: 'left',
  },
  rightAlign: {
    textAlign: 'right',
  },
  containerPad: {
    paddingTop: 10,
  },
  customBtn: {
    display: 'flex',
    gap: '7px',
    padding: '12px'
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    gridColumnGap: '40px',
    gridRowGap: '20px'
  }  
}));

const Reports = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [reportType, setReportType] = useState(null);
  const { currentUser } = props.user;
  const switchReportsHandler = (type) => {
    if (type) {
      history.push({
        pathname: `/dashboard/reports/${type}`,
      });
    } else {
      history.push({
        pathname: '/dashboard/reports',
      });
    }

    setReportType(type);
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    const reportType = pathname.split('/')?.pop();

    if(reportType == 'custom' || reportType == 'subrogation' || reportType == 'pendingdisposal' || reportType == 'invoice-download' || reportType == 'shipper' || reportType == 'contractor-rejection' || reportType == 'revenue-accrual' || reportType == 'contractor-revision') {
      setReportType(reportType)
    }
  }, []);


  return (
    <>
      <div className={classes.containerPad} />
      {reportType === 'custom' && (
        <InvoiceSubmittedReport
          reportsAllow={true}
          goToSpills={() => { }}
          reportType={reportType}
          batchUpdateAllow={false}
          switchReportsHandler={switchReportsHandler}
        />
      )}
      {reportType === 'subrogation' && (
        <SearchSpill
          reportsAllow={false}
          goToSpills={() => { }}
          defaultSearch={false}
          reportType={reportType}
          subrogationAllow={true}
          batchUpdateAllow={false}
          pendingDisposalAllow={false}
          switchReportsHandler={switchReportsHandler}
        />
      )}

      {reportType === 'pendingdisposal' && 
        (
          <SearchSpill
            reportsAllow={false}
            goToSpills={() => { }}
            defaultSearch={false}
            pendingDisposalAllow={true}
            subrogationAllow={false}
            reportType={reportType}
            switchReportsHandler={switchReportsHandler}
          />
        )}

      {reportType === 'invoice-download' && (
        <InvoiceReportDownload
          reportsAllow={true}
          goToSpills={() => { }}
          batchUpdateAllow={false}
          switchReportsHandler={switchReportsHandler}
        />
      )}

      {reportType === 'contractor-rejection' && (
        <ContractorRejectionReport
          reportsAllow={true}
          goToSpills={() => { }}
          batchUpdateAllow={false}
          switchReportsHandler={switchReportsHandler}
        />
      )}

      {reportType === 'shipper' && (
        <SearchSpill
          reportsAllow={false}
          goToSpills={() => { }}
          defaultSearch={false}
          reportType={reportType}
          subrogationAllow={false}
          batchUpdateAllow={false}
          pendingDisposalAllow={false}
          isShipperReport={true}
          switchReportsHandler={switchReportsHandler}
        />
      )}

      {reportType === 'revenue-accrual' && (
        <RevenueAccrualReport
          reportsAllow={true}
          goToSpills={() => { }}
          batchUpdateAllow={false}
          revenueAccrualAllow={true}
          switchReportsHandler={switchReportsHandler}
        />
      )}
      {reportType === 'contractor-revision' && (
        <ContractorRevisionReport
          reportsAllow={true}
          goToSpills={() => { }}
          batchUpdateAllow={false}
          revenueAccrualAllow={true}
          switchReportsHandler={switchReportsHandler}
        />
      )}

      {!reportType ? (
        <Container maxWidth='lg'>
          <Grid container spacing={3}>
            <Grid item xs={8} className={classes.leftAlign}>
              <Typography variant='h5' gutterBottom>
                Reports
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.leftAlign}>
              <InputLabel
                id='location_type_label'
                className={classes.locationTypeLabel}
              >
                Following are the types of Reports you can generate.
              </InputLabel>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{marginTop:"40px"}}>
            <Grid className={classes.grid} xs={12}>
              {/* <div item className={classes.customBtn}> */}
                <Button
                  variant='contained'
                  color='primary'
                  type='reset'
                  onClick={() => switchReportsHandler('custom')}
                >
                  Invoice Submitted to Client
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  type='reset'
                  onClick={() => switchReportsHandler('subrogation')}
                >
                  Subrogation Report
                </Button>

                {isSUPERUSER(currentUser?.data?.role?.role) && (
                  <Button
                    variant='contained'
                    color='primary'
                    type='reset'
                    onClick={() => switchReportsHandler('pendingdisposal')}
                  >
                    Pending Disposal
                  </Button>
                )}

              {isPESUser_4(currentUser?.data?.role?.role) ? (
                <>
                  <Button
                    variant='contained'
                    color='primary'
                    type='reset'
                    onClick={() => switchReportsHandler('invoice-download')}
                  >
                    Invoice Download Report
                  </Button>

                  <Button
                    variant='contained'
                    color='primary'
                    type='reset'
                    onClick={() => switchReportsHandler('contractor-rejection')}
                  >
                    Contractor Rejections Report
                  </Button>

                  <Button
                    variant='contained'
                    color='primary'
                    type='reset'
                    onClick={() => switchReportsHandler('shipper')}
                  >
                    Shipper Report
                  </Button>
                </>
              ) : null}
              {isSUPERUSER(currentUser?.data?.role?.role) && (
                  <Button
                    variant='contained'
                    color='primary'
                    type='reset'
                    onClick={() => switchReportsHandler('revenue-accrual')}
                  >
                    Revenue Accrual Report
                  </Button>
              )}
              {isSUPERUSER(currentUser?.data?.role?.role) && (
                  <Button
                    variant='contained'
                    color='primary'
                    type='reset'
                    onClick={() => switchReportsHandler('contractor-revision')}
                  >
                    Contractor revision
                  </Button>
              )}
            </Grid>
          </Grid>
        </Container>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: bindActionCreators(getCurrentUser, dispatch),
});
Reports.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withRouter(connect( mapStateToProps,mapDispatchToProps)(Reports));

