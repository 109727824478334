// routeAccessConfig.js
import { isPESUser_4, isSUPERUSER } from '../utils';

export const routeAccessConfig = {
  "/dashboard/reports/invoice-download": isPESUser_4,
  "/dashboard/reports/contractor-rejection": isPESUser_4,
  "/dashboard/reports/shipper": isPESUser_4,
  "/dashboard/reports/revenue-accrual": isSUPERUSER,
  "/dashboard/reports/pendingdisposal": isSUPERUSER,
  "/dashboard/staffing/search": isSUPERUSER,
  "/dashboard/staffing": isSUPERUSER,
  "/dashboard/staffing/efficiency-report": isSUPERUSER,
  "/dashboard/staffing/packet-reviewer-report": isSUPERUSER,
  "/dashboard/staffing/notes-activity-report": isSUPERUSER,
  "/dashboard/roster": isSUPERUSER,
  "/dashboard/reports/contractor-revision": isSUPERUSER,
};

export default routeAccessConfig;
