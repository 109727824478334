/** @format */

export const signUp = (obj, history) => ({
  type: 'SIGNUP',
  obj,
  history,
});

export const signUpSuccess = (data) => ({
  type: 'SIGNUP_SUCCESS',
  data,
});

export const signUpFail = (error) => ({
  type: 'SIGNUP_FAIL',
  error,
});

export const logIn = (
  obj,
  history,
  redirectUrl,
  becomeUser,
  userToBecomeId
) => ({
  type: 'LOGIN',
  obj,
  history,
  redirectUrl,
  ...(becomeUser && {
    becomeUser,
    userToBecomeId,
  }),
});

export const becomeDefaultUser = (
  redirectUrl,
  history,
  isDefaultButtonVisible
) => ({
  type: 'BECOME_DEFAULT',
  redirectUrl,
  history,
  isDefaultButtonVisible,
});

export const logInSuccess = (payload) => ({
  type: 'LOGIN_SUCCESS',
  payload,
  data: payload,
});

export const logInFail = (error) => ({
  type: 'LOGIN_FAIL',
  error,
});

export const logOut = (history) => ({
  type: 'LOGOUT',
  history,
});

export const logOutSuccess = () => ({
  type: 'LOGOUT_SUCCESS',
});

export const logOutFail = (error) => ({
  type: 'LOGOUT_FAIL',
  error,
});

export const getCurrentUser = (obj, history, redirectUrl) => ({
  type: 'GET_CURRENT_USER',
  obj,
  history,
  redirectUrl,
});

export const getCurrentUserSuccess = (data) => ({
  type: 'GET_CURRENT_USER_SUCCESS',
  data,
});

export const getCurrentUserFail = (error) => ({
  type: 'GET_CURRENT_USER_FAIL',
  error,
});

export const editUserProfile = (obj) => ({
  type: 'EDIT_USER_PROFILE',
  obj,
});

export const editUserProfileSuccess = (data) => ({
  type: 'EDIT_USER_PROFILE_SUCCESS',
  data,
});

export const editUserProfileFail = (error) => ({
  type: 'EDIT_USER_PROFILE_FAIL',
  error,
});

export const editUserEmail = (obj) => ({
  type: 'EDIT_USER_EMAIL',
  obj,
});

export const editUserEmailSuccess = (data) => ({
  type: 'EDIT_USER_EMAIL_SUCCESS',
  data,
});

export const editUserEmailFail = (error) => ({
  type: 'EDIT_USER_EMAIL_FAIL',
  error,
});

export const editUserLicense = (obj) => ({
  type: 'EDIT_USER_LICENSE',
  obj,
});

export const editUserLicenseSuccess = (data) => ({
  type: 'EDIT_USER_LICENSE_SUCCESS',
  data,
});

export const editUserLicenseFail = (error) => ({
  type: 'EDIT_USER_LICENSE_FAIL',
  error,
});

export const editUserPassword = (obj) => ({
  type: 'EDIT_USER_PASSWORD',
  obj,
});

export const editUserPasswordSuccess = (data) => ({
  type: 'EDIT_USER_PASSWORD_SUCCESS',
  data,
});

export const editUserPasswordFail = (error) => ({
  type: 'EDIT_USER_PASSWORD_FAIL',
  error,
});

export const editUserPasswordResponse = () => ({
  type: 'EDIT_USER_PASSWORD_RESPONSE',
});

export const getUsers = (obj) => ({
  type: 'GET_USERS',
  obj,
});

export const getUsersSuccess = (data) => ({
  type: 'GET_USERS_SUCCESS',
  data,
});

export const getUsersFailure = (err) => ({
  type: 'GET_USERS_FAILURE',
  err,
});
export const getUsersForEmail = (obj) => ({
  type: 'GET_USERS_FOR_EMAIL',
  obj,
});

export const getUsersForEmailSuccess = (data) => ({
  type: 'GET_USERS_FOR_EMAIL_SUCCESS',
  data,
});

export const getUsersForEmailFailure = (err) => ({
  type: 'GET_USERS_FOR_EMAIL_FAILURE',
  err,
});

export const checkInSpills = (obj) => ({
  type: 'CHECK_IN_SPILLS',
  obj,
});

export const checkInSpillsSuccess = (data) => ({
  type: 'CHECK_IN_SPILLS_SUCCESS',
  data,
});

export const checkInSpillsFailure = (err) => ({
  type: 'CHECK_IN_SPILLS_FAILURE',
  err,
});

export const createNewUser = (obj) => ({
  type: 'CREATE_NEW_USER',
  obj,
});

export const createNewUserSuccess = (data) => ({
  type: 'CREATE_NEW_USER_SUCCESS',
  data,
});

export const createNewUserFailure = (error) => ({
  type: 'CREATE_NEW_USER_FAILURE',
  error,
});

export const editUser = (obj) => ({
  type: 'EDIT_USER',
  obj,
});

export const editUserSuccess = (data) => ({
  type: 'EDIT_USER_SUCCESS',
  data,
});

export const editUserFailure = (error) => ({
  type: 'EDIT_USER_FAILURE',
  error,
});

export const getUsersByOrgId = (obj) => ({
  type: 'GET_USERS_BY_ORG_ID',
  obj,
});

export const getUsersByOrgIdSuccess = (data) => ({
  type: 'GET_USERS_BY_ORG_ID_SUCCESS',
  data,
});

export const getUsersByOrgIdFailure = (err) => ({
  type: 'GET_USERS_BY_ORG_ID_FAILURE',
  err,
});

export const getAdmins = (obj) => ({
  type: 'GET_ADMINS',
  obj,
});

export const getAdminsSuccess = (data) => ({
  type: 'GET_ADMINS_SUCCESS',
  data,
});

export const getAdminsFailure = (err) => ({
  type: 'GET_ADMINS_FAILURE',
  err,
});

export const getSalePersons = (obj) => ({
  type: 'GET_SALE_PERSONS',
  obj,
});

export const getSalePersonsSuccess = (data) => ({
  type: 'GET_SALE_PERSONS_SUCCESS',
  data,
});

export const getSalePersonsFailure = (err) => ({
  type: 'GET_SALE_PERSONS_FAILURE',
  err,
});

export const getOrganizationAdmins = (obj) => ({
  type: 'GET_ORGANIZATION_ADMINS',
  obj,
});

export const getOrganizationAdminsSuccess = (data) => ({
  type: 'GET_ORGANIZATION_ADMINS_SUCCESS',
  data,
});

export const getOrganizationAdminsFailure = (err) => ({
  type: 'GET_ORGANIZATION_ADMINS_FAILURE',
  err,
});

export const getUserRoles = (obj) => ({
  type: 'GET_ROLES',
  obj,
});

export const getUserRolesSuccess = (data) => ({
  type: 'GET_ROLES_SUCCESS',
  data,
});

export const getUserRolesFailure = (err) => ({
  type: 'GET_ROLES_FAILURE',
  err,
});

export const searchUser = (obj) => ({
  type: 'SEARCH_USER',
  obj,
});

export const searchUserSuccess = (data) => ({
  type: 'SEARCH_USER_SUCCESS',
  data,
});

export const searchUserFailure = (err) => ({
  type: 'SEARCH_USER_FAILURE',
  err,
});

export const clearSearch = () => ({
  type: 'CLEAR_SEARCH',
});

export const setNotificationPreference = (obj) => ({
  type: 'SET_NOTIFICATION_PREFERENCE',
  obj,
});

export const setNotificationPreferenceSuccess = (data) => ({
  type: 'SET_NOTIFICATION_PREFERENCE_SUCCESS',
  data,
});

export const setNotificationPreferenceFailure = (err) => ({
  type: 'SET_NOTIFICATION_PREFERENCE_FAILURE',
  err,
});

export const getAssociatedServices = (obj) => ({
  type: 'GET_ASSOCIATED_SERVICES',
  obj,
});

export const getAssociatedServicesSuccess = (data) => ({
  type: 'GET_ASSOCIATED_SERVICES_SUCCESS',
  data,
});

export const getAssociatedServicesFailure = (err) => ({
  type: 'GET_ASSOCIATED_SERVICES_FAILURE',
  err,
});

export const updateEula = (obj) => ({
  type: 'UPDATE_EULA',
  obj,
});

export const updateEulaSuccess = (data) => ({
  type: 'UPDATE_EULA_SUCCESS',
  data,
});

export const updateEulaFailure = (error) => ({
  type: 'UPDATE_EULA_FAILURE',
  error,
});

export const setCurrentSideBarValue = (data) => ({
  type: 'SET_CURRENT_SIDEBAR_VALUE',
  data,
});

export const fetchAttachmentTypesWithExpiry = (obj) => ({
  type: 'FETCH_ATTACHMENT_TYPES_WITH_EXPIRY',
  obj,
});

export const fetchAttachmentTypesWithExpirySuccess = (data) => ({
  type: 'FETCH_ATTACHMENT_TYPES_WITH_EXPIRY_SUCCESS',
  data,
});

export const fetchAttachmentTypesWithExpiryFail = (error) => ({
  type: 'FETCH_ATTACHMENT_TYPES_WITH_EXPIRY_FAIL',
  error,
});

export const updateUserAttachmentExpiry = (obj) => ({
  type: 'UPDATE_USER_ATTACHMENT_EXPIRY',
  obj,
});

export const updateUserAttachmentExpirySuccess = (data) => ({
  type: 'UPDATE_USER_ATTACHMENT_EXPIRY_SUCCESS',
  data,
});
export const updateUserAttachmentExpiryFail = (error) => ({
  type: 'UPDATE_USER_ATTACHMENT_EXPIRY_FAIL',
  error,
});

export const showErrorSnackbar = (data) => {
  return {
    type: 'SET_SNACKBAR_ERROR',
    data,
  };
};

export const showSuccessSnackbar = (data) => ({
  type: 'SET_SNACKBAR_SUCCESS',
  data,
});
export const showInfoSnackbar = (data) => ({
  type: 'SET_SNACKBAR_INFO',
  data,
});
export const clearSnackbar = () => ({
  type: 'SNACKBAR_CLEAR',
});

export const fetchPacketReviewers = (obj) => ({
  type: 'FETCH_PACKET_REVIEWERS',
  obj,
});

export const fetchPacketReviewersSuccess = (data) => ({
  type: 'FETCH_PACKET_REVIEWERS_SUCCESS',
  data,
});

export const fetchPacketReviewersFail = (error) => ({
  type: 'FETCH_PACKET_REVIEWERS_FAIL',
  error,
});

export const getAllActivePMs = (obj) => ({
  type: 'GET_ALL_ACTIVE_PMS',
  obj,
});

export const getAllActivePMsSuccess = (data) => ({
  type: 'GET_ALL_ACTIVE_PMS_SUCCESS',
  data,
});

export const getAllActivePMsFailure = (err) => ({
  type: 'GET_ALL_ACTIVE_PMS_FAILURE',
  err,
});

export const manageRosterCategory = (obj) => ({
  type: 'MANAGE_ROSTER_CATEGORY',
  obj,
});

export const manageRosterCategorySuccess = (data) => ({
  type: 'MANAGE_ROSTER_CATEGORY_SUCCESS',
  data,
});

export const manageRosterCategoryFail = (error) => ({
  type: 'MANAGE_ROSTER_CATEGORY_FAIL',
  error,
});
