import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Moment from 'moment';
import { useHistory, withRouter } from 'react-router-dom';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import HomeIcon from '@material-ui/icons/Home';
import BuildIcon from '@material-ui/icons/Build';
import SearchIcon from '@material-ui/icons/Search';
import UpdateIcon from '@material-ui/icons/Update';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import BusinessIcon from '@material-ui/icons/Business';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import WarningIcon from '@material-ui/icons/Warning';
import FolderSharedRoundedIcon from '@material-ui/icons/FolderSharedRounded';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import SettingsApplicationsRoundedIcon from '@material-ui/icons/SettingsApplicationsRounded';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PollIcon from '@material-ui/icons/Poll';
import TrackChangesOutlinedIcon from '@material-ui/icons/TrackChangesOutlined';
import { ROLES } from '../../../utils/keyMappers';
import {
  isBatchUpdatingUser,
  isPESUser,
  isPESUser_2,
  pathNames,
  isSUPERUSER,
  isCorporateAdmin
} from '../../../utils';
import {
  logOut,
  getCurrentUser,
  setCurrentSideBarValue,
  becomeDefaultUser,
} from '../../../actionCreators/User';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  sidebarBoxfirst: {
    padding: theme.spacing(2),
    paddingBottom: '2px !important',
    backgroundColor: theme.palette.grey[200],
    textAlign: 'center',
  },
  sidebarBoxSecond: {
    padding: theme.spacing(2),
    paddingTop: '2px !important',
    backgroundColor: theme.palette.grey[200],
  },
  sidebarSection: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.grey[200],
  },
  sidebarItems: {
    textAlign: 'left',
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  sidebarTabsBox: {
    backgroundColor: '#F5F5F5',
    color: '#2F7D32',
    height: '100%',
  },
  tabBars: {},
  secondaryBar: {
    zIndex: 0,
  },
  list: {
    width: '100%',
    maxWidth: 360,
    padding: '10px 30px',
    fontSize: '12px',
  },
  listItemText: {
    fontSize: '5px',
  },
  lastLoginInfo: {
    display: 'flex',
  },
  navLink: {
    color: 'green',
    cursor: 'pointer',
    fontWeight: 900,
  },
  navButtonLink: {
    fontSize: '0.875rem',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: '500',
    lineHeight: '1.57',
    letterSpacing: '0.00714em',
    display: 'flex',
  },
  bolder: {
    fontWeight: 'bolder',
  },
  headerText: {
    fontWeight: 'bolder',
    textTransform: 'capitalize',
  },
  alignStart: {
    display: 'flex',
    alignSelf: 'flex-start',
  },
  padLeft: {
    paddingLeft: 20,
  },
  padLeftDouble: {
    paddingLeft: 40,
  },
  spaceRight: {
    marginRight: 10,
  },
  alignInline: {
    display: 'flex',
  },
  spaceTop: {
    marginTop: 15,
  },
  noMargin: {
    marginLeft: '0px',
  },
  tabStyle: {
    zIndex: 2,
  },
}));

const Sidebar = (props) => {
  const { valueReturner, setCurrentSideBarValue , handleCloseNewSpill} = props;
  const classes = useStyles();
  const history = useHistory();
  const [userName, setUserName] = React.useState('');
  const [lastLogin, setLastLogin] = React.useState('');
  const [organization, setOrganization] = React.useState('');
  const [contractor, setContractor] = React.useState(null);
  const [role, setRole] = React.useState('');
  const [becomingDefaultUser, setBecomingDefaultUser] = useState(false);
  const [isDefaultButtonVisible, setIsDefaultButtonVisible] = React.useState(
    () => {
      const isDefaultButtonVisible = localStorage.getItem(
        'isDefaultButtonVisible'
      );
      return isDefaultButtonVisible === 'true';
    }
  );

  const { success, currentUser, currentSideBarValue } = props.user;

  const handleChange = (event, newValue) => {
    setCurrentSideBarValue(newValue);
  };

  const handleMoveSection = (newValue) => {
    valueReturner(newValue);
  };

  const getCurrentBarValue = () => {
    return currentUserRole?.role !== ROLES?.PROB_PM
      ? currentSideBarValue
      : currentSideBarValue + 1;
  };

  const handleBecomeDefault = () => {
    try {
      setBecomingDefaultUser(true);
      const { becomeDefaultUser } = props;
      const redirectUrl = '/dashboard/home';
      becomeDefaultUser(redirectUrl, history, setIsDefaultButtonVisible);
    } catch (err) {
      setBecomingDefaultUser(false);
    }
  };
  const currentUserRole = currentUser?.data?.role;

  function LinkTab({ tabValue = 0, ...props }) {
    return (
      <Tab
        component='a'
        onClick={(event) => {
          event.preventDefault();
          handleMoveSection(tabValue);
        }}
        href={`${window?.location?.origin}/dashboard/${pathNames[tabValue]}`}
        {...props}
      />
    );
  }

  React.useEffect(() => {
    if (success) {
      let contractorData;
      if (currentUser?.data?.opsTrack) {
        setIsDefaultButtonVisible(true);
      }
      setBecomingDefaultUser(false);

      if (currentUser.data?.contractor) {
        if (currentUser.data?.address) {
          const contractorDetail = currentUser.data?.contractor
            ? `${currentUser.data?.contractor?.name} - ${currentUser.data?.address?.tier_level} - (${currentUser.data?.address?.city}, ${currentUser.data?.address?.state})`
            : null;

          contractorData = contractorDetail;
        } else {
          const contractorDetail = currentUser.data?.contractor
            ? `${currentUser.data?.contractor?.name} - ${currentUser.data?.contractor?.tier_level} - (${currentUser.data?.contractor?.city}, ${currentUser.data?.contractor?.state})`
            : null;

          contractorData = contractorDetail;
        }
      }

      setUserName(currentUser.data?.full_name);
      setOrganization(currentUser.data?.client_organization?.name);
      setContractor(contractorData);
      setRole(currentUser.data?.role);
      if (currentUser.data?.last_login) {
        let date = Moment(currentUser.data?.last_login || '')?.format(
          'ddd, MMMM Do, h:mm a'
        );
        setLastLogin(date);
      }
    }
  }, [success, currentUser]);

  const handleClickLogout = () => {
    props.logOut(props.history);
  };

  return (
    <div className={classes.sidebarItems}>
      <Paper elevation={0} className={classes.sidebarBoxfirst} square>
        <Typography variant='h6' gutterBottom className={classes.headerText}>
          {`Welcome, ${userName || ''}`}
        </Typography>
      </Paper>
      <Paper elevation={0} className={classes.sidebarBoxSecond} square>
        <hr />
        <Typography variant='subtitle2' className={classes.navLink}>
          {role ? role?.role : ''}
        </Typography>
        <Typography variant='subtitle2'>
          {organization ? `${organization}` : `${''}`}
        </Typography>
        {contractor ? (
          <Typography variant='subtitle2'>{contractor}</Typography>
        ) : null}

        <div className={classes.lastLoginInfo}>
          <Typography variant='subtitle2' className={classes.bolder}>
            Online Since:&nbsp;
          </Typography>
          <Typography variant='subtitle2'>{lastLogin}</Typography>
        </div>

        <div
          className={`${classes.bolder} ${classes.navButtonLink} ${classes.spaceTop}`}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {!isDefaultButtonVisible && (
            <span className={classes.navLink} onClick={handleClickLogout}>
              {'Log Out'}
            </span>
          )}
          {becomingDefaultUser ? (
            <CircularProgress />
          ) : (
            isDefaultButtonVisible && (
              <span className={classes.navLink} onClick={handleBecomeDefault}>
                {'Become Default'}
              </span>
            )
          )}
        </div>
        <hr />
      </Paper>
      <Paper elevation={0} className={classes.sidebarTabsBox} square>
        <Tabs
          value={getCurrentBarValue()}
          orientation='vertical'
          textColor='inherit'
          onChange={handleChange}
          className={classes.noMargin}
        >
          (
          <LinkTab
            className={classes.tabStyle}
            textColor='inherit'
            label={
              <div className={classes.alignStart}>
                <HomeIcon className={classes.spaceRight} /> Home
              </div>
            }
            tabValue={0}
          />
          )
          {currentUserRole?.permission?.view_spills ? (
            <LinkTab
              className={classes.tabStyle}
              textColor='inherit'
              label={
                <div className={classes.alignStart} onClick={handleCloseNewSpill}>
                  <WarningIcon className={classes.spaceRight}  />
                  Spills
                </div>
              }
              tabValue={2}
            />
          ) : null}
          {currentUserRole?.permission?.view_packet_reviewer_spills ? (
            <LinkTab
              className={classes.tabStyle}
              textColor='inherit'
              label={
                <div className={`${classes.alignStart} ${classes.padLeft}`}>
                  <AssignmentIndIcon className={classes.spaceRight} />
                  Packet Assignment
                </div>
              }
              tabValue={18}
            />
          ) : null}
          {currentUserRole?.permission?.view_spills ? (
            <LinkTab
              className={classes.tabStyle}
              textColor='inherit'
              label={
                <div className={`${classes.alignStart} ${classes.padLeft}`}>
                  <SearchIcon className={classes.spaceRight} />
                  Search
                </div>
              }
              tabValue={3}
            />
          ) : null}
          {isBatchUpdatingUser(currentUserRole?.role) ? (
            <LinkTab
              className={classes.tabStyle}
              textColor='inherit'
              label={
                <div
                  className={` ${classes.spaceRight} ${classes.alignInline}`}
                >
                  <UpdateIcon className={classes.spaceRight} />
                  Batch Status Update
                </div>
              }
              tabValue={13}
            />
          ) : null}
          {isPESUser(currentUserRole?.role) ? (
            <LinkTab
              className={classes.tabStyle}
              textColor='inherit'
              label={
                <div
                  className={`${classes.alignStart} ${classes.padLeftDouble}`}
                >
                  <DescriptionOutlinedIcon className={classes.spaceRight} />
                  Custom Reports
                </div>
              }
              tabValue={17}
            />
          ) : null}
          {isSUPERUSER(currentUserRole?.role) ? (
            <LinkTab
                className={classes.tabStyle}
                textColor='inherit'
                label={
                  <div
                    className={`${classes.alignStart} ${classes.padLeftDouble}`}
                  >
                    <PeopleAltOutlinedIcon className={classes.spaceRight} />
                    Staffing
                  </div>
                }
                tabValue={25}
              />
            ): null}
          {isSUPERUSER(currentUserRole?.role) ? (
            <LinkTab
                className={classes.tabStyle}
                textColor='inherit'
                label={
                  <div
                    className={`${classes.alignStart} ${classes.padLeftDouble}`}
                  >
                    <TrackChangesOutlinedIcon className={classes.spaceRight} />
                    Roster
                  </div>
                }
                tabValue={26}
              />
            ): null}            
          {(isSUPERUSER(currentUser?.data?.role?.role) || isCorporateAdmin(currentUser?.data?.role?.role)) && (
            <LinkTab
              className={classes.tabStyle}
              textColor='inherit'
              label={
                <div className={classes.alignStart}>
                  <PollIcon className={classes.spaceRight} /> Analytics
                </div>
              }
              tabValue={16}
            />
          )}
          <LinkTab
            className={classes.tabStyle}
            textColor='inherit'
            label={
              <div className={classes.alignStart}>
                <FolderSharedRoundedIcon className={classes.spaceRight} /> My
                Profile
              </div>
            }
            tabValue={4}
          />
          {isPESUser_2(currentUserRole?.role) ? (
            <LinkTab
              className={classes.tabStyle}
              textColor='inherit'
              label={
                <div className={classes.alignStart}>
                  <SettingsApplicationsRoundedIcon
                    className={classes.spaceRight}
                  />
                  Admin
                </div>
              }
              tabValue={5}
            />
          ) : null}
          {currentUserRole?.permission?.view_agencies ? (
            <LinkTab
              className={classes.tabStyle}
              textColor='inherit'
              label={
                <div className={`${classes.alignStart} ${classes.padLeft}`}>
                  <BusinessCenterIcon className={classes.spaceRight} />
                  Agencies
                </div>
              }
              tabValue={6}
            />
          ) : null}
          {currentUserRole?.permission?.view_contractors ? (
            <LinkTab
              className={classes.tabStyle}
              textColor='inherit'
              label={
                <div className={`${classes.alignStart} ${classes.padLeft}`}>
                  <BuildIcon className={classes.spaceRight} />
                  Contractors
                </div>
              }
              tabValue={7}
            />
          ) : null}
          {currentUserRole?.permission?.view_client ? (
            <LinkTab
              className={classes.tabStyle}
              textColor='inherit'
              label={
                <div className={`${classes.alignStart} ${classes.padLeft}`}>
                  <BusinessIcon className={classes.spaceRight} />
                  Client Orgs
                </div>
              }
              tabValue={8}
            />
          ) : null}
          {currentUserRole?.permission?.view_users ? (
            <LinkTab
              className={classes.tabStyle}
              textColor='inherit'
              label={
                <div className={`${classes.alignStart} ${classes.padLeft}`}>
                  <AccountCircleIcon className={classes.spaceRight} />
                  Users
                </div>
              }
              tabValue={9}
            />
          ) : null}
          {currentUserRole?.permission?.view_services ? (
            <LinkTab
              className={classes.tabStyle}
              textColor='inherit'
              label={
                <div className={`${classes.alignStart} ${classes.padLeft}`}>
                  <AssignmentTurnedInIcon className={classes.spaceRight} />
                  Services
                </div>
              }
              tabValue={10}
            />
          ) : null}
          {isPESUser(currentUserRole?.role) && (
            <LinkTab
              className={classes.tabStyle}
              textColor='inherit'
              label={
                <div className={`${classes.alignStart} ${classes.padLeft}`}>
                  <WarningIcon className={classes.spaceRight} />
                  Pending Actions
                </div>
              }
              tabValue={11}
            />
          )}
        </Tabs>
      </Paper>
    </div>
  );
};

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = (dispatch) => ({
  logOut: bindActionCreators(logOut, dispatch),
  getCurrentUser: bindActionCreators(getCurrentUser, dispatch),
  setCurrentSideBarValue: bindActionCreators(setCurrentSideBarValue, dispatch),
  becomeDefaultUser: bindActionCreators(becomeDefaultUser, dispatch),
});

Sidebar.propTypes = {
  user: PropTypes.object.isRequired,
  archives: PropTypes.array,
  description: PropTypes.string,
  social: PropTypes.array,
  title: PropTypes.string,
  logOut: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  setCurrentSideBarValue: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
