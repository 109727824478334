import moment from "moment";
import XlsxPopulate from "xlsx-populate";
import { XLSX_DATATYPE, XLSX_FORMAT } from './keyMappers';

export const csvDownloader = async (data, type, title = "", includeTitle = false) => {
  const processedData = [data[1]];
  for (let index = 2; index < data.length; index++) {
    const itemArray = [];
    const element = data[index];
    for (let i = 0; i < element.length; i++) {
      const item = element[i];
      if (item) {
        if (!isNaN(item) && typeof item !== 'boolean') {
          if (Number.isInteger(Number(item))) {
            itemArray.push(parseInt(item, 10));
          } else {
            itemArray.push(parseFloat(item));
          }
        } else if (moment(item, "MM/DD/YYYY HH:mm:ss", true).isValid()) {
          const formattedDateTime = moment(item).toDate();
          itemArray.push(formattedDateTime);
        } else {
          itemArray.push(item);
        }
      } else {
        itemArray.push(item);
      }
    }
    if (itemArray.length) {
      processedData.push(itemArray);
    }
  }

  const workbook = await XlsxPopulate.fromBlankAsync();
  const sheet = workbook.sheet(0);

  let headerRowIndex = 1;
  if (includeTitle) {
    // Add Title Row
    const totalColumns = data[0].length;
    sheet.cell("A1").value(title).style({
      bold: true,
      fontSize: 14,
      horizontalAlignment: "center",
    });
    sheet.range(`A1:${String.fromCharCode(64 + totalColumns)}1`).merged(true);
    headerRowIndex = 2;
  }

  // Apply styling to the header row
  const headerRow = sheet.row(headerRowIndex);
  headerRow.style({
    bold: true,
    fill: {
      type: "pattern",
      pattern: "darkDown",
      foreground: { rgb: "2F7D32" },
    },
    fontColor: "000000",
    border: { style: "thin", color: "000000", size: 1 },
  });

  // Define format for all columns
  const columnDataTypes = data[0];
  for (let i = 0; i < columnDataTypes?.length; i++) {
    const columnIndex = i + 1;
    let columnFormat = false;
    if (columnDataTypes[i]["type"] === XLSX_DATATYPE.DATE) {
      columnFormat = XLSX_FORMAT.DATE_FORMAT;
    }
    if (columnFormat) {
      sheet.column(columnIndex).style({ numberFormat: columnFormat });
    }
  }

  // Set the data in the sheet
  sheet.cell(`A${headerRowIndex}`).value(processedData);

  // Calculate column widths based on content
  const maxColumnWidths = calculateMaxColumnWidths(processedData);
  setColumnWidths(sheet, maxColumnWidths);

  // Generate the Excel file
  const excelData = await workbook.outputAsync();
  const blob = new Blob([excelData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

  // Create a download link and trigger the download
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${type}-${moment(new Date()).format("DD-MM-YYYY h:mm:ss")}.xlsx`;
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const calculateMaxColumnWidths = (processedData) => {
  return processedData[0].map((_, columnIndex) => {
    return Math.max(
      ...processedData.map((col) => {
        const cellValue = col[columnIndex];
        const cellLength = cellValue instanceof Date
          ? moment(cellValue).format("MM-DD-YYYY hh:mm:ss").length + 5
          : cellValue ? cellValue.toString().length : 0;
        return cellLength;
      })
    );
  });
};

const setColumnWidths = (sheet, maxColumnWidths) => {
  for (let i = 0; i < maxColumnWidths.length; i++) {
    sheet.column(i + 1).width(maxColumnWidths[i] + 2);
  }
};